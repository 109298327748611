<template>
  <div :style="bg_image" class="container_fluid" id="side_shrink">
    <div id="content">
      <div class="top-content">
        <div class="inner-bg">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-sm-offset-2 text text-center mb-5">
                <h1 class="text-center text-white" id="registertitle">
                  <strong>RESET PASSWORD</strong>
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2"></div>
              <div
                class="col-sm-8 col-sm-push-2 form-box animated fadeInDown"
                data-animate="fadeInDown"
                style="opacity: 0"
              >
                <form @submit.prevent="submitForm()" method="post">
                  <fieldset>
                    <div class="form-top">
                      <div class="form-top-left">
                        <h3>RESET PASSWORD</h3>
                        <validation-errors
                          :errors="errors"
                          v-if="errors"
                        ></validation-errors>
                      </div>
                      <div class="form-top-right">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="form-bottom">
                      <div class="row">
                        <div class="col-sm-12 col-xs-12">
                          <div class="form-group">
                            <label>Email</label>
                            <input
                              type="hidden"
                              name="token"
                              autofocus
                              required
                              v-model.trim="formData.token"
                            />
                            <input
                              class="form-control"
                              placeholder="Please enter email"
                              type="email"
                              autofocus
                              required
                              v-model.trim="formData.email"
                            />
                            <span
                              class="d-block font-11 ms-1 mt-1 text-danger text-start"
                              v-if="errors.email"
                            >
                              {{ errors.email }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label>Password</label>
                            <input
                              class="form-control"
                              placeholder="Please enter password"
                              type="password"
                              autofocus
                              required
                              v-model.trim="formData.password"
                            />
                            <span
                              class="d-block font-11 ms-1 mt-1 text-danger text-start"
                              v-if="errors.password"
                            >
                              {{ errors.password }}
                            </span>
                          </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <input
                              class="form-control"
                              placeholder="Please enter confirm password"
                              type="password"
                              autofocus
                              required
                              v-model.trim="formData.password_confirmation"
                            />
                            <span
                              class="d-block font-11 ms-1 mt-1 text-danger text-start"
                              v-if="errors.password_confirmation"
                            >
                              {{ errors.password_confirmation }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <button
                            type="submit"
                            class="register btn btn-lg btn-info pull-right"
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container -->
    </div>
  </div>
</template>

<script>
import VerifyOtpValidations from "@/services/VerifyOtpValidations";
import axios from "axios";
//import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      formData: {
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errors: [],
      ServerSideError: "",
      bg_image: {
        backgroundImage: "url(../img/bg.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      },
    };
  },
  mounted() {
    // Access parameter values programmatically
    const token = this.$route.params.link;
    this.formData.token = token;
    console.log("token :", token);
  },
  methods: {
    submitForm() {
      // console.log("this.formData.email..", this.formData.email);
      let validations = new VerifyOtpValidations(
        // this.formData.otp,
        this.formData.email,
        this.formData.password,
        this.formData.password_confirmation
      );

      this.errors = validations.checkValidations();
      if ("email" in this.errors) {
        return false;
      }

      let formData = new FormData();
      //password

      formData.append("password", this.formData.password);
      formData.append(
        "password_confirmation",
        this.formData.password_confirmation
      );
      formData.append("email", this.formData.email);

      axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "password/reset?token=" +
            this.formData.token +
            "&password=" +
            this.formData.password +
            "&confirm_password=" +
            this.formData.password_confirmation +
            "&email=" +
            this.formData.email
        )
        .then((res) => {
          if (res.data.status == false) {
            let errorArray = res.data.msg;
            // console.log(errorArray, "Error");
            if (errorArray) {
              toast.error(errorArray, {
                autoClose: 2500,
              });
            }
          }

          if (res.data.status == true) {
            let message = res.data.msg;
            this.formData.token = "";
            this.formData.email = "";
            this.formData.password = "";
            this.formData.password_confirmation = "";
            toast.success(message, {
              autoClose: 2500,
            });

            // setTimeout(() => {
            //   this.$router.push("/password-reset");
            // }, 2800);
          }
        })
        .catch((error) => {
          console.log(error, "kkkkkkkkkkkkkkkkkkk");
          if (error.response.status === 401) {
            // Handle the 401 error here
            // this.errors = error.response.data.errors;
            console.log("Unauthorized access");
          } else {
            // Handle other errors
            console.log("An error occurred");
          }
        });
      // Send a request to your server to handle the password reset email
      // This should include the user's email address
      // After a successful response, you can show a success message to the user
      // and possibly redirect them to a confirmation page.
    },
  },
};
</script>
<style scoped>
.btn-info {
  color: #fff !important;
  background-color: #5bc0de !important;
  border-color: #46b8da !important;
}

.btn:hover {
  border-radius: 0px 15px 0px 15px !important;
}
</style>
