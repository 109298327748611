<template>
  
    <div v-if="this.route.params.pagename == 'terms-of-service' || this.route.params.pagename == 'privacy-policy' || this.route.params.pagename == 'cookies-policy' || this.route.params.pagename == 'printing'">
        <span v-if="Base_Url != '' && !Base_Url.includes('undefined')">
            <InnerPageBanner :data="Base_Url" :page="page.name" />
        </span>
        <span v-else>
            <InnerPageBanner :data="'/img/innner_page_banner.png'" :page="page.name" />
        </span>
    </div>

    <div v-if="page.name == 'Printing' || page.name == 'printing'">
        <div class="container mb-5 mt-5">
            <div class="row">
                <div class="col-sm-12 col-md-3 mb-3">
                    <ProductNavbar />
                </div>
                 
                <div class="col-sm-12 col-md-9">
                    <div class="mt-4" v-html="printerPageDescription"></div>
                </div>
            </div>
        </div>
    </div>
  
    <div v-else v-bind:style="bgStyle">
        <div class="container pt-3 pb-2">
            <div v-if="this.route.params.pagename != 'terms-of-service' && this.route.params.pagename != 'privacy-policy' && this.route.params.pagename != 'cookies-policy'">
                <nav aria-label="breadcrumb" class="text-center">
                    <!-- <h1 class="font-weight-700 font-50 inner_page_banner_title text-capitalize green-color">
                        {{ page.name }}
                    </h1> -->
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                            <a :href="baseURL" class="font-16 font-family-Roboto font-weight-700 green-color text-decoration-none">
                                Home
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a  ref="parent_page"
                                :href="printingPage"
                                class="font-16 font-family-Roboto font-weight-700 green-color text-decoration-none parent_page" >
                                {{ parentSlug ? parentSlug : page.name }}
                            </a>
                        </li>

                        <li v-if="childSlug" class="active breadcrumb-item child_page" aria-current="page">
                            <a ref="child_page" :href="childPage" class="font-16 font-family-Roboto font-weight-700 green-color text-decoration-none">
                                {{ this.route.params.pagename !== "" ? page.name: ""}}
                            </a>
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <!-- <h1> {{page.name}}</h1> -->
                    <div v-html="page.body_text"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { useRoute } from "vue-router";
import InnerPageBanner from "@/components/InnerPageBanner.vue";
import ProductNavbar from "@/components/ProductNavbar.vue";

export default {
    name: "CmsPages",
    components: {
        InnerPageBanner,
        ProductNavbar,
    },

    setup() {
        const route = useRoute();
        return {
            route,
        };
    },

    data() {
        return {
            Base_Url: "",
            page: [],
            page_details: [],
            banner: "",
            printerPageDescription: "",

            baseURL: this.$baseurlWeb,
            printingPage: "",
            childPage: "",
            parentSlug: "",
            childSlug: localStorage.getItem("producttitle"),
            params: null,
            bgStyle:{}
        };
    },

    mounted() {
        if (this.route.params.pagename == "printing") {
            fetch(process.env.VUE_APP_BASE_URL + "page/printing" + "?domain_id=2")
                .then((response) => response.json())
                .then((json) => {
                this.page_details = JSON.stringify(json.data.page_details, null, 2);
                let body_text = JSON.parse(this.page_details);
                this.printerPageDescription = body_text.body_text;
            });
        }

        let cururl = location.href;
        if (cururl.indexOf("pages/printing/") > -1) {
        let url1 = cururl.split("/");
        if ( url1[5] !== undefined && url1[5].length > 0 && url1[6] !== undefined && url1[6].length > 0) {
            this.parentSlug = url1[5];
            this.childSlug = url1[6];
            this.printingPage = this.$baseurlWeb + "pages/printing";
            this.childPage = this.printingPage + "/" + this.childSlug;
        }
        console.log("pagename..????- if..", this.route.params.pagename);
        } else {
            let pagename = this.route.name;
            console.log("pagename..????- else", this.route);
            let pageslug = this.route.params.pagename;
            this.parentSlug = pagename;
            this.childSlug = pageslug;
        }
        
    },

    watch: {
        $route() {
        this.getPage();
        },
    },

    methods: {
        getPage() {
            fetch(process.env.VUE_APP_BASE_URL + "page/" + this.$route.params.pagename + "?domain_id=2")
                .then((response) => response.json())
                .then((json) => {
                    this.page_details = JSON.stringify(json.data.page_details, null, 2);
                    let body_text = JSON.parse(this.page_details);
                    this.page = body_text;
                 //   console.log(' this.page....?????????????????????????????', this.page.meta_keywords);
                    this.banner = this.page.addons ? JSON.parse(this.page.addons) : "";
                    // console.log("this.banner... MD", this.banner.image);
                    // console.log(this.banner, "DDDDDD Hello")
                    // console.log(this.$baseurlForImages + "page/" + this.banner.image, "DDSSSS")
                     ////Set product meta tags //
                    const descEl           = document.querySelector('head meta[name="keywords"]'); 
                    const descTitle        = document.querySelector('head meta[name="title"]');
                    const descdescription  = document.querySelector('head meta[name="description"]');
                    ///console.log('descEl....',descEl); meta_description  description
                    const titleEl = document.querySelector('head title'); 
                    descEl.setAttribute('content', this.page?.meta_keywords);
                    descTitle.setAttribute('content', this.page?.meta_title?this.page?.meta_title: this.page?.name);
                    descdescription.setAttribute('content', this.page?.meta_description);
                    titleEl.textContent = this.page?.meta_title;   
                    ///// End ///  


                    if (this.banner.image != "") {
                        this.Base_Url =  this.$baseurlForImages + "page/" + this.banner.image;
                    } else {
                        this.Base_Url = "";
                    }
                    this.bgStyle= {
                        backgroundImage: this.route.params.pagename != 'terms-of-service' && this.route.params.pagename != 'privacy-policy' && this.route.params.pagename != 'cookies-policy' ? 'url('+this.Base_Url+')':null,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top right',
                        backgroundColor:'#edf1f3'
                    }
                });
            },
    },
    
    created() {
        this.getPage();
        // console.log("Hell created")
        // console.log(this.Base_Url, "Hello Main HHDDD")
        // this.bgStyle= {
        //     backgroundImage: this.route.params.pagename != 'terms-of-service' && this.route.params.pagename != 'privacy-policy' && this.route.params.pagename != 'cookies-policy' ? 'url('+this.Base_Url+')':null,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundSize: 'cover',
        //     backgroundPosition: 'top right',
        //     backgroundColor:'#edf1f3'
        // }
    },

    updated() {
        // this.bgStyle= {
        //     backgroundImage: this.route.params.pagename != 'terms-of-service' && this.route.params.pagename != 'privacy-policy' && this.route.params.pagename != 'cookies-policy' ? 'url('+this.Base_Url+')':null,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundSize: 'cover',
        //     backgroundPosition: 'top right',
        //     backgroundColor:'#edf1f3'
        // }
    },

};
</script>
