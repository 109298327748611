<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb align-items-center mb-0">
            <li class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Home</a
              >
            </li>
            <li class="breadcrumb-item">
              <a
                href="#/cart"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Cart</a
              >
            </li>
            <li class="breadcrumb-item">
              <a
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Review</a
              >
            </li>
            <li style="display: none" class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >5.5" X 8.5" 50 Note pads</a
              >
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <!-- Breadcrumb En d -->

  <!-- Process Steps Name Start -->
  <div class="container mt-2">
    <div class="row">
      <div class="col-md-12">
        <ul id="progress">
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            my shopping cart
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Shipping Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Payment Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Review Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize"
          >
            Checkout
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Process Steps Name End -->
  <div class="container">
    <div style="margin-bottom: 30px; margin-top: 30px">
      <div class="row">
        <div class="col-md-12 clearfix">
          <h2>Review your order</h2>

          <h3>Shipping Details</h3>

          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td class="align-right">Name</td>
                  <td>
                    {{
                      getShippingDetails.shipping_to_full_name !== undefined
                        ? getShippingDetails.shipping_to_full_name
                        : ""
                    }}
                  </td>
                </tr>
                <tr style="display: none">
                  <td class="align-right">Title</td>
                  <td>
                    {{
                      getShippingDetails.shipping_to_title !== undefined
                        ? getShippingDetails.shipping_to_title
                        : "N/A"
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="align-right">Company</td>
                  <td>
                    {{
                      getShippingDetails.shipping_to_company !== undefined
                        ? getShippingDetails.shipping_to_company
                        : "N/A"
                    }}
                  </td>
                </tr>

                <tr>
                  <td class="align-right">Address</td>
                  <td>
                    {{
                      getShippingDetails.shipping_to_address !== undefined
                        ? getShippingDetails.shipping_to_address
                        : ""
                    }}
                    {{
                      getShippingDetails.shipping_to_address_1 !== undefined
                        ? getShippingDetails.shipping_to_address_1
                        : ""
                    }}<br />
                    {{
                      getShippingDetails.shipping_to_stateName !== undefined
                        ? getShippingDetails.shipping_to_stateName
                        : ""
                    }},
                    {{
                      getShippingDetails.shipping_to_cityName !== undefined
                        ? getShippingDetails.shipping_to_cityName
                        : ""
                    }},
                    {{
                      getShippingDetails.shipping_to_countryName !== undefined
                        ? getShippingDetails.shipping_to_countryName
                        : ""
                    }}
                    -
                    {{
                      getShippingDetails.shipping_to_zip !== undefined
                        ? getShippingDetails.shipping_to_zip
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="align-right">Day Phone</td>
                  <td>
                    {{
                      getShippingDetails.shipping_to_day_phone !== undefined
                        ? getShippingDetails.shipping_to_day_phone
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="align-right">Night Phone</td>
                  <td>
                    {{
                      getShippingDetails.shipping_to_night_phone !== undefined
                        ? getShippingDetails.shipping_to_night_phone
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="align-right">Email</td>
                  <td>
                    <a
                      href="`{{mail:${getShippingDetails.shipping_to_email}}}`"
                    >
                      {{
                        getShippingDetails.shipping_to_email !== undefined
                          ? getShippingDetails.shipping_to_email
                          : ""
                      }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <h3>Payment Method</h3>
          <div class="input align-center">
            <strong>{{ getPaymentMethod.payment_method }}</strong
            ><br />Make payment with {{ getPaymentMethod.payment_method }}
          </div>
          <div v-if="note">
            <hr />
            <h3>Notes</h3>
            <div class="input align-center">
                <p>{{note}}</p>
            </div>
          </div>
          <hr />

          <h3>Product being purchased</h3>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Shipping Cost</th>
                  <th>Total</th>
                </tr>
              </thead>

              <tbody v-for="(item, index) in getCartItems.products" :key="index" >
                <tr>
                  <td>{{ ++index }}</td>
                  <td>
                    <strong v-html="item.title? item.title:''"></strong><br />
                    <!-- <strong>{{ item.title }}</strong><br /> -->
                    <div class="mt-2 mb-2" v-if="item.cart_data">
                      <div v-for="(it, i) in item.cart_data" :key="i">
                        <div
                          v-if="
                            it.text != '' &&
                            it.value != '' &&
                            it.id != 'user_filename' &&
                            it.id != 'filenames_arr'"
                        >
                          <b>{{ it.label }} {{ it.label ? ":" : "" }}</b>
                          {{ it.text }}
                        </div>
                      </div>
                    </div>
                    <div class="mt-2 mb-2" v-if="item.file_details">
                      <strong>Artwork</strong>
                      <div v-for="(fileName, i) in item.file_details" :key="i">
                        <div>
                           {{ fileName.name }}
                        </div>
                      </div>
                    </div>
                    <a
                      ><br /><b>Shipping From:</b>
                      {{ item.shipping_quote?.shipData?.from?.name }}</a
                    >
                  </td>
                  <td>
                    <b>{{ item.qty }}</b>
                  </td>
                  <td>
                    <b>${{ parseFloat(item.price).toFixed(2) }}</b>
                  </td>
                  <td>
                    <b
                      >$
                      {{
                        parseFloat(
                          parseFloat(
                            getShippingMethod.ship_price !== undefined
                              ? getShippingMethod.ship_price * item.qty
                              : 0.0
                          ).toFixed(2)
                        )
                      }}</b
                    >
                  </td>
                  <td>
                    <b
                      >$
                      {{
                        parseFloat(
                          item.price * item.qty +
                            getShippingMethod.ship_price * item.qty
                        ).toFixed(2)
                      }}</b
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td colspan="2" style="text-align: right; width: 76.8px">
                    <strong>Shipping: </strong
                    >{{
                      getShippingMethod.ship_type !== undefined
                        ? getShippingMethod.ship_type
                        : ""
                    }}
                  </td>
                  <td colspan="2" style="width: 46.8px"></td>
                  <td colspan="2" style="width: 46.8px">
                    <b
                      >$
                      {{
                        getShippingperItemcost !== undefined
                          ? getShippingperItemcost
                          : ""
                      }}</b
                    >
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <!----Tax if any---->
            <table
              v-if="getTaxDetails !== undefined && getTaxDetails > 0"
              class="table table-bordered"
            >
              <tbody>
                <tr>
                  <td colspan="2" style="text-align: right; width: 374.8px">
                    <strong
                      >Taxes: on Item ({{ getCartItems.products.length }})
                    </strong>
                  </td>
                  <td colspan="2" style="width: 416.8px">
                    $ <b>{{ getTaxDetails }}</b>
                  </td>
                  <td colspan="2" style="width: 46.8px"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <!----Tax if any---->

            <!---discount and coupon--->

            <table
              v-if="getTaxDetails !== undefined && getTaxDetails > 0"
              class="table table-bordered"
            >
              <tbody v-if="discount && discount > 0">
                <tr>
                  <td colspan="2" style="text-align: right; width: 374.8px">
                    <strong>Discount </strong>
                  </td>
                  <td colspan="2" style="width: 416.8px">
                    - <b>$ {{ discount }}</b>
                  </td>
                  <td colspan="2" style="width: 46.8px"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <!---discount and coupon--->

            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td colspan="2" style="text-align: right; width: 374.8px">
                    <strong>Total: </strong>
                  </td>
                  <td colspan="2" style="width: 416.8px">
                    $ <b>{{ getFullPrice }}</b>
                  </td>
                  <td colspan="2" style="width: 46.8px"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <form
            @submit.prevent="continueProcess()"
            id="CartReviewForm"
            method="post"
            accept-charset="utf-8"
          >
            <div
              class="row align-items-center"
              v-if="getPaymentMethod.payment_method == 'Paypal'"
            >
              <div class="col-12 col-md-6">
                <div id="paypal-container" ref="paypal"></div>
              </div>

              <div class="col-12 col-md-6">
                <input
                  class="btn btn-warning pull-right me-0"
                  :class="
                    getPaymentMethod.payment_method == 'Paypal'
                      ? 'paypal_cancel'
                      : 'cancelorder'
                  "
                  name="cancel"
                  type="submit"
                  value="Cancel Order"
                  @click="clearCartData()"
                />
              </div>
            </div>

            <div class="row" v-else>
              <div class="col-md-12">
                <input
                  class="btn btn-primary p-0 text-capitalize"
                  name="continue"
                  type="submit"
                  value="Continue to Billing"
                />

                <input
                  class="btn btn-warning pull-right"
                  name="cancel"
                  type="submit"
                  value="Cancel Order"
                  id="cancelorder"
                  @click="clearCartData()"
                />
              </div>
            </div>
          </form>
        </div>

        <!-- /.col-md-12 -->
      </div>
      <!-- /.row -->
    </div>
  </div>
</template>

<script>
//import $ from "jquery";
//import axios from "axios";
// import { ref, onMounted } from "vue";

import { useStore } from "vuex";
import { mapActions } from "vuex";
import {
  GET_CART_REVIEW_ACTION,
  PAYPAL_PAYMENT_ACTION,
  CLEAR_CART_DATA_ACTION,
  DELETE_ALL_ITEMS_CART_ACTION,
} from "@/store/storeconstants";
//no-used-var
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
//import { useRoute } from "vue-router";
export default {
  name: "ReviewDetails",
  data() {
    return {
      user_token: null,
      grandTotal: 0,
      loaded: false,
      paidFor: false,
      note:localStorage.getItem('notes') ? localStorage.getItem('notes'): null,
      product: {
        price: 777.77,
        description: "leg lamp from that one movie",
        img: "./assets/lamp.jpg",
      },
    };
  },
  setup() {
    const store = useStore();
    let cartData =
      store.state.cartData !== undefined ? store.state.cartData : [];
    let currentOrderid =
      localStorage.getItem("current_order_id") !== undefined
        ? localStorage.getItem("current_order_id")
        : {};
    let paymentMethod =
      localStorage.getItem("paymentMethod") !== null
        ? JSON.parse(localStorage.getItem("paymentMethod"))
        : {};
    let ShippingtoDetails =
      localStorage.getItem("ShippingToDetails") !== null
        ? JSON.parse(localStorage.getItem("ShippingToDetails"))
        : {};

    let ShippingfromDetails =
      localStorage.getItem("ShippingFromDetails") !== null
        ? JSON.parse(localStorage.getItem("ShippingFromDetails"))
        : {};

    let ShippingMethods =
      localStorage.getItem("shipping_methods") !== null
        ? JSON.parse(localStorage.getItem("shipping_methods"))
        : {};

    let coupon =
      localStorage.getItem("coupon") !== null
        ? localStorage.getItem("coupon")
        : "";

    let discount =
      localStorage.getItem("discount") !== null
        ? localStorage.getItem("discount")
        : "";

    return {
      cartData,
      currentOrderid,
      ShippingfromDetails,
      ShippingtoDetails,
      ShippingMethods,
      paymentMethod,
      coupon,
      discount,
    };
  },
  computed: {
    getShippingDetails() {
      let ship_details = this.ShippingtoDetails;
      console.log(ship_details);
      return ship_details;
    },
    getShippingCost() {
      let getShip =
        localStorage.getItem("shipping_methods") !== null
          ? JSON.parse(localStorage.getItem("shipping_methods"))
          : "";
      let costship = getShip.ship_value.split("$");
      let getprice = costship[1].split(")");
      let finalshipprice = parseFloat(getprice[0]).toFixed(2);
      return finalshipprice;
    },
    getPaymentMethod() {
      let getpay = this.paymentMethod;
      return getpay;
    },
    getTaxDetails() {
      let checktax =
        localStorage.getItem("tax") !== null ? localStorage.getItem("tax") : 0;
      return checktax;
    },
    getDiscount() {
      let getdiscount = this.discount && this.discount > 0 ? this.discount : 0;
      return getdiscount;
    },
    getItemTotal() {
      let allitems = this.cartData.cart;
      let total = 0;
      allitems.map((item) => {
        let price = item.price * item.qty;
        total = total + price;
      });
      return total.toFixed(2);
    },
    getShippingMethod() {
      let ship_method = this.ShippingMethods;
      let ship_value =
        ship_method.ship_value !== undefined ? ship_method.ship_value : "";
      let getshipelem = ship_value.split("(");
      let shiptype = getshipelem[0];
      let shipPrice1 = getshipelem[1].replace("$", " ");
      let shipPrice = shipPrice1.replace(")", " ");
      //console.log("ship_method...", ship_method);

      let getshipprice = parseFloat(shipPrice).toFixed(2);

      return {
        ship_type: shiptype,
        ship_key: ship_method.ship_key,
        ship_price: getshipprice,
      };
    },
    getTotalExtraCosts() {
      let items = this.cartData.cart;
      let total_price = 0;
      items.map((e) => {
        total_price = total_price + e.total_price;
      });

      return parseFloat(total_price).toFixed(2);
    },
    getShippingperItemcost() {
      let shipping_price = this.getShippingCost;
      let carts = this.cartData.cart;
      let totalshipcost = 0;
      let finalshipprice = 0;
      carts.map((e) => {
        totalshipcost =
          totalshipcost + parseFloat(shipping_price) * parseFloat(e.qty);
      });
      finalshipprice = totalshipcost.toFixed(2);
      return finalshipprice;
    },

    getTaxRate() {
      let checktax =
        localStorage.getItem("tax") !== null
          ? localStorage.getItem("tax")
          : "0.0";
      let getTax = parseFloat(checktax).toFixed(2);
      return getTax;
    },
    getFullPrice() {
      let itemprice = 0;
      let allitems = this.cartData.cart;

      // eslint-disable-next-line no-unused-vars
      let shipcost = this.getShippingperItemcost
        ? this.getShippingperItemcost
        : 0;
      let tax = this.getTaxRate;
      let discount = this.getDiscount;
      allitems.map((e) => {
        let unitprice = e.price * e.qty;
        itemprice = itemprice + unitprice;
      });
      if (discount && discount > 0) {
        return (
          parseFloat(itemprice) +
          parseFloat(shipcost) +
          parseFloat(tax) -
          parseFloat(discount)
        ).toFixed(2);
      } else {
        return (
          parseFloat(itemprice) +
          parseFloat(shipcost) +
          parseFloat(tax)
        ).toFixed(2);
      }
    },

    getCompleteCost() {
      let getallitems = this.cartData.cart;
      //let getcost = 0;
      let total_cost = 0;
      let checkTax = this.getTaxDetails;
      let checkShippingCost =
        this.getShippingMethod.ship_price !== ""
          ? this.getShippingMethod.ship_price
          : 0;
      getallitems.map(function (e) {
        let p_price = e.price;
        let p_qty = e.qty;
        let total =
          checkShippingCost && checkShippingCost > 0
            ? p_price * p_qty + checkShippingCost
            : p_price * p_qty;
        // let complete_cost_inc_ship = (
        //   parseFloat(checkShippingCost) +
        //   parseFloat(total) +
        //   parseFloat(checkTax)
        // ).toFixed(2);
        total_cost = total_cost + total;
      });

      return (parseFloat(total_cost) + parseFloat(checkTax)).toFixed(2);
    },
    getCartItems() {
      let items = this.cartData.cart;
      let products = [];
      items.map((e) => {
        let product_title = e.product_data.title;
        let product_short_desc = e.product_data.short_description;
        let order_id = e.order_id;
        let product_qty = e.qty;
        let shipping_quote = e.shipping_quote;
        let product_price = e.price;
        let total_price = e.total_price;
        let cart_data = e.cart_data;
        let file_details = e.file_details;

        products.push({
          title: product_title,
          short_desc: product_short_desc,
          order_id: order_id,
          qty: product_qty,
          shipping_quote: shipping_quote,
          price: product_price,
          total_price: total_price,
          cart_data: cart_data,
          file_details: file_details,
        });
      });

      return {
        products: products,
        shipping_from: this.ShippingfromDetails,
      };
    },
  },
  created() {
    this.user_token =
      this.$store.state.auth.CurrentUserLogin.token !== undefined
        ? this.$store.state.auth.CurrentUserLogin.token
        : this.$store.state.auth.CurrentUserLogin.CurrentUserLogin.token;

    this.discount =
      localStorage.getItem("discount") !== null
        ? localStorage.getItem("discount")
        : 0;
    document.title = "Review";
  },
  mounted() {
    const script = document.createElement("script");
    //let paypal_key = process.env.VUE_APP_PAYPAL_CLIENT_ID_LIVE;
    //let  Url    = process.env.IS_LIVE?'https://www.paypal.com/sdk/js?client-id=':'https://www.sandbox.paypal.com/sdk/js?client-id=';
    //script.src = `https://www.paypal.com/sdk/js?client-id=${paypal_key}`;

    console.log("process.env.IS_LIVE..", process.env.VUE_APP_IS_LIVE == "true");

    script.src =
      process.env.VUE_APP_IS_LIVE == "true"
        ? "https://www.paypal.com/sdk/js?client-id=" +
          process.env.VUE_APP_PAYPAL_CLIENT_ID_LIVE
        : "https://www.paypal.com/sdk/js?client-id=" +
          process.env.VUE_APP_PAYPAL_CLIENT_ID;

    if (this.paymentMethod.payment_method == "Paypal") {
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
    }
    this.getCompleteCost;
  },
  methods: {
    ...mapActions("cartData", {
      reviewCart: GET_CART_REVIEW_ACTION,
    }),
    ...mapActions("cartData", {
      paypalPayment: PAYPAL_PAYMENT_ACTION,
    }),
    ...mapActions("cartData", {
      deletedAllItem: DELETE_ALL_ITEMS_CART_ACTION,
    }),

    ...mapActions("cartData", {
      clearCart: CLEAR_CART_DATA_ACTION,
    }),
    ...mapActions("cartData", {
      clearCartItems: DELETE_ALL_ITEMS_CART_ACTION,
    }),
    clearCartData() {
      let input = confirm("Are you sure you want to clear cart?");
      if (input && input == true) {
        this.deletedAllItem();
        this.clearCart([]);
        // this.store.state.cartData.cart = [];
        // $("table thead").empty();
        // $("table tbody").empty();
        // $(".white_bg_btn").empty();
        // $("#clearcart").empty();
        // $(".cart-empty").show("slow");

        toast.success("cart cleared successfully", { autoClose: 740 });
        this.$router.push("/cart");
      }
    },
    continueProcess() {
      let formData = new FormData();

      let gettoken = this.user_token;
      console.log("gettoken.. MD", gettoken);
 
      formData.append("token", gettoken);
      formData.append("continue", 1);
      formData.append("current_order_id", this.currentOrderid);
      let shipping_price = this.getShippingMethod.ship_price;
      let ship_key = this.getShippingMethod.ship_key;
      let payment_method = "";
      if (
        this.getPaymentMethod.payment_method == "American Express" ||
        this.getPaymentMethod.payment_method == "Master Card" ||
        this.getPaymentMethod.payment_method == "Visa"
      ) {
        payment_method = "helcium";
      } else {
        payment_method = this.getPaymentMethod.payment_method;
      }

      let tax = this.getTaxDetails;

      let coupon =
        localStorage.getItem("coupon") !== null
          ? localStorage.getItem("coupon")
          : "";
      if (shipping_price != "") {
        formData.append("shipping_cost", shipping_price);
      }

      if (ship_key != "") {
        formData.append("selected_shipping_method", ship_key);
      }

      //ship_key

      if (tax != "") {
        formData.append("tax_exemption", tax);
      }

      if (coupon !== undefined && coupon != "") {
        formData.append("coupon", coupon);
      }

      if (payment_method != "") {
        formData.append("payment_method", payment_method);
      }
      
      let formDataWithToken = {
          formDataSend:formData, 
          token:gettoken
      };
      
      this.reviewCart(formDataWithToken)
        .then((res) => {
          console.log(res, 'res from reviewCart')
          if (res.status == 200) {
            //check payment method
            let payment_method = this.getPaymentMethod.payment_method;
            //let params = {};
            switch (payment_method) {
              case "Paypal":
                // params = {
                //   order_id: this.currentOrderid,
                // };
                // this.$router.push("/payment/paypal");
                //this.paypalPayment(params);
                break;
              case "American Express":
              case "Master Card":
              case "Visa":
                this.$router.push("/payment/helcim/checkout");
                break;
              case "Cheque":
                this.$router.push("/payment/check-cash");
                break;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setLoaded() {
      this.loaded = true;
      const items = this.cartData.cart.map((item) => ({
        name: item.product_data.title,
        description: item.product_data.short_desc,
        unit_amount: {
          currency_code: "USD",
          value: parseFloat(item.price).toFixed(2),
        },
        shipping: {
          currency_code: "USD",
          value: this.getShippingMethod.ship_price,
        },
        quantity: item.qty,
      }));

      // console.log("paypal costs");
      // console.log("Full Price" + this.getFullPrice);

      // console.log("Item Total" + this.getItemTotal);
      // console.log("Shipping Total" + this.getShippingperItemcost);
      // console.log("Tax Rate " + this.getTaxDetails);
      // console.log("Discount Rate " + this.getDiscount);
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: this.getFullPrice, //this.getCompleteCost,
                    breakdown: {
                      item_total: {
                        /* Required when including the `items` array */
                        currency_code: "USD",
                        value: this.getItemTotal, // this.getCompleteCost,
                      },
                      shipping: {
                        currency_code: "USD",
                        value: this.getShippingperItemcost,
                      },
                      tax_total: {
                        currency_code: "USD",
                        value: this.getTaxDetails,
                      },
                      discount: {
                        currency_code: "USD",
                        value: this.getDiscount,
                      },
                    },
                  },
                  items,
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();

            this.data;

            this.paidFor = true;
            console.log(order);

            if (order && order.status == "COMPLETED") {
              let check_tax =
                localStorage.getItem("tax") !== ""
                  ? localStorage.getItem("tax")
                  : "";
              let coupon =
                localStorage.getItem("coupon") !== ""
                  ? localStorage.getItem("coupon")
                  : "";
              let shipping =
                localStorage.getItem("shipping_methods") != ""
                  ? JSON.parse(localStorage.getItem("shipping_methods"))
                  : [];
              let paymentMethod =
                localStorage.getItem("paymentMethod") != ""
                  ? JSON.parse(localStorage.getItem("paymentMethod"))
                  : [];
              let alldata = {
                orders: order,
                order_id: this.currentOrderid,
                tax: check_tax,
                coupon: coupon,
                shipping_details: shipping,
                paypal_data: this.data,
                paymentMethod: paymentMethod,
              };
              this.paypalPayment(alldata)
                .then((res) => {
                  if (res.status == 200) {
                    toast.success("Your Transaction is Successfull", {
                      autoClose: 2500,
                    });
                    localStorage.setItem("isActive", "true");
                    toast.success("Your Transaction is Successfull", {
                      autoClose: () => {
                        console.log("Toast is closed");
                        // You can perform actions or call a callback function here.
                      },
                    });

                    setTimeout(() => {
                      if (localStorage.getItem("current_order_id") !== null) {
                        localStorage.removeItem("current_order_id");
                      }

                      if (localStorage.getItem("paymentMethod") !== null) {
                        localStorage.removeItem("paymentMethod");
                      }

                      if (
                        localStorage.getItem("ShippingFromDetails") !== null
                      ) {
                        localStorage.removeItem("ShippingFromDetails");
                      }

                      if (localStorage.getItem("ShippingToDetails") !== null) {
                        localStorage.removeItem("ShippingToDetails");
                      }

                      if (localStorage.getItem("product_option_map") !== null) {
                        localStorage.removeItem("product_option_map");
                      }

                      if (localStorage.getItem("shipping_methods") !== null) {
                        localStorage.removeItem("shipping_methods");
                      }

                      if (localStorage.getItem("Tax") !== null) {
                        localStorage.removeItem("Tax");
                      }

                      if (localStorage.getItem("coupon") !== null) {
                        localStorage.removeItem("coupon");
                      }

                      if (localStorage.getItem("discount") !== null) {
                        localStorage.removeItem("discount");
                      }

                      this.clearCart([]); //clear cart

                      this.$router.push("/account");
                    }, 3000);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          },
          onCancel: async (data) => {
            console.log("cancelled");
            console.log(data);
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },
    cancelOrder() {
      //onclick='if (confirm("Do you really want to clear the cart?")) { document.post_5bfe3513e93e7276594003.submit(); } event.returnValue = false; return false;'
    },
  },
};
</script>
<style scoped>
.btn.btn-primary.p-0.text-capitalize {
  margin-top: 20px;
  font-family: Roboto !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff !important;
  border-color: #7ec4cf !important;
  border-radius: 2px !important;
  width: 160px !important;
  height: 60px !important;
  line-height: 60px !important;
  background: 0/100% 100% no-repeat linear-gradient(#7ec4cf, #7ec4cf) !important;
  transition: background-size 0.5s !important;
}

.btn.btn-primary.p-0.text-capitalize:hover {
  color: #fff !important;
  border-color: #7ec4cf !important;
  border-radius: 2px !important;
  background: 0/100% 100% no-repeat linear-gradient(#7ec4cf, #7ec4cf) !important;
  transition: background-size 0.5s !important;
}
#cancelorder {
  margin-top: 20px;
  font-family: Roboto !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff !important;
  border-color: #7ec4cf !important;
  border-radius: 2px !important;
  width: 160px !important;
  height: 60px !important;
  line-height: 46px !important;
  background: 0/100% 100% no-repeat linear-gradient(#dc3545, #dc3545) !important;
}

#paypal-container {
  min-width: 150px !important;
  max-width: 250px !important;
}

.paypal_cancel {
  width: 150px;
  padding: 12px;
}
</style>
