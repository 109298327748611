import { createApp } from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";

//import 'bootstrap-vue/dist/bootstrap-vue.css'
import "bootstrap";
/***importing Datatables ***/
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.mjs";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5.min.mjs";
import "datatables.net-colreorder-dt";
import "datatables.net-fixedheader-dt";
import "datatables.net-fixedcolumns-dt";

/***importing Datatables ***/
//import "./assets/css/bootstrap.min.css";
import "./assets/css/scriptina-font.css";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
import "./assets/css/animate.css";
import "./assets/css/custom.css";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faDesktop } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";

/* add icons to the library */
library.add(faDesktop);

import router from "./router";
import store from "./store/store";
import VSwitch from "v-switch-case";
import helper from "../src/helper"
import mitt from 'mitt';
import { createMetaManager } from 'vue-meta'
//import VueMeta from 'vue-meta'
const emitter = mitt();
const app = createApp(App);

app.use(router);
app.use(store);
app.use(VSwitch)
app.use(helper) //using custome helper
app.use(createMetaManager()) // for meta tags 

 


app.component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.$axios = axios;

app.config.globalProperties.$baseurl = process.env.VUE_APP_BASE_URL; // "https://hollywoodstudios.org/api/"

//app.config.globalProperties.$baseurl = "https://hsexposed.iknowsolution.com/api/" 

 
 app.config.globalProperties.$baseurlForImages = process.env.VUE_APP_BASE_URL_WEB;///"https://hollywoodstudios.org/"

//app.config.globalProperties.$baseurlForImages = "https://hsexposed.iknowsolution.com/"


//app.config.globalProperties.$baseurlWeb = "https://hsexposedweb.iknowsolution.com/#/" 

app.config.globalProperties.$baseurlWeb =process.env.VUE_APP_BASE_URL_LOCAL;// "https://store.hollywoodstudios.org/#/" 
 
  //app.config.globalProperties.$baseurlWeb = "http://localhost:8080/#/" 


 //app.config.globalProperties.$baseurlWebPriting = "https://hsexposedweb.iknowsolution.com/#/pages/printing/"

  app.config.globalProperties.$baseurlWebPriting = process.env.VUE_APP_ACCNT_URL_WEB_PRINTING;//'https://store.hollywoodstudios.org/#/pages/printing/';
//app.config.globalProperties.$baseurlWebPriting = 'http://localhost:8080/#/pages/printing/';


// app.config.errorHandler = (err, vm, info) => {
  
//   console.error("Global error:", err.message);
//   console.error("Error occurred in:", info);
// };
//error handling
  app.config.globalProperties.emitter = emitter;
  app.config.globalProperties.$storageclear=function()
  {
    if (localStorage.getItem("current_order_id") !== null) {
      localStorage.removeItem("current_order_id");
    }

    if (localStorage.getItem("shipping_methods") !== null) {
      localStorage.removeItem("shipping_methods");
    }

    if (localStorage.getItem("ShippingFromDetails") !== null) {
      localStorage.removeItem("ShippingFromDetails");
    }


    if (localStorage.getItem("ShippingToDetails") !== null) {
      localStorage.removeItem("ShippingToDetails");
    }

    if(localStorage.getItem("DataTables_printingorders_/")!==null)
    {
      localStorage.removeItem("DataTables_printingorders_/");
    }

    if(localStorage.getItem("__paypal_storage__")!==null)
    {
      localStorage.removeItem("__paypal_storage__");
    }
        

    

    if (localStorage.getItem("product_option_map") !== null) {
      localStorage.removeItem("product_option_map");
    }

    if (localStorage.getItem("loggedout") == null) {
      localStorage.setItem("loggedout", true);
    } else {
      localStorage.setItem("loggedout", true);
    }

    if (localStorage.getItem("coupon") !== null) {
      localStorage.removeItem("coupon");
    }


    if (localStorage.getItem("tax") !== null) {
      localStorage.removeItem("tax");
    }

    if (localStorage.getItem("paymentMethod") !== null) {
      localStorage.removeItem("paymentMethod");
    }

    if (localStorage.getItem("isRegistered") !== null) {
      localStorage.removeItem("isRegistered");
    }

    if (localStorage.getItem("discount") !== null) {
      localStorage.removeItem("discount");
    }

    if (localStorage.getItem("TotalAmount") !== null) {
      localStorage.removeItem("TotalAmount");
    }
  }
  /****declare Hele */
  app.mount("#app"); //mounting app instance
