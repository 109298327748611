<template>
  <!--Banner Section----->
  <div
    v-if="this.$store.state?.homeData?.home?.main_banner?.logo_url !== ' '"
    class="home_banner"
    :style="{
      'background-image':
        'url(' +
        $baseurlForImages +
        this.$store.state?.homeData.home?.main_banner?.logo_url +
        ')',
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-11">
          <div
            v-html="this.$store.state?.homeData?.home?.main_banner?.description"
          ></div>
          <div class="mt-2 ms-5 ps-5" id="chatload"></div>
          <div style="display: none" class="white_bg_btn pt-4">
            <a href="#" class="btn btn-primary p-0 me-sm-3">Get Started</a>
            <a
              href="#"
              id="chathere"
              @click="loadComponent"
              class="btn btn-outline-primary p-0"
              >Chat With Us</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showComponent">
    <AsyncPartialComponent />
  </div>
  <!--Banner Section----->

  <!---Service Section---->
  <div class="service_section pb-sm-5 pb-xs-5 pb-lg-0">
    <div class="container-fluid g-0">
      <div class="m-0 row">
        <div class="col-sm-12 col-md-12 col-lg-6 ps-0 p-xs-0 p-sm-0">
          <div class="h-100">
            <img
              class="img-fluid w-100 h-100"
              :src="
                $baseurlForImages +
                this.$store.state?.homeData?.home?.looking_service
                  ?.looking_service_image_url
              "
              alt="service"
            />
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-xxl-4 mt-md-5 mt-sm-5 mt-lg-0 mt-xs-5 align-self-center"
        >
          <div
            class="h-100 mb-4 mt-4 ps-lg-5 ps-xxl-5"
            v-html="
              this.$store.state?.homeData?.home?.looking_service
                ?.looking_service_descriptions
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!---Service Section---->

  <!---Welcome Section----->
  <div class="welcome_section pt-5 pb-5 mt-5 mt-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12 col-lg-6">
          <div class="position-relative">
            <div class="welcome_lg_img">
              <img
                class="img-fluid w-100 rounded-4"
                :src="
                  this.$store.state?.homeData?.home?.welcome
                    ?.welcome_image_url !== ' '
                    ? $baseurlForImages +
                      this.$store.state?.homeData?.home?.welcome
                        ?.welcome_image_url
                    : ''
                "
                alt=""
              />
            </div>
            <div class="welcome_sm_img">
              <img
                class="img-fluid w-100 rounded-4"
                :src="
                  this.$store.state?.homeData?.home?.welcome
                    ?.welcome_image1_url !== ' '
                    ? $baseurlForImages +
                      this.$store.state?.homeData?.home?.welcome
                        ?.welcome_image1_url
                    : ''
                "
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 mt-sm-5 mt-xs-3">
          <h3
            class="ms-md-4 text-capitalize title"
            v-html="this.$store.state?.homeData?.home?.welcome?.welcome_header"
          ></h3>
          <span
            class="d-block ms-md-4 scriptina_font_style"
            v-html="
              this.$store.state?.homeData?.home?.welcome?.welcome_subheader
            "
          ></span>
          <div
            class="margin-left"
            v-html="
              this.$store.state?.homeData?.home?.welcome?.welcome_descriptions
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!---Welcome Section----->

  <!---Carousel--->
  <div class="printing_services_slider position-relative">
    <div class="container mb-4">
      <div class="row">
        <div class="col-md-9 col-lg-6">
          <h4 class="title position-relative">
            {{ printingsevice != "" ? printingsevice : "" }}
          </h4>
        </div>
        <div
          class="col-md-3 col-lg-6 align-self-end text-end position-relative"
        >
          <div id="Slider_count">
            <span class="current_slider">{{ currentSlide }}</span
            ><span>/</span>
            <span class="total_slider">{{ slides.length }}</span>
          </div>
        </div>
      </div>
    </div>
    <Carousel
      class="printing_services_owl_carousel"
      :items-to-show="4"
      :wrap-around="true"
      @init="handleInit"
      @slide-start="handleSlideStart"
      v-bind="settings"
      :breakpoints="breakpoints"
    >
      <Slide v-for="slide in slides" :key="slide">
        <div class="carousel__item h-100">
          <div class="card w-100 h-100">
            <div class="slider_img h-100" v-if="!slide.images.length">
              <img class="w-100" src="../assets/img/slider/1.png" alt="" />
            </div>

            <div
              class="slider_img h-100"
              v-for="image in slide.images"
              :key="image.id"
            >
              <img
                v-if="image.file_name"
                class="w-100 h-100"
                :src="this.$baseurlForImages + 'files/image/' + image.file_name"
                alt=""
              />
            </div>

            <div class="content_layer">
              <!-- <span class="d-block text-white mb-2">{{ slide.title }}</span> -->
              <span class="d-block text-white mb-2" v-html="slide.title"></span>
              <p class="text-white" v-html="slide.short_description"></p>
              <!-- <a :href="$baseurlWebPriting+`${slide.url}`"  class="text-decoration-none d-block mb-3">Shop Now</a> -->
              <a
                style="cursor: pointer"
                @click.prevent="goToProductpage(slide.url)"
                class="text-decoration-none d-block mb-3"
                >Shop Now</a
              >
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
  <!---Carousel--->

  <!---Why Choose us--->
  <div class="why_choose_us_section">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-md-12 col-lg-5 text-center order-xs-1 order-sm-1 order-md-1 order-lg-0"
          v-html="
            this.$store.state?.homeData?.home?.why_choose_us
              ?.why_choose_us_descriptions
          "
        ></div>

        <div class="col-lg-7 col-md-12 mb-sm-5 mb-xs-5">
          <div class="position-relative d-inline-block img-section">
            <img
              class="w-100"
              :src="
                $baseurlForImages +
                this.$store.state?.homeData.home?.why_choose_us
                  ?.why_choose_us_image_url
              "
              alt=""
            />
            <div class="layer_img">
              <img
                class="img-fluid"
                :src="
                  $baseurlForImages +
                  this.$store.state?.homeData.home?.why_choose_us
                    ?.why_choose_us_image1_url
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <img
      class="img-fluid w-100"
      src="../assets/img/why_choose_us_banner.png"
      alt=""
    />
  </div>
  <!---Why CHoose us--->
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";

import {
  defineAsyncComponent,
  ref,

  //onBeforeUnmount,
} from "vue";

//import { useHead } from "@unhead/vue"; // Import the useHead function
import { GET_HOME_PAGE_DATA_ACTION } from "@/store/storeconstants";

const AsyncPartialComponent = defineAsyncComponent(
  () => import("./ChatWidget.vue") // Path to your partial component
);

export default {
  metaInfo: {
    title: "Page Title-----",
    meta: [
      {
        name: "description",
        content: "This is a description of the page content.",
      },
      // Add more meta tags as needed.
    ],
  },
  name: "PrintingServices",
  components: {
    Carousel,
    Navigation,
    Slide,
    AsyncPartialComponent,
  },

  setup() {
    const showComponent = ref(false);

    const loadComponent = () => {
      showComponent.value = true;
    };

    return {
      showComponent,
      loadComponent,
    };
  },
  data: function () {
    return {
      printingsevice: null,
      slides: [],
      currentSlide: 1,

      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        320: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        400: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        500: {
          itemsToShow: 1.5,
          snapAlign: "start",
        },
        700: {
          itemsToShow: 2.8,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.9,
          snapAlign: "start",
        },
      },
    };
  },

  methods: {
    ...mapActions("homeData", {
      homePageData: GET_HOME_PAGE_DATA_ACTION,
    }),
    handleInit() {
      // Get Recents 15 Products for show in owl carousel
      this.$axios
        .get(process.env.VUE_APP_BASE_URL + "getRecent15Product")
        .then((res) => {
          this.slides = res.data.data;

          //console.log("this.slides", this.slides);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleSlideStart(data) {
      console.log(data, "Slide");
      this.currentSlide = data.currentSlideIndex + 1;
    },

    goToProductpage(purl) {
      this.$router.push("/product/" + purl);

      //location.href = process.env.VUE_APP_BASE_URL_LOCAL + "/pages/printing/" + purl;
    },
  },

  created() {
    // const head = useHead(); // Initialize the head object
    //const { title, meta } = useHead();

    // // // Define and update meta tags dynamically
    // head.title = "My Page Title"; // Update the page title
    //document.title = "My Page Title..ji";
    // head.meta.push({ name: "description", content: "My page description" }); // Add or update a meta tag
    // Update the title and meta tags when the component is mounted
    // console.log("Title...?", title);
    // title.value = "My Page - Vue 3 Example";
    // meta.value = [
    //   {
    //     name: "description",
    //     content: "A Vue 3 example with @vueuse/head",
    //   },
    //   {
    //     property: "og:title",
    //     content: "My Page - Vue 3 Example",
    //   },
    // ];

    // Optionally, clear the title and meta tags when the component is unmounted
    // onBeforeUnmount(() => {
    //   title.value = "";
    //   meta.value = [];
    // });

    this.homePageData()
      .then((res) => {
        this.printingsevice =
          res.data.data.looking_service.printing_service_heading;

        if (res.data.data?.pageData?.meta_title) {
          document.title = res.data.data?.pageData?.meta_title;
          document.querySelector('meta[name="title"]').content =
            res.data.data?.pageData?.meta_title;
        }

        if (res.data.data?.pageData?.meta_title)
          document.querySelector('meta[name="keywords"]').content =
            res.data.data?.pageData?.meta_keywords;

        if (res.data.data?.pageData?.meta_title)
          document.querySelector('meta[name="description"]').content =
            res.data.data?.pageData?.meta_description;

        // document.metaInfo.name.title =
        //For seo secton start  //

        // let title = document.createElement(`TITLE`);
        // title.innerText = this.$t(document.title);

        // document.querySelector(`head`).appendChild(title);

        // adding  meta
        let meta = document.createElement(`META`);
        meta.setAttribute(`name`, `description`);
        meta.setAttribute(`content`, res.data.data?.pageData?.meta_description);
        meta.setAttribute(`name`, `keywords`);
        meta.setAttribute(`content`, res.data.data?.pageData?.meta_keywords);

        document.querySelector(`head`).appendChild(meta);

        if (res.data.data.success) {
          // End ///
          /// console.log(res, "lllll0-----???-home....");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updated() {},
};
</script>
