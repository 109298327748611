import $ from 'jquery';
//import html2canvas from 'html2canvas';
//import Hermite_class from 'hermite-resize';
// eslint-disable-next-line no-unused-vars
// export default function Store_template(project = false, active = false, move = false) {
export default function Store_template( project, active, width, height, ratio, move) {

    console.log('Project', project, 'Active', active, 'Width', width, 'height', height, 'Ratio', ratio, 'Move', move, "hello Console Data")

    var mouseDownState = false;
    var mouseDownEvent = false;
    //var get_left = false;


    /*****set frameowrk artwork to its initial position */
    $('document').ready(function () {
        console.log(project, 'project.larger_section')
        let styles1 = {
            // 'height': project.larger_section + 50 + 'px'
            'height': project.main_width + 50 + 'px'
        }

        $('#workspace').css(styles1)
        let styles2 = {
            'width': '80%',
            'min-width': '1000px'
        }
        $('#canvaspopup .modal-dialog').css(styles2)
        let mainartstyle = {
            'margin-top': project.artwork_margin_top+'px',
            'margin-left': project.artwork_margin_left + 'px',
            'width': project.main_width + 'px',
            'height': project.main_height + 'px',
            'position': 'relative'

        }
        $('.main-artwork').css(mainartstyle)
        console.log(mainartstyle, "Hello Main")

        let template_style = {
            'position': 'absolute',
            'z-index': 1,
            'width': project.main_width + 'px',
            'height': project.main_height + 'px',
            // 'width': Math.round(project.main_width) + 'px',
            // 'height': Math.round(project.main_width) + 'px',

        }
        $('.template-bg').css(template_style)
        // if (eval("project." + active +".file"+ ".rotate") > 0) {
        //  var pos = $("#main-" + active + "-template").position();
        //  move.width = pos.left + (project.newheight - move.width) / 2;
        //  move.height= pos.top + (project.newwidth - move.height) / 2;
        // } else {
        //  move.width = (project.newwidth - move.width) / 2;
        //  move.height = (project.newheight - move.height) / 2;
        // }
        // console.log(`'top':${move.top}, 'left': ${move.left}, 'width': ${move.width}, 'height': ${move.height}`)
        $('#main-' + active + " .user-artwork").css({ 
            // 'top': move.top, 
            // 'left': move.left, 
            // 'width': move.width, 
            // 'height': move.height, 

            'top': 0,
            'left': 0,
            'width': project.main_width + 'px',
            'height': project.main_height + 'px',
        })
    })
    /*****set frameowrk artwork to its initial position */

    $(document).ready(function () { 
        // $("#workspace .main-artwork").first().removeClass("hidden");
        setTimeout(function () {
            $('.artwork-selection').parent().css({ height: 175 });
        }, 3000);

        // var formdata = false;
        
        // $('.artwork-selection').on('click', function () {
        //     alert("Hello Main")
        //     $('.artwork-selection').removeClass('selected');
        //     $(this).addClass('selected');
        //     active = $(this).attr('rel');
        //     $('.main-artwork').addClass('hidden');
        //     $('#main-' + active).removeClass('hidden');
        // });


        $('#rotate-template').on('click', function () {
            if (active == '') {
                active = $('.artwork-selection.selected').attr('rel');
            }

            // console.log('#main-' + active);
            if ($('#main-' + active + ' .template').hasClass('rotate90')) {
                $('#main-' + active + ' .template').removeClass('rotate90');
                $('#main-' + active + ' .template-bg').removeClass('rotate90');
                $('#canvasimage').css('height', 'auto');

                if (active == 'front') {
                    project.front.rotate = 0;
                } else if (active == "back") {
                    project.back.rotate = 0;
                } else if (active == "frontuv") {
                    project.frontuv.rotate = 0;
                } else if (active == "backuv") {
                    project.backuv.rotate = 0;
                } else if (active == "mattedull") {
                    project.mattedull.rotate = 0;
                } else if (active == "backmask") {
                    project.backmask.rotate = 0;
                } else if (active == "frontmask") {
                    project.frontmask.rotate = 0;
                }

            } else {
                $('#main-' + active + '-template').addClass('rotate90');
                $('#main-' + active + ' .template-bg').addClass('rotate90');
                $('#canvasimage').css('height', '800px');

                if (active == 'front') {
                    project.front.rotate = 90;
                } else if (active == "back") {
                    project.back.rotate = 90;
                } else if (active == "frontuv") {
                    project.frontuv.rotate = 90;
                } else if (active == "backuv") {
                    project.backuv.rotate = 90;
                } else if (active == "mattedull") {
                    project.mattedull.rotate = 90;
                } else if (active == "backmask") {
                    project.backmask.rotate = 90;
                } else if (active == "frontmask") {
                    project.frontmask.rotate = 90;
                }
            }

            var tmpwidth = project.width;
            project.width = project.height;
            project.height = tmpwidth
        });

        $('#rotate-art-right').on('click', function () {
            console.log(move, "move on  ")
            console.log(project, "project")
            project.front.file.rotate = project.front.file.rotate + 90;
            $('#main-' + active + " .user-artwork").css({ 'transform': 'rotate(' + project.front.file.rotate + 'deg)' });
            $('.artwork-selection.selected + .upld-artwork-small img').css({ 'transform': 'rotate(' + project.front.file.rotate + 'deg)' });
            $('#main-' + active + " .user-artwork").css({ 
                // 'top': move.top, 
                // 'left': move.left, 
                // 'width': project.main_width, 
                // 'height': project.main_height 
            })
        });


        $('#rotate-art-left').on('click', function () {
            console.log(move, "move on  ")
            console.log(project, "project")
            project.front.file.rotate = project.front.file.rotate + 90;
            $('#main-' + active + " .user-artwork").css({ 'transform': 'rotate(' + project.front.file.rotate + 'deg)' });
            $('.artwork-selection.selected + .upld-artwork-small img').css({ 'transform': 'rotate(' + project.front.file.rotate + 'deg)' });
            $('#main-' + active + " .user-artwork").css({ 
                // 'top': move.top, 
                // 'left': move.left, 
                // 'width': project.main_width, 
                // 'height': project.main_height
            })
        });

        // control buttons

        // scale artwork up
        $('#rotate-scale-up').on('click', function () {
           
            if (active == 'front') {
                project.front.file.scale = project.front.file.scale + 1;
                let divwidth = (project.main_width * (project.front.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.front.file.scale / 100)).toFixed(3);
               
                let pos = ''
                let marginx = ''
                let marginy = ''

                $('#main-front .user-artwork').css({ 'width': divwidth, 'height': divheight });
                if (eval("project." + active + ".rotate") > 0) {
                     pos = $('#main-' + active + '-template').position(); 
                    console.log(pos, "position rotate-scale-up");
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    console.log("project.main_height",project.main_height, "divheight", divheight)
                    console.log("project.main_width",project.main_width, "divwidth", divwidth)
                    marginy = ((project.main_height - divheight)) / 2;
                    marginx = ((project.main_width - divwidth)) / 2;
                }
               
                console.log("top", marginy, "left", marginx , "rotate-scale-up")
                $('#main-front .user-artwork').css({ 'top': marginy, 'left': marginx });
                // update project object
                project.front.file.positionx = marginx
                project.front.file.positiony = marginy;

            } else if (active == 'back') {
                console.log('Project Back', project.back);
                project.back.file.scale = project.back.file.scale + 1;
                let divwidth = (project.main_width * (project.back.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.back.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-back .user-artwork').css({ 'width': divwidth, 'height': divheight });
                if (eval("project." + active + ".rotate") > 0) {
                    console.log(active, 'active rotate-scale-up in rotation if')
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }
                console.log("top", marginy, "left", marginx , "rotate-scale-up")
                $('#main-back .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.back.file.positionx = marginx;
                project.back.file.positiony = marginy;
            } else if (active == 'frontuv') {
                project.frontuv.file.scale = project.frontuv.file.scale + 1;
                let divwidth = (project.main_width * (project.frontuv.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.frontuv.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-frontuv .user-artwork').css({ 'width': divwidth, height: divheight });
                //var marginy = (project.height - divheight) / 2;
                //var marginx = (project.width - divwidth) / 2;
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.newheight - divheight) / 2;
                    marginx = (project.newwidth - divwidth) / 2;
                }
                // marginy = (marginy < 0) ? 0 : marginy
                $('#main-frontuv .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.frontuv.file.positionx = marginx;
                project.frontuv.file.positiony = marginy;

            } else if (active == 'backuv') {
                project.backuv.file.scale = project.backuv.file.scale + 1;
                let divwidth = (project.main_width * (project.backuv.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.backuv.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-backuv .user-artwork').css({ 'width': divwidth, height: divheight });
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }
                
                $('#main-backuv .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.backuv.file.positionx = marginx;
                project.backuv.file.positiony = marginy;

            } else if (active == 'mattedull') {
                project.mattedull.file.scale = project.mattedull.file.scale + 1;
                let divwidth = (project.main_width * (project.mattedull.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.mattedull.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-mattedull .user-artwork').css({ 'width': divwidth, height: divheight });
                
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_height - divheight) / 2;
                    marginx = pos.left + (project.main_width - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }
                
                $('#main-mattedull .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.mattedull.file.positionx = marginx;
                project.mattedull.file.positiony = marginy;

            } else if (active == 'frontmask') {
                project.frontmask.file.scale = project.frontmask.file.scale + 1;
                let divwidth = (project.main_width * (project.frontmask.file.scale / 100)).toFixed(3);
                let divheight = (project.main_ * (project.frontmask.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-frontmask .user-artwork').css({ 'width': divwidth, height: divheight });
                //var marginy = (project.height - divheight) / 2;
                //var marginx = (project.width - divwidth) / 2;
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_height - divheight) / 2;
                    marginx = pos.left + (project.main_width - divwidth) / 2;
                } else {
                    marginy = (project.newheight - divheight) / 2;
                    marginx = (project.newwidth - divwidth) / 2;
                }
                // marginy = (marginy < 0) ? 0 : marginy
                $('#main-frontmask .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.frontmask.file.positionx = marginx;
                project.frontmask.file.positiony = marginy;

            } else if (active == 'backmask') {
                project.backmask.file.scale = project.backmask.file.scale + 1;
                let divwidth = (project.main_width * (project.backmask.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.backmask.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-backmask .user-artwork').css({ 'width': divwidth, height: divheight });
                //var marginy = (project.height - divheight) / 2;
                //var marginx = (project.width - divwidth) / 2;
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_height - divheight) / 2;
                    marginx = pos.left + (project.main_width - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }
                // marginy = (marginy < 0) ? 0 : marginy
                $('#main-backmask .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.backmask.file.positionx = marginx;
                project.backmask.file.positiony = marginy;

            }
        })

        // scale artwork down
        $('#rotate-scale-down').on('click', function () {
            if (active == 'front') {
                project.front.file.scale = project.front.file.scale - 1;
                let divwidth = (project.main_width * (project.front.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.front.file.scale / 100)).toFixed(3);
                 
                let pos = ''
                let marginx = '';
                let marginy = '';


                $('#main-front .user-artwork').css({ 'width': divwidth, height: divheight });
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position(); 
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }

                console.log("top", marginy, "left", marginx ,'rotate-scale-down')
                $('#main-front .user-artwork').css({ 'top': marginy, 'left': marginx });

                project.front.file.positionx = marginx
                project.front.file.positiony = marginy;

            } else if (active == 'back') {
                project.back.file.scale = project.back.file.scale - 1;
                let divwidth = (project.main_width * (project.back.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.back.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-back .user-artwork').css({ 'width': divwidth, height: divheight });
                //var marginy = (project.height - divheight) / 2;
                //var marginx = (project.width - divwidth) / 2;
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }
                console.log("top", marginy, "left", marginx , "rotate-scale-down")
                $('#main-back .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.back.file.positionx = marginx;
                project.back.file.positiony = marginy;
            } else if (active == 'frontuv') {
                project.frontuv.file.scale = project.frontuv.file.scale - 1;
                let divwidth = (project.main_width * (project.frontuv.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.frontuv.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-frontuv .user-artwork').css({ 'width': divwidth, height: divheight });
               
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }

                $('#main-frontuv .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.frontuv.file.positionx = marginx;
                project.frontuv.file.positiony = marginy;

            } else if (active == 'backuv') {
                project.backuv.file.scale = project.backuv.file.scale - 1;
                let divwidth = (project.main_width * (project.backuv.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.backuv.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-backuv .user-artwork').css({ 'width': divwidth, height: divheight });
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }

                $('#main-backuv .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.backuv.file.positionx = marginx;
                project.backuv.file.positiony = marginy;

            } else if (active == 'mattedull') {
                project.mattedull.file.scale = project.mattedull.file.scale - 1;
                let divwidth = (project.main_width * (project.mattedull.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.mattedull.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-mattedull .user-artwork').css({ 'width': divwidth, height: divheight });

                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }

                $('#main-mattedull .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.mattedull.file.positionx = marginx;
                project.mattedull.file.positiony = marginy;

            } else if (active == 'frontmask') {
                project.frontmask.file.scale = project.frontmask.file.scale - 1;
                let divwidth = (project.main_width * (project.frontmask.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.frontmask.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-frontmask .user-artwork').css({ 'width': divwidth, height: divheight });
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }

                $('#main-frontmask .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.frontmask.file.positionx = marginx;
                project.frontmask.file.positiony = marginy;

            } else if (active == 'backmask') {
                project.backmask.file.scale = project.backmask.file.scale - 1;
                let divwidth = (project.main_width * (project.backmask.file.scale / 100)).toFixed(3);
                let divheight = (project.main_height * (project.backmask.file.scale / 100)).toFixed(3);
                let marginx = ''
                let marginy = ''
                let pos = ''
                $('#main-backmask .user-artwork').css({ 'width': divwidth, height: divheight });
                //var marginy = (project.height - divheight) / 2;
                //var marginx = (project.width - divwidth) / 2;
                if (eval("project." + active + ".rotate") > 0) {
                    pos = $('#main-' + active + '-template').position();
                    marginy = pos.top + (project.main_width - divheight) / 2;
                    marginx = pos.left + (project.main_height - divwidth) / 2;
                } else {
                    marginy = (project.main_height - divheight) / 2;
                    marginx = (project.main_width - divwidth) / 2;
                }

                $('#main-backmask .user-artwork').css({ 'top': marginy, 'left': marginx });
                project.backmask.file.positionx = marginx;
                project.backmask.file.positiony = marginy;

            }
        })

        // scale reset function 
        $('#rotate-scale-reset').on('click', function () {
            console.log(project, 'project')
            if (active == 'front') {
                project.front.file.scale = 100;
                $('#main-front .user-artwork img').css({ 'width': project.front.file.scale + '%' });
                // $('#main-front .user-artwork').css({ width: project.front.file.scaled_width, height: project.front.file.scaled_height });
                // let marginy = (project.newheight - project.front.file.scaled_height) / 2;
                // let marginx = (project.newwidth - project.front.file.scaled_width) / 2;
                $('#main-front .user-artwork').css({ width: project.main_width, height: project.main_height }); 
                let marginy = 0;
                let marginx = 0;
                $('#main-front .user-artwork').css({'top': marginy, 'left': marginx,});
                project.front.file.positionx = marginx;
                project.front.file.positiony = marginy;
            } else if (active == 'back') {
                project.back.file.scale = 100;
                $('#main-back .user-artwork img').css({ 'width': project.back.file.scale + '%' });
                // $('#main-back .user-artwork').css({ width: project.back.file.scaled_width, height: project.back.file.scaled_height }); 
                // let marginy = (project.newheight - project.back.file.scaled_height) / 2;
                // let marginx = (project.newwidth - project.back.file.scaled_width) / 2;
                $('#main-front .user-artwork').css({ width: project.main_width, height: project.main_height });
                let marginy = 0;
                let marginx = 0;
                $('#main-back .user-artwork').css({'top': marginy, 'left': marginx}); 
                project.back.file.positionx = marginx;
                project.back.file.positiony = marginy;
            } else if (active == 'frontuv') {
                project.frontuv.file.scale = 100;
                $('#main-frontuv .user-artwork img').css({ 'width': project.frontuv.file.scale + '%' });
                // $('#main-frontuv .user-artwork').css({ width: project.frontuv.file.scaled_width, height: project.frontuv.file.scaled_height });
                // let marginy = (project.newheight - project.frontuv.file.scaled_height) / 2;
                // let marginx = (project.newwidth - project.frontuv.file.scaled_width) / 2;
                $('#main-front .user-artwork').css({ width: project.main_width, height: project.main_height });
                let marginy = 0;
                let marginx = 0;
                $('#main-frontuv .user-artwork').css({'top': marginy, 'left': marginx}); 
                project.frontuv.file.positionx = marginx;
                project.frontuv.file.positiony = marginy;

            } else if (active == 'backuv') {
                project.backuv.file.scale = 100;
                $('#main-backuv .user-artwork img').css({ 'width': project.backuv.file.scale + '%' });
                // $('#main-backuv .user-artwork').css({ width: project.backuv.file.scaled_width, height: project.backuv.file.scaled_height });
                // let marginy = (project.newheight - project.backuv.file.scaled_height) / 2;
                // let marginx = (project.newwidth - project.backuv.file.scaled_width) / 2;
                $('#main-front .user-artwork').css({ width: project.main_width, height: project.main_height });
                let marginy = 0;
                let marginx = 0;
                $('#main-backuv .user-artwork').css({'top': marginy, 'left': marginx}); 
                project.backuv.file.positionx = marginx;
                project.backuv.file.positiony = marginy;

            } else if (active == 'mattedull') {
                project.mattedull.file.scale = 100;
                $('#main-mattedull .user-artwork img').css({ 'width': project.mattedull.file.scale + '%' });
                // $('#main-mattedull .user-artwork').css({ width: project.mattedull.file.scaled_width, height: project.mattedull.file.scaled_height });
                // let marginy = (project.newheight - project.mattedull.file.scaled_height) / 2;
                // let marginx = (project.newwidth - project.mattedull.file.scaled_width) / 2;
                $('#main-front .user-artwork').css({ width: project.main_width, height: project.main_height });
                let marginy = 0;
                let marginx = 0;
                $('#main-mattedull .user-artwork').css({'top': marginy, 'left': marginx}); 
                project.mattedull.file.positionx = marginx;
                project.mattedull.file.positiony = marginy;

            } else if (active == 'frontmask') {
                project.frontmask.file.scale = 100;
                $('#main-frontmask .user-artwork img').css({ 'width': project.frontmask.file.scale + '%' });
                // $('#main-frontmask .user-artwork').css({ width: project.frontmask.file.scaled_width, height: project.frontmask.file.scaled_height });
                // let marginy = (project.newheight - project.frontmask.file.scaled_height) / 2;
                // let marginx = (project.newwidth - project.frontmask.file.scaled_width) / 2;
                $('#main-front .user-artwork').css({ width: project.main_width, height: project.main_height });
                let marginy = 0;
                let marginx = 0;
                $('#main-frontmask .user-artwork').css({'top': marginy, 'left': marginx}); 
                project.frontmask.file.positionx = marginx;
                project.frontmask.file.positiony = marginy;

            } else if (active == 'backmask') {
                project.backmask.file.scale = 100;
                $('#main-backmask .user-artwork img').css({ 'width': project.backmask.file.scale + '%' });
                // $('#main-backmask .user-artwork').css({ width: project.backmask.file.scaled_width, height: project.backmask.file.scaled_height });
                // let marginy = (project.newheight - project.backmask.file.scaled_height) / 2;
                // let marginx = (project.newwidth - project.backmask.file.scaled_width) / 2;
                 $('#main-front .user-artwork').css({ width: project.main_width, height: project.main_height });
                let marginy = 0;
                let marginx = 0;
                $('#main-backmask .user-artwork').css({'top': marginy, 'left': marginx}); 
                project.backmask.file.positionx = marginx;
                project.backmask.file.positiony = marginy;

            }

        });

        //    Move artwork
        $('#artwork-move-left').on('mousedown', function () {
            mouseDownState = true;
            triggerMouseEvent('left');
        }).on('mouseup', function () {
            mouseDownState = false;
            clearTimeout(mouseDownEvent)
        });

        $('#artwork-move-up').on('mousedown', function () {
            mouseDownState = true;
            triggerMouseEvent('up');

        }).on('mouseup', function () {
            mouseDownState = false;
            clearTimeout(mouseDownEvent);
        });

        $('#artwork-move-right').on('mousedown', function () {

            mouseDownState = true;
            triggerMouseEvent('right');

        }).on('mouseup', function () {
            mouseDownState = false;

            clearTimeout(mouseDownEvent)
        });
        
        $('#artwork-move-down').on('mousedown', function () {
            //activate_left();
            mouseDownState = true;
            triggerMouseEvent('down');

        }).on('mouseup', function () {
            mouseDownState = false;
            clearTimeout(mouseDownEvent)
        });

    });

    //reset artwork
    $('#image-uploader').on('change', function (e) {
        e.preventDefault();
        console.log("upload triggered...:"+active);
        //var imageControl = $(this);
        //formdata = new FormData();
        var file = this.files[0];
        console.log(file)
    })

    // function update_small(){
    //     console.log(html2canvas())
    //     if(active == 'front'){
    //             html2canvas(document.getElementById('main-front'), {
    //     onrendered:function (canvas){
    //         var HERMITE = new Hermite_class();
    //         HERMITE.resample_single(canvas, (canvas.width*project.left_ratio/100), (canvas.height*project.left_ratio/100), true);
    //         img_b64 = canvas.toDataURL('image/png');
    //         blob = dataURItoBlob(img_b64)
    //         img = document.getElementById('frnt-sml');
    //         img.src = URL.createObjectURL(blob);
    //     }
    //     });
    //     } else {
    //     html2canvas(document.getElementById('main-back-template'), {
    //     onrendered:function (canvas){
    //         var HERMITE = new Hermite_class();
    //         HERMITE.resample_single(canvas, (canvas.width*project.left_ratio/100), (canvas.height*project.left_ratio/100), true);
    //         img_b64 = canvas.toDataURL('image/png');
    //         blob = dataURItoBlob(img_b64)
    //         img = document.getElementById('bck-sml');
    //         img.src = URL.createObjectURL(blob);
    //     }
    //     });
    //     }
    // }


    function triggerMouseEvent(type) {
        if (mouseDownState == false) {
            return;
        } else {
            if (type == 'left') {
                if (active == 'front') {
                    project.front.file.positionx = project.front.file.positionx - 1;
                    $('#main-front .user-artwork').css({ 'left': project.front.file.positionx });
                } else if (active == 'back') {
                    project.back.file.positionx = project.back.file.positionx - 1;
                    $('#main-back .user-artwork').css({ 'left': project.back.file.positionx });
                } else if (active == 'backuv') {
                    project.backuv.file.positionx = project.backuv.file.positionx - 1;
                    $('#main-backuv .user-artwork').css({ 'left': project.backuv.file.positionx });
                } else if (active == 'frontuv') {
                    project.frontuv.file.positionx = project.frontuv.file.positionx - 1;
                    $('#main-frontuv .user-artwork').css({ 'left': project.frontuv.file.positionx });
                } else if (active == 'mattedull') {
                    project.mattedull.file.positionx = project.mattedull.file.positionx - 1;
                    $('#main-mattedull .user-artwork').css({ 'left': project.mattedull.file.positionx });
                } else if (active == 'frontmask') {
                    project.frontmask.file.positionx = project.frontmask.file.positionx - 1;
                    $('#main-frontmask .user-artwork').css({ 'left': project.frontmask.file.positionx });
                } else if (active == 'backmask') {
                    project.backmask.file.positionx = project.backmask.file.positionx - 1;
                    $('#main-backmask .user-artwork').css({ 'left': project.backmask.file.positionx });
                }
            } else if (type == 'up') {
                if (active == 'front') {
                    console.log(project.front.file.positiony, "up front value");
                    project.front.file.positiony = project.front.file.positiony - 1;
                    $('#main-front .user-artwork').css({ 'top': project.front.file.positiony });
                } else if (active == 'back') {
                    //project.front.file.positiony=(project.front.file.positiony<0)?0:project.front.file.positiony;
                    project.back.file.positiony = project.back.file.positiony - 1;
                    $('#main-back .user-artwork').css({ 'top': project.back.file.positiony });
                } else if (active == 'frontuv') {
                    //project.frontuv.file.positiony=(project.frontuv.file.positiony<0)?0:project.frontuv.file.positiony;
                    project.frontuv.file.positiony = project.frontuv.file.positiony - 1;
                    $('#main-frontuv .user-artwork').css({ 'top': project.frontuv.file.positiony });
                } else if (active == 'backuv') {
                    //project.backuv.file.positiony=(project.backuv.file.positiony<0)?0:project.backuv.file.positiony
                    project.backuv.file.positiony = project.backuv.file.positiony - 1;
                    $('#main-backuv .user-artwork').css({ 'top': project.backuv.file.positiony });
                } else if (active == 'mattedull') {
                    //project.mattedull.file.positiony =(project.mattedull.file.positiony<0)?0:project.mattedull.file.positiony
                    project.mattedull.file.positiony = project.mattedull.file.positiony - 1;
                    $('#main-mattedull .user-artwork').css({ 'top': project.mattedull.file.positiony });
                } else if (active == 'frontmask') {
                    //project.frontmask.file.positiony=(project.frontmask.file.positiony <0)?0:project.frontmask.file.positiony
                    project.frontmask.file.positiony = project.frontmask.file.positiony - 1;
                    $('#main-frontmask .user-artwork').css({ 'top': project.frontmask.file.positiony });
                } else if (active == 'backmask') {
                    //project.backmask.file.positiony =(project.backmask.file.positiony < 0 )?0:project.backmask.file.positiony 
                    project.backmask.file.positiony = project.backmask.file.positiony - 1;
                    $('#main-backmask .user-artwork').css({ 'top': project.backmask.file.positiony });
                }

            } else if (type == 'right') {
                if (active == 'front') {
                    project.front.file.positionx = project.front.file.positionx + 1;
                    $('#main-front .user-artwork').css({ 'left': project.front.file.positionx });
                } else if (active == 'back') {
                    project.back.file.positionx = project.back.file.positionx + 1;
                    $('#main-back .user-artwork').css({ 'left': project.back.file.positionx });
                } else if (active == 'frontuv') {
                    project.frontuv.file.positionx = project.frontuv.file.positionx + 1;
                    $('#main-frontuv .user-artwork').css({ 'left': project.frontuv.file.positionx });
                } else if (active == 'backuv') {
                    project.backuv.file.positionx = project.backuv.file.positionx + 1;
                    $('#main-backuv .user-artwork').css({ 'left': project.backuv.file.positionx });
                } else if (active == 'mattedull') {
                    project.mattedull.file.positionx = project.mattedull.file.positionx + 1;
                    $('#main-mattedull .user-artwork').css({ 'left': project.mattedull.file.positionx });
                } else if (active == 'frontmask') {
                    project.frontmask.file.positionx = project.frontmask.file.positionx + 1;
                    $('#main-frontmask .user-artwork').css({ 'left': project.frontmask.file.positionx });
                } else if (active == 'backmask') {
                    project.backmask.file.positionx = project.backmask.file.positionx + 1;
                    $('#main-backmask .user-artwork').css({ 'left': project.backmask.file.positionx });
                }

            } else if (type == 'down') {
                if (active == 'front') {
                    project.front.file.positiony = project.front.file.positiony + 1;
                    $('#main-front .user-artwork').css({ 'top': project.front.file.positiony });
                } else if (active == 'back') {
                    //project.back.file.positiony =(project.back.file.positiony<0)?0:project.back.file.positiony
                    project.back.file.positiony = project.back.file.positiony + 1;
                    $('#main-back .user-artwork').css({ 'top': project.back.file.positiony });
                } else if (active == 'frontuv') {
                    //project.frontuv.file.positiony = (project.frontuv.file.positiony < 0)?0:project.frontuv.file.positiony 
                    project.frontuv.file.positiony = project.frontuv.file.positiony + 1;
                    $('#main-frontuv .user-artwork').css({ 'top': project.frontuv.file.positiony });
                } else if (active == 'backuv') {
                    //project.backuv.file.positiony = (project.backuv.file.positiony < 0)?0:project.backuv.file.positiony
                    project.backuv.file.positiony = project.backuv.file.positiony + 1;
                    $('#main-backuv .user-artwork').css({ 'top': project.backuv.file.positiony });
                } else if (active == 'mattedull') {
                    //project.mattedull.file.positiony = (project.mattedull.file.positiony < 0)?0:project.mattedull.file.positiony
                    project.mattedull.file.positiony = project.mattedull.file.positiony + 1;
                    $('#main-mattedull .user-artwork').css({ 'top': project.mattedull.file.positiony });
                } else if (active == 'frontmask') {
                    //project.frontmask.file.positiony = (project.frontmask.file.positiony < 0)?0:project.frontmask.file.positiony
                    project.frontmask.file.positiony = project.frontmask.file.positiony + 1;
                    $('#main-frontmask .user-artwork').css({ 'top': project.frontmask.file.positiony });
                } else if (active == 'backmask') {
                    //project.backmask.file.positiony = (project.backmask.file.positiony < 0)?0:project.backmask.file.positiony  
                    project.backmask.file.positiony = project.backmask.file.positiony + 1;
                    $('#main-backmask .user-artwork').css({ 'top': project.backmask.file.positiony });
                }
            }
            setTimeout(function () {
                mouseDownEvent = triggerMouseEvent(type);
            }, 100);
        }
    }

    // function get_left_image() {
    //     if (get_left == true) {
    //         var sendArr = new Object();
    //         sendArr.project = project;
    //         $.ajax({
    //             url: '/products/get_left',
    //             data: sendArr,
    //             type: "POST",
    //             dataType: 'json',
    //             success: function(data) {
    //                 get_left = false;
    //                 console.log(data);
    //             }
    //         });
    //     }
    //     setTimeout(get_left_image(), 5000);
    // }


}
