import SignupValidations from "@/services/SignupValidations";
import { LOGIN_TLOGIN_MUTATION, LOGIN_ACTION,LOGIN_CHOOSER_ACTION,LOGIN_TLOGIN_ACTION,LOGIN_CHOOSER_MUTATION,FIRST_REGISTERED_ACTION,FIRST_REGISTERED_MUTATION,SET_USER_LOGIN_DATA_MUTATION,SET_USER_LOGGED_IN_ACTION,SET_USER_LOGGED_IN_MUTATION, SET_USER_TOKEN_DATA_MUTATION, SIGNUP_ACTION,/*SET_USER_DATA_MUTATION*/ SET_USER_STORE_DATA_MUTATION,CLEAR_UP_LOGIN_ACTION,CLEAR_UP_LOGIN_MUTATION,CLEAR_USER_STORE_ACTION,CLEAR_USER_STORE_MUTATION,CLEAR_UP_LOGIN_FLAG_ACTION,CLEAR_UP_LOGIN_FLAG_MUTATION} from "@/store/storeconstants";

//import router from "@/router";
//import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const axios = require("axios");

export default {

    async [LOGIN_ACTION](context, payload) {
        console.log(payload)
    
        try {
         
            let result=[]
            let response = await axios.post(`${process.env.VUE_APP_BASE_URL}login`, payload)
            if (response.status === 200) {
                let getToken=response.data.data.token;  
                
                        //  await axios.get(`${process.env.VUE_APP_BASE_URL}cart/defaultShip`, {
                        //         headers: {
                        //             'Authorization': `Bearer ${getToken}`
                        //         }
                        //     }).then(response => {
                        //         //console.log('resstore..',response);
 

                        //         if (response.status === 200) {
                        //                 console.log('SET_USER_SHIPPING_MUTATION,,200....')
                        //                 context.commit(SET_USER_SHIPPING_MUTATION,
                        //                     response.data
                        //                 ) 
                        //         }

                        //     });
                
                /****login chooser */
                   /****************SAve User Store Data *****************/
                   await axios.get(`${
                    process.env.VUE_APP_BASE_URL
                }login-chooser`, {
                    headers: {
                        'Authorization': `Bearer ${getToken}`
                    }
                }).then(resstore => {
                    if (resstore.status === 200) { 
                        /*****************Open login chooser ************/
                        document.getElementById('loginchooser').click();

                        console.log('loginchooser for shipping', getToken);
                        result={
                            "data":resstore.data,"logininfo":response.data.data}
                            context.commit(SET_USER_STORE_DATA_MUTATION , {
                            UserStore: resstore.data
                            })      
        
                            
                            ///end//
        
                    }         
            
        
                }).catch((error1) => {console.log(error1)})   

                /****login chooser */
                

                context.commit(SET_USER_LOGIN_DATA_MUTATION, {
                    CurrentUserLogin: response.data.data
                   })  

                
            }
            
            return result;
            
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
         
            throw errormsg;
         
        }

    },


    /****Login chooser after registration ****/
    async [LOGIN_CHOOSER_ACTION](context, payload) {
       
       console.log("login chooser payload")
       console.log(payload)
      

       context.commit(LOGIN_CHOOSER_MUTATION, payload)

                          
         
       
       

    },

    /**** Token login  */ 
    async [LOGIN_TLOGIN_ACTION](context, payload) {
        console.log(payload, 'payload form LOGIN_TLOGIN_ACTION')
         try {
            let response = await axios.get(`${process.env.VUE_APP_BASE_URL}users/tlogin/${payload}`)
            console.log(response, 'res')
            //return false
            if (response.status === 200) {
                context.commit(LOGIN_TLOGIN_MUTATION, {
                     tlogin: response.data.data
                })
                return response
            }
        } catch (error) {
            console.log(error)
        }

    //    console.log(" token login chooser payload")
    //    console.log(payload) 
    //    context.commit(LOGIN_TLOGIN_MUTATION, payload) 

    },
    /**  */
    
    /*****Login chooser after registration ****/

    /***set login flag */
    async [SET_USER_LOGGED_IN_ACTION](context) {    
        context.commit(SET_USER_LOGGED_IN_MUTATION, {
            userLoggedin: true,         


        })
    },
    /***set login flag */

    async [SIGNUP_ACTION](context, payload) {
       
        try {
            let response = await axios.post(`${process.env.VUE_APP_BASE_URL}register`, payload)
            if (response.status === 200) {
                context.commit(SET_USER_TOKEN_DATA_MUTATION, {
                    signup: response.data.data
                })
                return response
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.code)
            throw errormsg;
        }
    },

    async [CLEAR_UP_LOGIN_ACTION](context,payload) {      
            
        context.commit(CLEAR_UP_LOGIN_MUTATION, {
            CurrentUserLogin: payload,         


        })
    },

    async [CLEAR_USER_STORE_ACTION](context,payload) {      
            
        context.commit(CLEAR_USER_STORE_MUTATION, {
            UserStore: payload,
        })
    },
  
    async [CLEAR_UP_LOGIN_FLAG_ACTION](context) {      
            
        context.commit(CLEAR_UP_LOGIN_FLAG_MUTATION , {
            userLoggedin: false,
        })
    },


    async [FIRST_REGISTERED_ACTION](context,payload) {      
            
        context.commit(FIRST_REGISTERED_MUTATION ,payload)
    },
    

};

