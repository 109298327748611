<template>
  <div class="product_list">
    <h2 class="font-20 font-weight-700">Products</h2>
    <ul v-if="this.$store.state.product.AllProducts" class="nav flex-column mt-4">
      <li class="nav-item" v-for="product in this.$store.state.product.AllProducts" :key="product.id">
        <a
          :id="'heading_' + product.id"
          class="nav-link active font-weight-700 font-12 font-family-Roboto text-dark ps-0 pe-0 text-capitalize"
          href="#"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse_' + product.id"
          aria-expanded="true"
          :aria-controls="'collapse_' + product.id"
          v-html="product.name">
        </a>

        <ul
          style="display: grid"
          class="nav collapse"
          :id="'collapse_' + product.id"
          :aria-labelledby="'heading_' + product.id"
        >
          <li
            class="nav-item"
            v-for="childproduct in product.Products"
            :key="childproduct.id"
          >
            <a
              ref="childmenu"
              :id="'child_' + childproduct.url"
              class="nav-link font-12 font-family-Roboto text-dark ps-0 pe-0 text-capitalize font-weight-400"
              :href="childproduct.url"
              @click.prevent="getSingleProductByUrl(childproduct.url)"
              v-html="
                childproduct.title != 'undefined' ? childproduct.title : 'Loading..'
              "
            ></a>
          </li>
        </ul>
      </li>
    </ul>
    <ul v-else>
      <li>Loading Products...</li>
    </ul>
  </div>
</template>

<script>
import $ from "jquery";
import { GET_PRODUCT_ACTION, GET_SINGLE_PRODUCT_ACTION } from "@/store/storeconstants";
import { mapActions } from "vuex";
import { useStore } from "vuex";

export default {
  name: "ProductNavbar",
  data() {
    return {
      allProduct: [],
      currentLocation: window.location.href,
    };
  },
  setup() {
    const store = useStore();
    let products = store.state.product.AllProducts;

    return {
      products,
    };
  },

  mounted() {
    this.getAllProduct();
  },
  methods: {
    ...mapActions("product", {
      getAllProduct: GET_PRODUCT_ACTION,
      getSingleProduct: GET_SINGLE_PRODUCT_ACTION,
    }),

    // Get Single Product By Product Url
    getSingleProductByUrl(ProductUrl) {
      let product_option_map = {
        price: 0.0,
        file: {},
        total_templates: 0,
        template_coating: [],
        hasFile: [],
        shipReturn: {},
        shipChanged: 0,
        weight: 0.0,
        changeTemplate: 0,
      };
      if (localStorage.getItem("product_option_map") != null) {
        localStorage.setItem("product_option_map", JSON.stringify(product_option_map));
      } else {
        localStorage.setItem("product_option_map", JSON.stringify(product_option_map));
      }

      this.$router.push(ProductUrl); //append url in route
      if (ProductUrl) {
        $("ol li:nth-child(3) a").text(ProductUrl);
      }

      let getPrint = location.href;

      if (getPrint.indexOf("printing") < -1) {
        //console.log("no printing")
      } else {
        this.$router.push("/product/" + ProductUrl);
      }
      this.getSingleProduct(ProductUrl);
    },
  },
  computed: {
    getprinting() {
      let print_products = this.products;
      return print_products;
    },
    getAll_products() {
      let pro = this.getAllProduct();
      return pro;
    },
  },
  created() {
    // Get All Products
    let getUrl = this.currentLocation;
    // console.log(this.$refs.myBtn)
    let cururl = getUrl.split("/");
    //  console.log(cururl[6])
    let getChildren = this.products;
    $.each(getChildren, function (i, e) {
      $.each(e.Products, function (i1, e1) {
        if (e1.url === cururl[6]) {
          let getParentElem = $(`a#child_${e1.url}`).parent().parent()[0];
          let getHeading = getParentElem.previousElementSibling;

          let getAtt = getHeading.getAttribute("id");
          if ($(getAtt).trigger("click")) {
            getHeading.nextElementSibling.classList.add("show");
            let getChildAttr = $(`a#child_${e1.url}`)[0];
            getChildAttr.click();
          }
        }
      });
    });

    this.products;
  },
};
</script>
