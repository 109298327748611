<template>
  <div class="container-fluid mt-4 mb-2 p-5">
    <div class="row">
      <div class="col-sm-6 col-lg-5 m-auto">
        <form class="form-horizontal" @submit.prevent="onLogin()" method="post">
          {{ console.log(userData, "userData from Login page") }}
          <div class="login-register-options p-5">
            <ul>
              <li class="text-center">
                <h4>Sign in</h4>
                <p>
                  If you would like to continue on without logging in yet, simply tap
                  anywhere off the popup
                </p>
                <span
                  class="d-block font-11 ms-1 mt-1 text-danger text-center"
                  v-if="ServerSideError"
                >
                  {{ ServerSideError }}
                </span>
              </li>
              <li>
                <div class="form-group">
                  <input
                    id="email"
                    type="text"
                    class="form-control"
                    placeholder="Email ID/Talent ID"
                    v-model.trim="formData.email"
                    autofocus
                    required
                    autocomplete="false"
                  />
                  <span
                    class="d-block font-11 ms-1 mt-1 text-danger text-start"
                    v-if="errors.email"
                  >
                    {{ errors.email }}
                  </span>
                </div>

                <div class="form-group">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    v-model.trim="formData.password"
                    autofocus
                    required
                    autocomplete="false"
                  />
                  <span
                    class="d-block font-11 ms-1 mt-1 text-danger text-start"
                    v-if="errors.password"
                  >
                    {{ errors.password }}
                  </span>
                </div>
                <p class="text-center">
                  <button
                    type="submit"
                    class="btn btn-template-main btn-block dopopuplogin"
                    id="login_form"
                  >
                    <i class="fa fa-sign-in"></i> Log in
                  </button>
                </p>
              </li>
              <li></li>
              <li>
                <a
                  class="btn btn-link"
                  @click.prevent="forgotpassword()"
                  data-bs-dismiss="modal"
                  :href="forgotpasswordlink"
                >
                  Forgot Your Password? :
                </a>
              </li>
              <li class="text-center">
                By signing in, you agree to our <br /><a href="#">Conditions of Use</a>
                and <a href="#">Privacy Policy</a>.
              </li>
              <li class="text-center">Or sign in with</li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <a href="#" class="btn btn-template-main btn-block"
                      ><i class="fa fa-facebook"></i> Facebook</a
                    >
                  </div>
                  <div class="col-sm-6">
                    <a href="#" class="btn btn-template-main btn-block"
                      ><i class="fa fa-envelope"></i> Google</a
                    >
                  </div>
                </div>
              </li>
              <li class="text-center">Or</li>
              <li>
                <router-link class="btn btn-info btn-block" to="/register"
                  >Create a new Account</router-link
                >
              </li>
            </ul>
          </div>
        </form>
      </div> 
    </div>
  </div>

  <!----display user role selection modal upon login--->
  <LoginChooser :storeData="userData" />
  <!----display user role selection modal upon login--->
</template>

<script>
import { useStore } from "vuex";
import "bootstrap-vue/dist/bootstrap-vue.css";
import SignupValidations from "../services/SignupValidations";
import { mapActions } from "vuex";
import { LOGIN_ACTION, SET_USER_LOGGED_IN_ACTION } from "@/store/storeconstants";
import LoginChooser from "@/components/LoginChooser.vue";
// eslint-disable-next-line no-unused-vars
import { toast } from "vue3-toastify";

export default {
  name: "LoginPage",
  components: {
    LoginChooser,
  },

  data() {
    return {
      userData: [],
      formData: {
        email: "",
        password: "",
      },
      errors: [],
      ServerSideError: "",
      token: "",
      forgotpasswordlink: process.env.VUE_APP_BASE_URL_LOCAL + "/forgot-password",
    };
  },
  setup() {
    const store = useStore();
    let userStoreData = store.state.auth;
    let checkCart =
      store.state.cartData.cart != undefined ? store.state.cartData.cart.length : 0;

    return {
      userStoreData,
      checkCart,
    };
  },
  methods: {
    ...mapActions("auth", {
      login: LOGIN_ACTION,
    }),
    ...mapActions("auth", {
      loggedin: SET_USER_LOGGED_IN_ACTION,
    }),

    forgotpassword() {
      this.$router.push("forgot-password");
    },
    onLogin() {
      // check email and password validations
      let validations = new SignupValidations(
        this.formData.email,
        this.formData.password
      );
      this.errors = validations.checkValidations();
      if ("email" in this.errors || "password" in this.errors) {
        return false;
      }

      // Login the user
      let __that = this;
      this.login(this.formData)
        .then((res) => {
          this.userData = res;
          this.loggedin();
          __that.formData = {
            email: "",
            password: "",
          };
        })
        .catch((ServerSideError) => {
          this.ServerSideError = ServerSideError;
        });
    },
  },
};
</script>

<style scoped>
.btn-template-main {
  color: #38a7bb !important;
  background-color: #ffffff !important;
  border-color: #38a7bb !important;
}

.btn {
  font-weight: 400 !important;
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  text-transform: uppercase !important;
  letter-spacing: 0.08em !important;
  padding: 6px 12px !important;
  font-size: 13px !important;
  line-height: 1.42857143 !important;
  border-radius: 2px !important;
  position: relative !important;
}

.btn-block {
  display: block !important;
  width: 100% !important;
}

.btn-template-main:hover {
  background: #38a7bb !important;
  color: #ffffff !important;
  border-color: #38a7bb !important;
  border-radius: 0px 15px 0px 15px !important;
}
.container-fluid {
  background-color: #38a7bb12;
}
</style>
