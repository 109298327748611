<template>
  <HeaderItem :user_Loggedin="userLoggedin" />
  <EmailSubscription />
  <FooterItem />
</template>

<script>
import { createApp } from "vue";
//import App from "./App.vue";
import HeaderItem from "./components/layouts/Header.vue";
import FooterItem from "./components/layouts/Footer.vue";
import { createHead } from "@unhead/vue";

const app = createApp();
const head = createHead();
app.use(head);
//createApp(App).mount("#app");
app.mount("#app");

import EmailSubscription from "./components/EmailSubscription.vue";

export default {
  name: "App",
  components: {
    HeaderItem,
    //HomeItem,
    EmailSubscription,
    FooterItem,
  },
  mounted() {
    //let getlogged = document.querySelector("nav ul li:nth-child(2)");
    // let account = document.createElement("a");
    // account.remove();
    // if (Object.keys(this.$store.state.auth.CurrentUserLogin).length !== 0) {
    //   account.innerHTML = "<span>MANAGE ACCOUNT</span>";
    //   account.setAttribute("href", "#/account");
    //   let getlogged = document.querySelector("nav ul li:nth-child(2)");
    //   getlogged.appendChild(account);
    // } else {
    //   account.remove();
    // }
  },
};
</script>
