import Validations from "./Validations";

export default class VerifyOtpValidations {
    constructor(email,password,password_confirmation) {
       // this.otp       =  otp; 
        this.email     =  email; 
        this.password  =  password; 
        this.password_confirmation = password_confirmation; 
    }

    checkValidations() {
        let errors = [];
        // otp validations
        if (!Validations.checkEmail(this.email)) {
            errors['email'] = 'Invalid   email';
        }

         //password Validations
         if (!Validations.minLength(this.password, 8)) {
            errors['password'] = 'password must be at least 8 characters';
        }

        return errors;
    }

    static getErrorMessageFromCode(msg) {
        switch (msg.message) {
            case 'Unauthorised':
                return 'Sorry you entered Invalid Credentials';
            case 'EMAIL_NOT_FOUND':
                return 'Email Not Found';
            case 'INVALID_PASSWORD':
                return 'Invalid Password';
            default:
                return 'Unexpected error occurred. Please try again';
        }
    }

}