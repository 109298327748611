<template>
    <div :style="bg_image" class="container_fluid" id="side_shrink">
        <div id="content">
            <div class="top-content">
                <div class="inner-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-sm-offset-2 text text-center mb-5">
                                <h1 class="text-center text-white" id="registertitle">
                                    <strong>Register</strong> Yourself
                                </h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8 col-sm-push-2 form-box animated fadeInDown" data-animate="fadeInDown"
                                style="opacity: 0">
                                <form @submit.prevent="onSignup()" method="post" id="register">
                                    <fieldset>
                                        <div class="form-top">
                                            <div class="form-top-left">
                                                <h3>Step 1 / 2</h3>
                                                <p>Tell us who you are:</p>
                                            </div>
                                            <div class="form-top-right">
                                                <i class="fa fa-user"></i>
                                            </div>
                                        </div>
                                        <div class="form-bottom">
                                            <div class="row">
                                                <div class="col-sm-4 col-xs-12">
                                                    <div class="form-group">
                                                        <select class="form-control" required readonly
                                                            v-model.trim="formData.role">
                                                            <option value="customer" selected>
                                                                customer
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>First Name</label>
                                                        <input class="form-control" placeholder="First Name" type="text"
                                                            autofocus required v-model.trim="formData.first_name" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Last Name</label>
                                                        <input class="form-control" placeholder="Last Name" type="text"
                                                            autofocus required v-model.trim="formData.last_name" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <input class="form-control" placeholder="E-Mail Address"
                                                            type="email" autofocus required v-model.trim="formData.email" />
                                                        <span class="d-block font-11 ms-1 mt-1 text-danger text-start"
                                                            v-if="errors.email">
                                                            {{ errors.email }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Username</label>
                                                        <input class="form-control" placeholder="Username" type="text"
                                                            autofocus required v-model.trim="formData.username" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Password</label>
                                                        <input class="form-control" id="password" placeholder="Password"
                                                            name="password" type="password" autofocus required
                                                            v-model.trim="formData.password" />
                                                        <span class="d-block font-11 ms-1 mt-1 text-danger text-start"
                                                            v-if="errors.password">
                                                            {{ errors.password }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Confirm Password</label>
                                                        <input class="form-control" placeholder="Confirm Password"
                                                            type="password" autofocus required
                                                            v-model.trim="formData.password_confirmation" />
                                                        <span class="d-block font-11 ms-1 mt-1 text-danger text-start"
                                                            v-if="errors.password">
                                                            {{ errors.password }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <vue-recaptcha  sitekey="6Lf7uXknAAAAAHXzOJdvoSNRTuohMYewR7HXOJ2m"
                                                            size="normal" 
                                                            theme="light"
                                                            hl="en"
                                                            @verify="recaptchaVerified"
                                                            @expire="recaptchaExpired"
                                                            @fail="recaptchaFailed"
                                                            @error="recaptchaError"
                                                            ref="vueRecaptcha">
                                                        </vue-recaptcha>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        

                                        <div class="mt-5">
                                            <button type="submit" class="register btn btn-lg btn-info pull-right">
                                                Register
                                            </button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            <div class="col-sm-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.container -->
        </div>
    </div>
    <Loader />
    <!----display user role selection modal upon login--->
    <LoginChooser :storeData="userData" />
    <!----display user role selection modal upon login--->
</template>

<script>
import SignupValidations from "@/services/SignupValidations";  
import vueRecaptcha from 'vue3-recaptcha2';

import {
    SIGNUP_ACTION,
    FIRST_REGISTERED_ACTION,
    SET_USER_LOGGED_IN_ACTION,
    LOGIN_CHOOSER_ACTION,
} from "../store/storeconstants";
import { toast } from "vue3-toastify";
import $ from "jquery";
import "vue3-toastify/dist/index.css";
import { mapActions } from "vuex";
export default {
    components: {
        vueRecaptcha
    },
    data() {
        return {
            recaptchaToken:'',
            userData: [],
            formData: {
                role: "customer",
                first_name: "",
                last_name: "",
                email: "",
                username: "",
                password: "",
                password_confirmation: "",
            },
            errors: [],
            ServerSideError: "",
            bg_image: {
                backgroundImage: "url(../img/bg.jpg)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            },
        };
    },

    methods: {
        recaptchaVerified(response) {
            this.recaptchaToken= response;
            console.log(response, "response re-cap")
        },

        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
        },

        recaptchaFailed() {
            console.log("Hello MAin")
        },

        recaptchaError(reason) {
            console.log(reason, "reason")
        },
 
        ...mapActions("auth", {
            signup: SIGNUP_ACTION,
        }),
        ...mapActions("auth", {
            registered: FIRST_REGISTERED_ACTION,
        }),
        ...mapActions("auth", {
            loggedin: SET_USER_LOGGED_IN_ACTION,
        }),
        ...mapActions("auth", {
            loggedchooser: LOGIN_CHOOSER_ACTION,
        }),

        onSignup() {
           
            let validations = new SignupValidations(
                this.formData.email,
                this.formData.password
            );

            this.errors = validations.checkValidations();
            if ("email" in this.errors || "password" in this.errors) {
                return false;
            }

            if (!this.recaptchaToken) {
                toast.error("Oops, you have to check the recaptcha!",{ autoClose: 2500,});
                return false;
            }
 
            // signup registration
            this.signup(this.formData)
                .then((res) => {
                    if (res.data.success == false) {
                        let allerror = res.data.message;
                        $.each(allerror, function (i, e) {
                            toast.error(e, {
                                autoClose: 4500,
                            });
                        });
                    }

                    if (res.data.success == true) {
                        this.loggedin();
                        this.registered(res.data.data);

                        this.$axios
                            .get(`${process.env.VUE_APP_BASE_URL}login-chooser`, {
                                headers: {
                                    Authorization: `Bearer ${res.data.data.token}`,
                                },
                            })
                            .then((resstore) => {
                                if (resstore.status === 200) {
                                    /*****************Open login chooser ************/

                                    toast.success("Congratulations you have registered successfully", {autoClose: 2500,});
                                    this.loggedchooser(resstore.data);
                                    setTimeout(() => {
                                        this.$router.push("/account");
                                        if (localStorage.getItem("isRegistered") == null) {
                                            localStorage.setItem("isRegistered", true);
                                        } else {
                                            localStorage.setItem("isRegistered", true);
                                        }
                                    }, 2600);
                                }
                            })
                            .catch((error1) => {
                                console.log(error1);
                            });

                        //this.signupuser(res.data.data.data);
                    }
 
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                    } else if (error.request) {
                            console.log(error.request);
                    } else {
                        console.log(error.responseText);
                    }
                });
        },

    },
   
};
</script>

<style scoped>
.btn-info {
    color: #fff !important;
    background-color: #5bc0de !important;
    border-color: #46b8da !important;
}

.btn:hover {
    border-radius: 0px 15px 0px 15px !important;
}
</style>
