<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb align-items-center mb-0">
            <li class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Home</a
              >
            </li>
            <li class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Printing</a
              >
            </li>
            <li class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >5.5" X 8.5" 50 Note pads</a
              >
            </li>
            <li
              class="breadcrumb-item font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
            >
              Cart
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <!-- Breadcrumb En d -->

  <!-- Process Steps Name Start -->
  <div class="container mt-2">
    <div class="row">
      <div class="col-md-12">
        <ul id="progress">
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            my shopping cart
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Shipping Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Payment Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize"
          >
            Checkout
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Process Steps Name End -->
  <div class="container">
    <div class="row">
      <h2 class="text-center mt-4 mb-4"><strong>Card Payment</strong></h2>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-sm-8 col-sm-push-2 form-box">
            <div class="form-top">
              <div class="form-top-left">
                <h3>Step 2 / 2</h3>
                <p>All Done! Just Make Your Payment</p>
              </div>
              <div class="form-top-right">
                <i class="fa fa-credit-card"></i>
              </div>
            </div>
            <div class="form-bottom">
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="table-responsive">
                    <table>
                      <tr>
                        <td>
                          <h4><b>Sub Total Amount &nbsp;&nbsp; :</b></h4>
                        </td>
                        <td>
                          <h4>
                            <b>&nbsp;&nbsp;$ {{ getSubtotal }} </b>
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4><b>Discount Amount &nbsp;&nbsp; :</b></h4>
                        </td>
                        <td>
                          <h4>
                            <b
                              >&nbsp;&nbsp; $
                              {{
                                getDiscount != undefined ? getDiscount : 0
                              }}</b
                            >
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4><b>Shipping Amount &nbsp;&nbsp; :</b></h4>
                        </td>
                        <td>
                          <h4 v-if="shippingCost">
                            <b>&nbsp;&nbsp; {{ shippingCost }} </b>
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>
                            <b
                              >Tax Amount
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              :</b
                            >
                          </h4>
                        </td>
                        <td>
                          <h4>
                            <b
                              >&nbsp;&nbsp; $
                              {{ getTaxDetails > 0 ? getTaxDetails : 0 }}</b
                            >
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>
                            <b
                              >Total Amount
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b
                            >
                          </h4>
                        </td>

                        <td>
                          <h4>
                            <b
                              >&nbsp;&nbsp;$
                              {{
                                getTaxDetails > 0
                                  ? (
                                      parseFloat(getTaxDetails) +
                                      parseFloat(getTotalAmount)
                                    ).toFixed(2)
                                  : getTotalAmount
                              }}
                            </b>
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!--End table responseive-->
                </div>

                <div class="col-sm-12 col-xs-12">
                  <div v-for="(item, index) in getItems" :key="index">
                    <div
                      class="total-payble"
                      id="active_class0"
                      style="text-align: left"
                    >
                      <h5>
                        <b
                          >{{ item.product_name }}:
                          {{ parseFloat(item.product_price).toFixed(2) }}</b
                        >
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-12 col-sm-12">
                  <form method="POST" accept-charset="UTF-8">
                    <!--SETTINGS-->
                    <input
                      type="hidden"
                      id="token"
                      value="4374c5c034d69629facdd7"
                    />
                    <input type="hidden" id="language" value="en" />
                    <input type="hidden" id="test" value="0" />
                    <input
                      type="hidden"
                      name="amount"
                      id="amount"
                      :value="
                        getTotalAmount !== undefined ? getTotalAmount : 0.25
                      "
                    />
                    <div class="accordion" id="accordionExample">
                      <div class="card">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-primary collapsed"
                              type="button"
                              @click.prevent="setupBilling()"
                            >
                              Setup Billing Information
                            </button>
                          </h2>
                        </div>
                        <div
                          style="display: none"
                          id="collapseThree"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <input
                              type="hidden"
                              name="id"
                              value=""
                              id="OrderBillingDetailId"
                            />

                            <input
                              type="hidden"
                              name="order_id"
                              value="1501763"
                              id="OrderBillingDetailIds"
                            />

                            <input
                              type="hidden"
                              name="email"
                              value="ajay@etelligens.in"
                            />

                            <div class="input text">
                              <label
                                class="text-muted"
                                for="OrderShippingDetailChek"
                                >Same as Shipping</label
                              >

                              <input
                                type="checkbox"
                                class="OrderShippingDetailChek"
                                id="OrderShippingDetailChek"
                              />
                            </div>

                            <div class="input text">
                              <label for="OrderBillingDetailFirstName"
                                >First Name</label
                              >
                              <input
                                class="form-control"
                                id="OrderBillingDetailFirstName"
                                required=""
                                maxlength="255"
                                placeholder="first name"
                                name="first_name"
                                type="text"
                                :value="
                                  getShippingToDetails.first_name !== ''
                                    ? getShippingToDetails.first_name
                                    : ''
                                "
                              />
                            </div>
                            <div class="input text">
                              <label for="OrderBillingDetailLastName"
                                >Last Name</label
                              >
                              <input
                                class="form-control"
                                id="OrderBillingDetailLastName"
                                required=""
                                maxlength="255"
                                placeholder="last name"
                                name="last_name"
                                type="text"
                                :value="
                                  getShippingToDetails.last_name !== ''
                                    ? getShippingToDetails.last_name
                                    : ''
                                "
                              />
                            </div>
                            <div class="input text">
                              <label for="OrderBillingDetailTitle">Title</label>
                              <input
                                class="form-control"
                                id="OrderBillingDetailTitle"
                                required=""
                                maxlength="255"
                                placeholder="Title"
                                name="title"
                                type="text"
                                :value="
                                  getShippingToDetails.title !== ''
                                    ? getShippingToDetails.title
                                    : ''
                                "
                              />
                            </div>

                            <div class="input text">
                              <label for="OrderBillingDetailAddress1"
                                >Address1</label
                              >
                              <input
                                class="form-control"
                                id="OrderBillingDetailAddress1"
                                required=""
                                maxlength="255"
                                placeholder="address1"
                                name="address1"
                                type="text"
                                :value="
                                  getShippingToDetails.address !== ''
                                    ? getShippingToDetails.address
                                    : ''
                                "
                              />
                            </div>
                            <div class="input text">
                              <label for="OrderBillingDetailAddress2"
                                >Address2</label
                              >
                              <input
                                class="form-control"
                                id="OrderBillingDetailAddress2"
                                maxlength="255"
                                placeholder="address2"
                                name="address2"
                                type="text"
                                :value="
                                  getShippingToDetails.address1 !== ''
                                    ? getShippingToDetails.address1
                                    : ''
                                "
                              />
                            </div>
                            <div class="form-group">
                              <label>Country</label>
                              <select
                                name="country"
                                class="form-control"
                                id="country"
                              >
                                <option value="">Select Country</option>
                                <option value="1">Afghanistan</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label>State</label>
                              <select
                                class="form-control"
                                name="state_id"
                                id="state"
                                state_id="33"
                              >
                                <option value="1">Alabama</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label>City </label>
                              <select
                                class="form-control"
                                name="city"
                                id="city"
                                city_id="66437"
                              >
                                <option value="65440">Accord</option>
                                <option value="65441">Acra</option>
                                <option value="65442">Adams</option>
                              </select>
                            </div>
                            <div class="input text">
                              <label for="OrderBillingDetailZip">Zip</label>
                              <input
                                class="form-control"
                                id="OrderBillingDetailZip"
                                maxlength="10"
                                placeholder="zip"
                                name="zip"
                                type="text"
                                :value="
                                  getShippingToDetails.zip_code !== ''
                                    ? getShippingToDetails.zip_code
                                    : ''
                                "
                              />
                            </div>
                            <div class="input text">
                              <label for="OrderBillingDetailDayPhone"
                                >Day Phone</label
                              >
                              <input
                                class="form-control"
                                id="OrderBillingDetailDayPhone"
                                maxlength="15"
                                step="0"
                                min="0"
                                placeholder="day phone"
                                name="day_phone"
                                type="number"
                                :value="
                                  getShippingToDetails.day_phone !== ''
                                    ? getShippingToDetails.day_phone
                                    : ''
                                "
                              />
                            </div>
                            <div class="input text">
                              <label for="OrderBillingDetailNightPhone"
                                >Night Phone</label
                              >
                              <input
                                class="form-control"
                                id="OrderBillingDetailNightPhone"
                                maxlength="15"
                                step="0"
                                min="0"
                                placeholder="night phone"
                                name="night_phone"
                                type="number"
                                :value="
                                  getShippingToDetails.night_phone !== ''
                                    ? getShippingToDetails.night_phone
                                    : ''
                                "
                              />
                            </div>

                            <div class="row no-print">
                              <div class="col-12 text-right">
                                <input
                                  class="btn btn-lg btn-primary"
                                  name="data[Order][save]"
                                  type="submit"
                                  value="Save"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-sm-12 col-xs-12 mt-4 mb-4">
                  <div class="payment-options">
                    <ul class="nav nav-pills nav-justified">
                      <li>
                        <a @click.prevent="openCard()" data-toggle="tab"
                          ><i class="fa fa-credit-card"></i> Credit/Debit
                          Card</a
                        >
                      </li>
                    </ul>

                    <div class="tab-content tab-content-inverse">
                      <div class="tab-pane" id="tab2-2">
                        <form
                          @submit.prevent="helcium_Process($event)"
                          name="helcimForm"
                          id="helcimForm"
                          method="POST"
                        >
                          <!--RESULTS-->
                          <div id="helcimResults"></div>

                          <!--SETTINGS-->
                          <!--SETTINGS-->
                          <input
                            type="hidden"
                            id="token"
                            value="4374c5c034d69629facdd7"
                          />
                          <input
                            type="hidden"
                            name="current_order_id"
                            id="current_order_id"
                          />

                          <input type="hidden" id="language" value="en" />
                          <input type="hidden" id="test" value="1" />

                          <input
                            type="hidden"
                            id="cardHolderAddress"
                            :value="
                              getShippingToDetails.address !== '' ||
                              getShippingToDetails.address1 !== ''
                                ? getShippingToDetails.address +
                                  '' +
                                  getShippingToDetails.address1
                                : ''
                            "
                          />
                          <input
                            type="hidden"
                            id="cardHolderPostalCode"
                            :value="
                              getShippingToDetails.zip_code !== ''
                                ? getShippingToDetails.zip_code
                                : ''
                            "
                          />
                          <input
                            type="hidden"
                            name="amount"
                            id="amount"
                            :value="
                              getTotalAmount !== undefined
                                ? getTotalAmount
                                : 0.25
                            "
                          />

                          <!-- CREDIT CARD FORM STARTS HERE -->
                          <div class="panel panel-default credit-card-box">
                            <div class="display-table text-center m-b-30">
                              <div class="row display-tr">
                                <div class="display-td">
                                  <img
                                    class=""
                                    src="https://hollywoodstudios.org/images/accepted_c22e0.png"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="">
                              <div class="row">
                                <div class="col-xs-12">
                                  <div class="form-group">
                                    <label for="cardNumber">CARD NUMBER</label>
                                    <div class="input-group">
                                      <input
                                        v-model="formData.card_no"
                                        type="tel"
                                        class="form-control"
                                        name="cardNumber"
                                        id="cardNumber"
                                        placeholder="Valid Card Number"
                                        autocomplete="cc-number"
                                        required
                                        autofocus=""
                                      />
                                      <span class="input-group-addon"
                                        ><i class="fa fa-credit-card"></i
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xs-12">
                                  <div class="form-group">
                                    <label for="couponCode"
                                      >CARD HOLDER NAME</label
                                    >
                                    <input
                                      v-model="formData.card_holder_name"
                                      type="text"
                                      class="form-control"
                                      name="cardHolder"
                                      id="cardHolderName"
                                      placeholder="Card Holder Name"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xs-4 col-md-4">
                                  <div class="form-group">
                                    <label for="cardExpiry"
                                      ><span class="hidden-xs">EXPIRATION</span
                                      ><span class="visible-xs-inline">EXP</span
                                      >MONTH</label
                                    >
                                    <select
                                      v-model="formData.card_expiry_month"
                                      class="form-control"
                                      name="card_expiry_month"
                                      id="cardExpiryMonth"
                                      required
                                    >
                                      <option value="01">January</option>
                                      <option value="02">February</option>
                                      <option value="03">March</option>
                                      <option value="04">April</option>
                                      <option value="05">May</option>
                                      <option value="06">June</option>
                                      <option value="07">July</option>
                                      <option value="08">August</option>
                                      <option value="09">September</option>
                                      <option value="10">October</option>
                                      <option value="11">November</option>
                                      <option value="12">December</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-xs-4 col-md-4">
                                  <div class="form-group">
                                    <label for="cardExpiry"
                                      ><span class="hidden-xs">EXPIRATION</span
                                      ><span class="visible-xs-inline">EXP</span
                                      >YEAR</label
                                    >
                                    <select
                                      v-model="formData.card_expiry_year"
                                      class="form-control"
                                      name="card_expiry_year"
                                      id="cardExpiryYear"
                                      required
                                    >
                                      <option value="23">2023</option>
                                      <option value="24">2024</option>
                                      <option value="25">2025</option>
                                      <option value="26">2026</option>
                                      <option value="27">2027</option>
                                      <option value="28">2028</option>
                                      <option value="29">2029</option>
                                      <option value="30">2030</option>
                                      <option value="31">2031</option>
                                      <option value="32">2032</option>
                                      <option value="33">2033</option>
                                      <option value="34">2034</option>
                                    </select>
                                  </div>
                                </div>

                                <div class="col-xs-4 col-md-4">
                                  <div class="form-group">
                                    <label for="cardCVC">CV CODE</label>
                                    <input
                                      v-model="formData.cardCVV"
                                      type="tel"
                                      id="cardCVV"
                                      class="form-control"
                                      name="cardCVC"
                                      placeholder="CVC"
                                      autocomplete="cc-csc"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-xs-12">
                                  <button
                                    class="subscribe btn btn-success btn-sm"
                                    id="buttonProcess"
                                    type="submit"
                                  >
                                    Make Payment
                                  </button>
                                </div>
                              </div>
                              <div
                                class="mt-2 mb-2"
                                style="display: none"
                                id="helciumerror"
                              >
                                <div class="alert alert-danger"></div>
                              </div>

                              <div class="row" style="display: none">
                                <div class="col-xs-12">
                                  <div id="helcimResults"></div>
                                  <p class="payment-errors"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- CREDIT CARD FORM ENDS HERE -->
                        </form>
                      </div>
                      <!-- /.tab -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { useStore } from "vuex";
import { mapActions } from "vuex";
import helcimProcess from "../../views/HelciumPayment/helcium.js";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

// eslint-disable-next-line no-unused-vars
//import testPayment from "../../views/HelciumPayment/test.js";

import {
  GET_PAYMENT_DATA_ACTION,
  HELCIUM_PAYMENT_ACTION,
  CLEAR_CART_DATA_ACTION,
} from "../../store/storeconstants";

export default {
  name: "HelciumPayment",
  data() {
    return {
      formData: {
        card_no: null,
        card_holder_name: null,
        card_expiry_month: null,
        card_expiry_year: null,
        cardCVV: null,
      },
      helcium: {
        token: null,
        cardNumber: null,
        cardExpiry: null,
        cardCVV: null,
        cardToken: null,
        cardHolderName: null,
        cardHolderAddress: null,
        cardHolderPostalCode: null,

        //Bank Account Name
        bankAccountToken: null,
        bankAccountType: null,
        bankAccountCorporate: null,
        bankTransitNumber: null,
        bankAccountNumber: null,
        bankFirstName: null,
        bankLastName: null,
        bankCompanyName: null,
        bankStreetAddress: null,
        bankCity: null,
        bankProvince: null,
        bankCountry: null,
        bankPostalCode: null,
        //Bank Account Name

        // OPTIONAL - ORDER
        amount: "0.00",
        currency: null,
        currencyHash: null,
        amountHash: null,
        orderNumber: null,
        customerCode: null,
        amountShipping: null,
        amountTax: null,
        amountDiscount: null,
        comments: null,

        // OPTIONAL - BILLING
        billing_name: null,
        billing_contactName: null,
        billing_businessName: null,
        billing_street1: null,
        billing_street2: null,
        billing_city: null,
        billing_province: null,
        billing_postalCode: null,
        billing_country: null,
        billing_phone: null,
        billing_email: null,
        billing_fax: null,

        // OPTIONAL - SHIPPING
        shipping_name: null,
        shipping_contactName: null,
        shipping_businessName: null,
        shipping_street1: null,
        shipping_street2: null,
        shipping_city: null,
        shipping_province: null,
        shipping_postalCode: null,
        shipping_country: null,
        shipping_phone: null,
        shipping_email: null,
        shipping_fax: null,

        // OPTIONAL - ITEMS
        items: [],

        // OPTIONAL - CAPTCHA
        captcha: null,

        // SET
        dataPOST: "",
      },
    };
  },
  setup() {
    const store = useStore();
    // let session_id = "";
    let cartData =
      store.state.cartData.cart != undefined ? store.state.cartData.cart : [];
    //let isCart = cartData !== undefined ? true : false;
    let shippingMethod =
      localStorage.getItem("shipping_methods") !== null
        ? JSON.parse(localStorage.getItem("shipping_methods"))
        : [];

    let shippingtoDetails =
      localStorage.getItem("ShippingToDetails") !== null
        ? JSON.parse(localStorage.getItem("ShippingToDetails"))
        : [];
    let current_order_id =
      localStorage.getItem("current_order_id") !== null
        ? localStorage.getItem("current_order_id")
        : "";

    let session_id = "";
    cartData.map(function (e) {
      session_id = e.session_id != undefined ? e.session_id : "";
    });

    let userlData = store.state.auth.CurrentUserLogin;

    userlData.CurrentUserLogin.token != ""
      ? userlData.CurrentUserLogin.token
      : "";

    let paymentMethod =
      localStorage.getItem("paymentMethod") !== null
        ? JSON.parse(localStorage.getItem("paymentMethod"))
        : [];

    return {
      store,
      cartData,
      shippingMethod,
      shippingtoDetails,
      paymentMethod,
      session_id,
      current_order_id,
    };
  },
  computed: {
    shippingCost() {
      let shipcost = this.shippingMethod.ship_value;
      let getship = shipcost.split("(");
      let getshipcost = getship[1].replace(")", " ");

      return getshipcost;
    },
    getTaxDetails() {
      let checktax =
        localStorage.getItem("Tax") !== null ? localStorage.getItem("Tax") : 0;
      return checktax;
    },
    getSubtotal() {
      let sub_t = this.cartData;
      let tot_price = 0;
      sub_t.forEach((e) => {
        tot_price = tot_price + e.price * e.qty;
      });
      return tot_price;
    },
    getTotalAmount() {
      let ship_cost = this.shippingCost.replace("$", " ");
      let checktax =
        localStorage.getItem("Tax") !== null ? localStorage.getItem("Tax") : 0;

      let tot_amt = parseFloat(this.getSubtotal) + parseFloat(ship_cost);

      // return tot_amt.toFixed(2);

      return (tot_amt + checktax).toFixed(2);
    },
    getItems() {
      let items = this.cartData;
      let getproducts = [];
      items.map((e) => {
        let product_title = e.product_data.title;
        let price = e.price;

        let getpdetails = {
          product_name: product_title,
          product_price: price,
        };

        getproducts.push(getpdetails);
      });

      return getproducts;
    },
    getShippingToDetails() {
      let shippingTo = this.shippingtoDetails;
      let shipdata = {};
      let name = shippingTo.shipping_to_full_name;
      let email = shippingTo.shipping_to_email;
      let address = shippingTo.shipping_to_address;
      let address1 = shippingTo.shipping_to_address_1;
      let city = shippingTo.shipping_to_city;
      let state = shippingTo.shipping_to_state;
      let country = shippingTo.shipping_to_country;
      let day_phone = shippingTo.shipping_to_day_phone;
      let night_phone = shippingTo.shipping_to_night_phone;
      let zip_code = shippingTo.shipping_to_zip;

      let ful_name = name.split(" ");
      let first_name = ful_name[0];
      let last_name = ful_name[1];
      shipdata = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        address: address,
        address1: address1,
        city: city,
        state: state,
        country: country,
        day_phone: day_phone,
        night_phone: night_phone,
        zip_code: zip_code,
      };
      return shipdata;
    },
  },

  methods: {
    ...mapActions("cartData", {
      savePaymentData: GET_PAYMENT_DATA_ACTION,
    }),
    ...mapActions("cartData", {
      Helciumpayment: HELCIUM_PAYMENT_ACTION,
    }),
    ...mapActions("cartData", {
      clearCart: CLEAR_CART_DATA_ACTION,
    }),

    openCard() {
      if ($("#tab2-2.tab-pane:visible").length <= 0) {
        $("#tab2-2.tab-pane").show("slow");
      } else {
        $("#tab2-2.tab-pane").hide("slow");
      }
      //$("#tab2-2.tab-pane").show("slow");
    },

    helcium_Process(event) {
      let Form_Data = new FormData();
      let card_no = this.formData.card_no;
      let card_holder_name = this.formData.card_holder_name;
      let expiry_month = this.formData.card_expiry_month;
      let expiry_year = this.formData.card_expiry_year;
      let cvv = this.formData.cardCVV;
      $("#helciumerror").hide();

      Form_Data.append("current_order_id", this.current_order_id);
      Form_Data.append("session_id", this.session_id);
      Form_Data.append("cardNumber", card_no);
      Form_Data.append("cardHolder", card_holder_name);
      Form_Data.append("card_expiry_month", expiry_month);
      Form_Data.append("card_expiry_year", expiry_year);
      Form_Data.append("cardCVC", cvv);

      let result = helcimProcess(event);
      result
        .then((res) => {
          console.log(res);
          let formdatanew = $("form#helcimForm").serialize();
          this.Helciumpayment(formdatanew)
            .then((res1) => {
              console.log(res1);
              if (res1.status == 200) {
                toast.success("Congratulations payment done successfully", {
                  autoClose: 2200,
                });

                if (localStorage.getItem("ShippingFromDetails") !== null) {
                  localStorage.removeItem("ShippingFromDetails");
                }

                if (localStorage.getItem("ShippingToDetails") !== null) {
                  localStorage.removeItem("ShippingToDetails");
                }

                if (localStorage.getItem("paymentMethod") !== null) {
                  localStorage.removeItem("paymentMethod");
                }

                if (localStorage.getItem("current_order_id") !== null) {
                  localStorage.removeItem("current_order_id");
                }

                if (localStorage.getItem("Tax") !== null) {
                  localStorage.removeItem("Tax");
                }

                if (localStorage.getItem("coupon") !== null) {
                  localStorage.removeItem("coupon");
                }

                if (localStorage.getItem("discount") !== null) {
                  localStorage.removeItem("discount");
                }

                this.clearCart([]); //clear cart

                setTimeout(() => {
                  this.$router.push("/account");
                }, 3050);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error.data);
                $("#helciumerror").show("slow");
                $("#helciumerror .alert.alert-danger").html(
                  "<span>Either Card No or Expiry Date or CVV is Invalid</span>"
                );

                setTimeout(() => {
                  $("#helciumerror").hide("slow");
                  $("#helciumerror .alert.alert-danger").html(" ");
                }, 5500);
              }
            });
        })
        .catch((error) => {
          console.log(error);
        });
      //testPayment(event);
    },
    setupBilling() {
      if ($("#collapseThree").is(":visible") == false) {
        $("#collapseThree").show("slow");
      } else {
        $("#collapseThree").hide("slow");
      }
    },
  },
  mounted() {
    console.log(this.getTotalAmount);
    $("#current_order_id").val(this.current_order_id);
    //console.log(this.getShippingToDetails);
    //console.log(this.shippingMethod.ship_value);
  },
};
</script>

<style scoped>
.form-bottom {
  margin-top: 4px;
  background: #e6d8cb1c !important;
  box-shadow: 1px 1px 1px 1px #8080801f I !important;
}

table tbody tr td h4 {
  font-size: 1.5em !important;
}

.total-payble {
  background: #f7f7f7;
  padding: 0;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 0px;
  margin-bottom: 50px;
  margin-top: 20px;
  position: relative;
}

.total-payble h5 {
  color: #5ac0dd;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1px;
}

button {
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}

button:hover {
  border-radius: 0px 15px 0px 15px !important;
}

.nav-justified {
  width: 100%;
  border: solid 1px #e7e9ea;
  border-radius: 3px;
  position: relative;
  box-shadow: 2px 2px 2px #dae0e46b;
}
.payment-options .nav-pills > li > a {
  background: #555555;
  border: 1px solid #555;
  color: #fff;
}

.nav-pills > li > a {
  cursor: pointer;
  position: absolute;
  width: 684px;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.nav-justified > li > a {
  text-align: center;
}
.tab-content.tab-content.tab-content-inverse {
  margin-top: 50px;
}
.panel.panel-default.credit-card-box {
  padding: 14px;
}

#buttonProcess {
  display: inline-block;
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
  padding: 6px 12px !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.42857143 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -ms-touch-action: manipulation !important;
  touch-action: manipulation !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  background-image: none !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
}

#buttonProcess:hover {
  border-radius: 0px 15px 0px 15px !important;
}
</style>
