<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li class="first">
                        <a href="/"><i class="fa fa-home fa-lg"></i></a><span class="ms-1 me-1"> > </span>
                    </li>
                    <li class="last">My Account <span class="ms-1"> > </span></li>
                    <li class="last"><span class="ms-1">Upload Artwork</span></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container mb-4">
        <div class="row">
            <h2 class="mb-4">
                 Upload Artwork for Order #{{ order_details.items[0]?.order_id }}
            </h2>

            <div class="col-md-12">
                <form @submit.prevent="submitArtwork()" action="" accept-charset="UTF-8" enctype="multipart/form-data">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="first_name">UPLOAD FILE</label>
                                            <div class="input-group">
                                                <div class="custom-file form-group">
                                                    <input ref="artimage" type="file" name="File[image][]"
                                                        class="custom-file-input" id="image" multiple />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="OrderOrderItem">Order Item</label>

                                            <div v-if="order_details.items !== undefined">
                                                <div v-for="item in order_details.items" :key="item"></div>
                                                <!-- <select name="order_item" id="OrderOrderItem" ref="OrderOrderItem">
                                                    <option v-for="items in order_details.items" :key="items"
                                                        :value="items.order_id"
                                                        :data-ItemId="items.line_items.order_line_id">
                                                        {{ items.order_id + ":" + items.line_items.order_line_id }}
                                                    </option>
                                                </select> -->
                                                <select name="order_id" id="OrderOrderItem" ref="OrderOrderItem">
                                                    <option v-for="items in order_details.items" :key="items"
                                                        :value="items.order_id"
                                                        :data-ItemId="items.line_items.order_line_id">
                                                        {{ items.order_id + ":" + items.line_items.order_line_id }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="text-right">
                                            <input type="hidden" name="id" value="id" />
                                            <button type="submit" id="uploadartwork_submit_form" name="uploadartwork_submit"
                                                value="uploadartwork_submit" class="btn btn-primary btn-lg">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { mapActions } from "vuex";
import { onMounted } from "vue";
import {
    GET_ORDER_DETAILS_ACTION,
    // GET_UPLOADED_ARTWORK_ACTION,
    GET_UPLOAD_MULTIPLE_FILE_ACTION,
} from "@/store/storeconstants";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: "UploadArtwork",
    data() {
        return {
            order_details: {
                items: [],
            },
        };
    },
    setup() {
        const route = useRoute();
        const order_id = route.params.id;
        const store = useStore();

        let gettoken = store.state.auth.CurrentUserLogin.CurrentUserLogin;

        return {
            order_id,
            gettoken,
        };
    },
    mounted() { },
    methods: {
        ...mapActions("userInfo", {
            orderDetails: GET_ORDER_DETAILS_ACTION,
        }),

        // ========================================================
        // ======== Single File Upload functionality Start ========
        // ...mapActions("userInfo", {
        //     uploadArtwork: GET_UPLOADED_ARTWORK_ACTION,
        // }),
        // async submitArtwork() {
        //     let formData = new FormData();

        //     let orderitems = this.$refs.OrderOrderItem;
        //     let artimage = this.$refs.artimage;

        //     let orderitemname = orderitems.getAttribute("name");
        //     let getartimagename = artimage.getAttribute("name");
        //     let OrderItemId = orderitems.options[orderitems.selectedIndex].getAttribute(
        //         "data-itemid"
        //     );

        //     let orderitemvalue = orderitems.options[orderitems.selectedIndex].value;
        //     let getartimagevalue = artimage.files;

        //     if (!getartimagevalue[0]) {
        //         toast.error("Please Select File", {
        //             autoClose: 2500,
        //         });
        //         return false;
        //     }

        //     formData.append(orderitemname, orderitemvalue);
        //     formData.append("order_item_id", OrderItemId);
        //     formData.append(getartimagename, getartimagevalue[0]);

        //     let result = await this.uploadArtwork(formData);
        //     //   console.log(result);
        //     if (result.status == 201) {
        //         toast.success(result.data[0].success, {
        //             autoClose: 2500,
        //         });
        //         this.$router.push("/account");
        //         setTimeout(() => {
        //             window.location.reload();
        //         }, 2000);
        //     } else if (result.status == 200) {
        //         toast.error(result.data[0].success, {
        //             autoClose: 2500,
        //         });
        //     }
        // },
        // ======== Single File Upload functionality End ==========
        // ========================================================


        // ========================================================
        // ======= Upload Multiple File functionality Start =======
        ...mapActions("cartData", {
            updatedCartFiles: GET_UPLOAD_MULTIPLE_FILE_ACTION,
        }),

        async submitArtwork() {
            let formData = new FormData();

            let orderitems = this.$refs.OrderOrderItem;
            let artimage = this.$refs.artimage;

            let orderitemname = orderitems.getAttribute("name");
            let getartimagename = artimage.getAttribute("name");
            console.log(getartimagename, 'getartimagename');
            let OrderItemId = orderitems.options[orderitems.selectedIndex].getAttribute("data-itemid");

            let orderitemvalue = orderitems.options[orderitems.selectedIndex].value;
            let getartimagevalue = artimage.files;


            if (!getartimagevalue[0]) {
                toast.error("Please Select File", {
                    autoClose: 2500,
                });
                return false;
            }

            formData.append("data["+orderitemname+"]", orderitemvalue);
            // formData.append("order_item_id", OrderItemId);
            formData.append("data[item_id]", OrderItemId);
            formData.append("data[uploadFrom]", 'upload_artwork_for_order');

            $.each(getartimagevalue, function (i, e) {
                formData.append("data[File][image][" + i + "]", e);
            });
            
            // formData.append(getartimagename, getartimagevalue[0]);

            let result = await this.updatedCartFiles(formData);
             console.log(result, "result")
            if (result.status == 200) {
                toast.success(result.data.message, {
                    autoClose: 2500,
                });
                this.$router.push("/account");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } 
            // else if (result.status == 200) {
            //     toast.error(result.data[0].success, {
            //         autoClose: 2500,
            //     });
            // }
        },


        // ======= Upload Multiple File functionality Start =======
        // ========================================================
         


    },

    created() {
        let usertoken = this.gettoken.token;
        let orders = {
            order_id: this.order_id,
            token: usertoken,
        };
        let result = this.orderDetails(orders);
        onMounted(() => {
            result
                .then((res) => {
                    if (res) {
                        let alldata = res.data.data;
                        let orderid = alldata.id;
                        let linedata = alldata.order_data.items;
                        linedata.forEach((e) => {
                            let o_data = {
                                order_id: orderid,
                                line_items: e,
                            };
                            this.order_details.items.push(o_data);
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    },
};
</script>

<style scoped>
.container {
    background: #edf1f3 !important;
    padding: 30px;
}

.btn-primary {
    color: #fff !important;
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
    box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}

h2,
.h2 {
    font-weight: 100;

    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 2rem;
    line-height: 1.42857143;
    color: #000;
}
</style>
