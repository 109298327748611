// Login
export const LOGIN_ACTION = "[actions] login user";
export const SET_USER_LOGIN_DATA_MUTATION = "[mutations] set login shipping user data";

//Login Chooser

export const LOGIN_CHOOSER_ACTION = "[actions] login chooser";
export const LOGIN_CHOOSER_MUTATION = "[mutations] login chooser";
export const LOGIN_CHOOSER_GETTER = "[GETTER] login chooser";

export const LOGIN_TLOGIN_ACTION = "[actions] tlogin chooser";
export const LOGIN_TLOGIN_MUTATION = "[mutations] tlogin chooser";
export const LOGIN_TLOGIN_GETTER = "[GETTER] tlogin chooser";




// Signup
export const SIGNUP_ACTION = "[actions] signup user";
export const SET_USER_TOKEN_DATA_MUTATION = "[mutations] set user token data";
export const GET_USER_TOKEN_GETTER = "[getters] auth user token";

//sign up ste login token
export const FIRST_REGISTERED_ACTION  = "[actions] first registered user";
export const FIRST_REGISTERED_MUTATION  = "[actions] first registered user";
export const FIRST_REGISTERED_GETTER  = "[actions] first registered getter";
//sign up set login token

// Products
export const GET_PRODUCT_ACTION = "[actions] get product data";
export const SET_PRODUCT_MUTATION = "[mutations] set product data";
export const GET_PRODUCT_DATA_GETTER =
  "[getters] get product data from getters";

// Get Single Product
export const GET_SINGLE_PRODUCT_ACTION = "[actions] get single product data";
export const SET_SINGLE_PRODUCT_MUTATION =
  "[mutations] set single product data";
export const GET_SINGLE_PRODUCT_DATA_GETTER =
  "[getters] get single product data from getters";

// Home Page
export const GET_HOME_PAGE_DATA_ACTION = "[actions] get home page data";
export const SET_HOME_PAGE_DATA_MUTATION = "[mutations] set home page data";
export const GET_HOME_PAGE_DATA_GETTER =
  "[getters] get home page data from getters";

// Get Attribute
export const GET_ATTRIBUTES_DATA_ACTION = "[actions] get attributes data";
export const SET_ATTRIBUTES_DATA_MUTATION = "[mutations] set attributes data";
export const GET_ATTRIBUTES_DATA_GETTER =
  "[getters] get attributes data from getters";

// Cart Page
export const GET_CART_DATA_ACTION = "[actions] get cart data";
export const SET_CART_DATA_MUTATION = "[mutations] set cart data";
export const GET_CART_DATA_GETTER = "[getters] get cart data from getters";

export const GET_UPDATE_CART_DATA_ACTION = "[actions] update cart data";
export const SET_UPDATE_CART_DATA_MUTATION = "[mutations] update cart data";
export const GET_UPDATE_CART_DATA_GETTER = "[getters] get updated cart data from getters";

// Multiple File Upload From Upload Artwork for Order
export const GET_UPLOAD_MULTIPLE_FILE_ACTION = "[actions] upload multiple files";
// export const SET_UPLOAD_MULTIPLE_FILE_MUTATION = "[mutations] upload multiple files";
// export const GET_UPLOAD_MULTIPLE_FILE_GETTER = "[getters] get upload multiple files from getters";


export const GET_UPDATE_CART_SHIPFROM_DATA_ACTION = "[actions]  shipfrom cart data";
export const SET_UPDATE_CART_SHIPFROM_DATA_MUTATION = "[mutations] shipfrom cart data";
export const GET_UPDATE_CART_SHIPFROM_DATA_GETTER = "[getters] get shipfrom cart data from getters";



export const GET_CART_QUANTITY_ACTION = "[actions] get cart quantity";
export const SET_CART_QUANTITY_MUTATION = "[mutations] set cart quantity";
export const GET_CART_QUANTITY_GETTER = "[getters] get quantity from getters";

export const DELETE_SINGLE_ITEM_CART_ACTION =
  "[actions] delete single item from cart";
export const SET_DELETE_SINGLE_ITEM_CART_MUTATION =
  "[mutations] set delete single item";
export const GET_DELETE_SINGLE_ITEM_CART_GETTER = "[getters] get deleted item";

export const DELETE_ALL_ITEMS_CART_ACTION =
  "[actions] delete all item from cart";

export const SET_DELETE_ALL_ITEMS_CART_MUTATION =
  "[mutations] delete all items from cart";

export const GET_DELETE_ALL_ITEMS_CART_GETTER =
  "[getters] get all deleted item";


  //Location Api
  export const GET_COUNTRY_ACTION = "[actions] get country action";
  export const SET_COUNTRY_MUTATION = "[mutations] set country mutation";
  export const GET_COUNTRY_GETTER = "[getters] get country from getters";
  
  export const GET_STATE_ACTION = "[actions] get state action";
  export const SET_STATE_MUTATION = "[mutations] set state mutation";
  export const GET_STATE_GETTER = "[getters] get state from getters";


  export const GET_CITY_ACTION = "[actions] get city action";
  export const SET_CITY_MUTATION = "[mutations] set city mutation";
  export const GET_CITY_GETTER = "[getters] get city from getters";

  //Location Api

  //User Data

  export const GET_USER_DATA_ACTION = "[actions] get user data";
  export const SET_USER_DATA_MUTATION = "[mutations] set user data";
  export const GET_USER_DATA_GETTER = "[getters] get user data from getters";  

  //user login  flag

  
  export const SET_USER_LOGGED_IN_ACTION = "[actions] get user logged in flag";
  export const SET_USER_LOGGED_IN_MUTATION = "[mutations] set user logged in flag";
  export const GET_USER_LOGGED_IN_GETTER = "[getters] get user logged in getter";  

  //User Store Data

  export const GET_USER_STORE_DATA_ACTION = "[actions] get user store data";
  export const SET_USER_STORE_DATA_MUTATION = "[mutations] set user store data";
  export const GET_USER_STORE_DATA_GETTER = "[getters] get user store data from getters"; 

  //User printing orders Data
  export const GET_USER_PRINTING_ORDER_ACTION= "[actions] get user printing orders";
  export const SET_USER_PRINTING_ORDER_ACTION_MUTATION = "[mutations] set user printing orders";
  export const GET_USER_PRINTING_ORDER_GETTER = "[getters] get user printing orders from getters"; 


  //User commercial orders Data
  export const GET_USER_COMMERCIAL_ORDER_ACTION= "[actions] get user commercial orders";
  export const SET_USER_COMMERCIAL_ORDER_ACTION_MUTATION = "[mutations] set user commercial orders";
  export const GET_USER_COMMERCIAL_ORDER_GETTER = "[getters] get user commercial orders from getters"; 



  //User Shipping Data
  export const GET_USER_SHIPPING_ACTION = "[actions] get user shipping";
  export const SET_USER_SHIPPING_MUTATION = "[mutations] set user shipping";
  export const GET_USER_SHIPPING_GETTER = "[getters] get user shipping from getters"; 

   //User Payment Data
   export const GET_PAYMENT_DATA_ACTION = "[actions] get payment data";
   export const SET_PAYMENT_DATA_MUTATION = "[mutations] set payment data";
   export const GET_PAYMENT_DATA_GETTER = "[getters] get payment data from getters"; 


  //Artwork Data
  export const GET_ARTWORK_DATA_ACTION = "[actions] get artwork data";
  export const SET_ARTWORK_DATA_MUTATION = "[mutations] set artwork data";
  export const GET_ARTWORK_DATA_GETTER = "[getters] get artwork data from getters"; 


  //Artwork Upload Data
  export const GET_ARTWORK_UPLOAD_ACTION = "[actions] get artwork upload";
  export const SET_ARTWORK_UPLOAD_MUTATION = "[mutations] set artwork upload";
  export const GET_ARTWORK_UPLOAD_GETTER = "[getters] get artwork upload from getters";


  //Artwork Framework Get Data
  export const GET_FRAMEWORK_ACTION = "[actions] get framework action";
  export const SET_FRAMEWORK_MUTATION = "[mutations] set framework mutation";
  export const GET_FRAMEWORK_GETTER = "[getters] set framework from getters";


   //Artwork Framework Save Data
   export const SAVE_ARTWORK_ACTION = "[actions] save artwork action";
   export const SAVE_ARTWORK_MUTATION = "[mutations] save artwork mutation";
   export const SAVE_ARTWORK_GETTER = "[getters] save artwork from getters";


  //Review Payment
  export const GET_CART_REVIEW_ACTION = "[actions] get cart review action";
  export const SET_CART_REVIEW_MUTATION = "[mutations] set cart review mutation";
  export const GET_CART_REVIEW_GETTER = "[getters] get cart review from getters";
  
   //Payment processes

   //Helcium
   export const HELCIUM_PAYMENT_ACTION = "[actions] get helcium payment data";
   export const HELCIUM_PAYMENT_MUTATION = "[mutations] set helcium payment data";
   export const HELCIUM_PAYMENT_GETTER = "[getters] get helcium payment data from getters"; 
  //Helcium

   //Check-Cash
   export const CHECK_PAYMENT_ACTION = "[actions] get check payment data";
   export const CHECK_PAYMENT_MUTATION = "[mutations] set check payment data";
   export const CHECK_PAYMENT_GETTER = "[getters] get check payment data from getters"; 
   //Check-Cash

   //Paypal
   export const PAYPAL_PAYMENT_ACTION = "[actions] get paypal payment data";
   export const PAYPAL_PAYMENT_MUTATION = "[mutations] set paypal payment data";
   export const PAYPAL_PAYMENT_GETTER = "[getters] get paypal data from getters"; 
   //Paypal



  


 /****GET ORDER DETAILS AND UPLOAD ARTWORK ******/

 export const GET_ORDER_DETAILS_ACTION= "[actions] get order details";
 export const SET_ORDER_DETAILS_MUTATION = "[mutations] set order details mutation";
 export const GET_ORDER_DETAILS_GETTER = "[getters] get order details getters"; 

  /****GET ORDER DETAILS AND UPLOAD ARTWORK ******/


  /***UPLOAD ARTWORK FROM ARTWORK DETAIL PAGE ******/
  
  export const GET_UPLOADED_ARTWORK_ACTION= "[actions] get uploaded artwork details";
  export const SET_UPLOADED_ARTWORK_MUTATION = "[mutations] set uploaded artwork mutation";
  export const GET_UPLOADED_ARTWORK_GETTER = "[getters] get uploaded artwork getters"; 
  
  /***UPLOAD ARTWORK FROM ARTWORK DETAIL PAGE ******/


  
  /***DELETE ARTWORK FILE ******/
  
  export const DELETE_UPLOADED_ARTWORK_ACTION= "[actions] delete uploaded artwork details";
  export const DELETE_UPLOADED_ARTWORK_MUTATION = "[mutations] delete uploaded artwork mutation";
  export const DELETE_UPLOADED_ARTWORK_GETTER = "[getters] delete uploaded artwork getters"; 

  export const DELETE_UPLOADED_CART_ARTWORK_ACTION= "[actions] delete uploaded artwork details from cart";
  export const DELETE_UPLOADED_CART_ARTWORK_MUTATION = "[mutations] delete uploaded artwork mutation from cart";
  export const DELETE_UPLOADED_CART_ARTWORK_GETTER = "[getters] delete uploaded artwork getters from cart"; 


  //Sigle item remove //

  export const DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION= "[actions]  get sigle cart details from cart";
  export const DELETE_SINGLE_UPLOADED_CART_ARTWORK_MUTATION = "[mutations]  set  sigle cart mutation from cart";
  export const DELETE_SINGLE_UPLOADED_CART_ARTWORK_GETTER = "[getters]  get sigle cart getters from cart";
  
  /***DELETE ARTWORK FILE ******/

  /*****SHIPPING ADDRESS LIST  OF USER *****/
  export const GET_USER_SHIPPING_ADDRESS_ACTION= "[actions] get user shipping address";
  export const SET_USER_SHIPPING_ADDRESS_MUTATION = "[mutations] set user shipping address";
  export const GET_USER_SHIPPING_ADDRESS_GETTER = "[getters] get user shipping address using getters"; 
   
  /*****SHIPPING ADDRESS LIST  OF USER *****/

  export const UPDATE_USER_SHIPPING_ADDRESS_ACTION= "[actions] update user shipping address";
  export const UPDATE_USER_SHIPPING_ADDRESS_MUTATION = "[mutations] update user shipping address mutation";
  export const UPDATE_USER_SHIPPING_ADDRESS_GETTER = "[getters] update user shipping address using getters"; 
     
  /*****UPDATE SHIPPING ADDRESS LIST  OF USER *****/


  export const GETUPDATED_USER_SHIPPING_ADDRESS_ACTION = "[actions]  get updated user shipping address";
  export const GETUPDATED_USER_SHIPPING_ADDRESS_MUTATION = "[mutations] get updated user shipping address mutation";
  export const GETUPDATED_USER_SHIPPING_ADDRESS_GETTER = "[getters] get updated user shipping address using getters";
     
  /*****UPDATE SHIPPING ADDRESS LIST  OF USER *****/






  /******CLEAR ALL DATA AFTER LOGOUT ****************/
 /****CLEAR USER LOGIN DETAILS */
   export const CLEAR_UP_LOGIN_ACTION= "[actions] clear login action";
   export const CLEAR_UP_LOGIN_MUTATION = "[mutations] set login info";
   export const CLEAR_UP_LOGIN_GETTER = "[getters] get login getters"; 

   /***CLEAR USER LOGIN DETAILS */


    /****CLEAR USER STORE DETAILS */
    export const CLEAR_USER_STORE_ACTION= "[actions] clear user store action";
    export const CLEAR_USER_STORE_MUTATION = "[mutations] user store info";
    export const CLEAR_USER_STORE_GETTER = "[getters] get user store getters"; 
 
    /***CLEAR USER LOGIN DETAILS */


     /****CLEAR USER LOGGED IN FLAG DETAILS */
    export const CLEAR_UP_LOGIN_FLAG_ACTION= "[actions] clear login flag action";
    export const CLEAR_UP_LOGIN_FLAG_MUTATION = "[mutations] set login flag info";
    export const CLEAR_UP_LOGIN_FLAG_GETTER = "[getters] get login flag getters"; 

   /****CLEAR USER LOGGED IN FLAG DETAILS */


    /****CLEAR CART DETAILS *****/
    export const CLEAR_CART_DATA_ACTION= "[actions] clear cart data";
    export const CLEAR_CART_MUTATION = "[mutations] clear cart mutation";
    export const CLEAR_CART_GETTER = "[getters] clear cart getters"; 
  
 
     /****CLEAR CART DETAILS *****/




      /****CLEAR LOCATION DATA *****/
      export const CLEAR_LOCATION_DATA_ACTION= "[actions] clear location data";
      export const CLEAR_LOCATION_MUTATION = "[mutations] clear location mutation";
      export const CLEAR_LOCATIOn_GETTER = "[getters] clear location getters"; 
      /****CLEAR LOCATION DATA *****/



      /*****CLEAR USER INFO DATA *****/
      export const CLEAR_USERINFO_ACTION= "[actions] clear userinfo data";
      export const CLEAR_USERINFO_MUTATION = "[mutations] clear userinfo mutation";
      export const CLEAR_USERINFO_GETTER = "[getters] clear userinfo getters"; 

      /*****CLEAR USER INFO DATA *****/


    /******CLEAR ALL DATA AFTER LOGOUT ****************/


  


  
 