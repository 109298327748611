/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import store from "@/store/store"
// eslint-disable-next-line no-unused-vars
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import router from ".";
import axios from "axios";
import {
  CLEAR_UP_LOGIN_ACTION,
  CLEAR_UP_LOGIN_FLAG_ACTION,
  CLEAR_USER_STORE_ACTION,
  CLEAR_CART_DATA_ACTION,
} from "@/store/storeconstants";
// eslint-disable-next-line no-unused-vars
export function authMiddleware(to,from,next)
{
      console.log(`Navigating from------------ ${from.path} to ${to.path}`);
 
next();
    
} 

export function isAuthenticated(to, from, next) {

   let getnewusertoken=store.state.auth.CurrentUserLogin
   let getusertoken=store.state.auth.CurrentUserLogin.CurrentUserLogin
   let usertoken=null;



    if(getusertoken?.token!=undefined )
    {
      usertoken=getusertoken?.token;
    }
    else
    {
      usertoken=getnewusertoken?.token;
    }

  

       let response1 = axios.get(`${process.env.VUE_APP_BASE_URL}user-data`,{
        headers: {
            'Authorization': `Bearer ${usertoken}`
        }
    });
          response1
            .then((res) => {
              if (res.status == 200 && usertoken!=="") {               
                 next();
              }
            })
            .catch((error) => {
              console.log('error...????',error);
                        toast.error("User is not authenticated!", {
                        autoClose: 2500,
                        });

                        let getvueitems=JSON.parse(localStorage.getItem('vuex'))
                        getvueitems.auth.CurrentUserLogin={}
                        getvueitems.auth.UserStore={}
                        getvueitems.auth.userLoggedin =false

                        getvueitems.cartData.cart=[]
                        getvueitems.userInfo.CommercialOrders=[]
                        getvueitems.userInfo.CommercialOrders=[]
                       // getvueitems.userInfo.DefaultShipping=[]
                        getvueitems.userInfo.PrintingOrder =[]
                        localStorage.setItem("vuex",JSON.stringify(getvueitems)); 
                       /***clear all localstorage ***/
                         localStorage.removeItem('auth');
                       ////  localStorage.clear();
                       /***clear all localstorage */


               next('/login');
            });
      /* Check if the user is authenticated */
  
}