<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-12 mb-xs-5">
          <ul class="p-0 mb-0 list-style-none">
            <li v-for="menus in renderMenu" v-bind:key="menus" class="mb-2">
              <a
                v-if="menus.domain_id == 2"
                :href="webUrl + menus.slug"
                class="text-dark text-decoration-none font-weight-400 font-family-Roboto font-18"
              >
                {{ menus.title }}
              </a>
            </li>
          </ul>
        </div>

        <div class="col-md-4 col-sm-12 mb-xs-5 align-self-center">
          <div class="footer_brand mb-sm-4 mt-sm-4">
            <router-link to="/">
              <img
                class="img-fluid"
                v-bind:src="
                  $baseurlForImages +
                  this.$store.state?.homeData?.home?.footer?.footer_logo_url
                "
                alt="footer logo"
              />
            </router-link>
          </div>
        </div>

        <div class="col-md-4 col-sm-12 mb-xs-5">
          <div
            class="align-items-md-end d-flex flex-md-column flex-direction-column"
          >
            <div>
              <ul class="list-style-none mb-0 p-0">
                <li class="mb-1 text-start">
                  <a
                    :href="$baseurlWeb + 'pages/privacy-policy'"
                    class="text-dark text-decoration-none font-weight-400 font-family-Roboto font-18"
                    >Privacy policy</a
                  >
                </li>
                <li class="mb-1 text-start">
                  <a
                    :href="$baseurlWeb + 'pages/cookies-policy'"
                    class="text-dark text-decoration-none font-weight-400 font-family-Roboto font-18"
                    >Cookies Policy</a
                  >
                </li>
                <li class="mb-4 text-start">
                  <a
                    :href="$baseurlWeb + 'pages/terms-of-service'"
                    class="text-dark text-decoration-none font-weight-400 font-family-Roboto font-18"
                    >Terms &amp; Services</a
                  >
                </li>

                <li class="mb-4 text-start">
                  <a
                    :href="`mailto:${this.$store.state?.homeData?.home?.footer?.newsletter_email}`"
                    class="text-dark text-decoration-none font-weight-400 font-family-Roboto font-18"
                    >{{
                      this.$store.state.homeData?.home?.footer?.newsletter_email
                    }}</a
                  >
                </li>

                <li>
                  <ul
                    class="d-flex justify-content-sm-between list-style-none mb-0 p-0"
                  >
                    <li class="d-inline">
                      <a
                        target="_blank"
                        :href="this.$store.state?.homeData?.home?.footer?.footer_facebook"
                        class="text-dark text-decoration-none font-weight-400"
                      >
                        <img src="../../assets/img/fb.png" alt="" />
                      </a>
                    </li>
                    <li class="d-inline ms-xs-4">
                      <a
                        target="_blank"
                        :href="this.$store.state?.homeData?.home?.footer?.footer_twitter"
                        class="text-dark text-decoration-none font-weight-400">
                        <img src="../../assets/img/twitter.png" alt="" />
                      </a>
                    </li>
                    <li class="d-inline ms-xs-4">
                      <a
                        target="_blank"
                        :href="this.$store.state?.homeData?.home?.footer?.footer_linkedin"
                        class="text-dark text-decoration-none font-weight-400">
                        <img src="../../assets/img/linkedin.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-xs-5 mt-5 text-center">
          <p class="font-family-Roboto font-weight-400 font-14">
            {{ this.$store.state?.homeData?.home?.footer?.copyright }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions } from "vuex";
import {
  GET_PRODUCT_ACTION,
  GET_SINGLE_PRODUCT_ACTION,
  GET_HOME_PAGE_DATA_ACTION,
} from "@/store/storeconstants";
export default {
    name: "FooterItem",
    data() {
        return {
        menus: [],
        webUrl: process.env.VUE_APP_BASE_URL_WEB,
        footer: {
            facebook: null,
            twitter: null,
            linkedin: null,
        },
        };
    },

    computed: {
        renderMenu() {
        let menu = [];
        this.menus.map((e) => {
            menu.push({
            title: e.title,
            menu_id: e.id,
            slug: e.slug,
            domain_id: e.domain_id,
            havingChildmenus: e.hasMenu,
            childmenus: e.sub_menu,
            parentId: e.parent_id,
            });
        });

        return menu;
        },
    },

    methods: {
        ...mapActions("homeData", {
            homePageData: GET_HOME_PAGE_DATA_ACTION,
        }),
        ...mapActions("product", {
            getAllProduct: GET_PRODUCT_ACTION,
            getSingleProduct: GET_SINGLE_PRODUCT_ACTION,
        }),

        getMenues() {
            fetch(process.env.VUE_APP_BASE_URL + "getMenuForFooter?position=bottom&domain_id=2").then((response) => response.json())
            .then((json) => {
                this.menus = json.data;
            });
        },
        
        getDefaultShippingFrom() {
            let result = this.$axios.get(`${process.env.VUE_APP_BASE_URL}getDefaultShipFrom`);
            result.then((res) => {
                if (res.status == 200) {
                    console.log("res....", res.data.data);
                    let shippinAddress = res?.data?.data;
                    console.log("shippinAddress-------??", shippinAddress);
                    if (localStorage.getItem("ShippingFromDetails") == null) {
                        //set Default ship from details***/
                        let userShipping = { 
                            shipping_from_id: shippinAddress?.id.toString(),
                            shipping_from_full_name: shippinAddress?.first_name + " " + shippinAddress?.last_name,
                            shipping_from_day_phone: shippinAddress?.day_phone != "" ? shippinAddress?.day_phone : "",
                            shipping_from_night_phone: shippinAddress?.night_phone != "" ? shippinAddress?.night_phone: "",
                            shipping_from_address: shippinAddress?.address1,
                            shipping_from_address_1: shippinAddress?.address2,
                            shipping_from_state: shippinAddress?.state_id.toString(),
                            shipping_from_city: shippinAddress?.city,
                            shipping_from_country: shippinAddress?.country,
                            shipping_from_zip: shippinAddress?.zip,
                            shipping_from_email: shippinAddress?.email,
                        };
                        localStorage.setItem("ShippingFromDetails", JSON.stringify(userShipping));
                    }
                }
            }).catch((error) => {
            console.log(error);
            });
        }, 
    },

    created() {
        this.homePageData()
        this.getMenues();
        this.getDefaultShippingFrom();
    },
};
</script>
