import { GET_PRODUCT_DATA_GETTER,GET_ATTRIBUTES_DATA_GETTER, GET_SINGLE_PRODUCT_DATA_GETTER,GET_ARTWORK_DATA_GETTER,GET_ARTWORK_UPLOAD_GETTER,GET_FRAMEWORK_GETTER,SAVE_ARTWORK_GETTER,DELETE_UPLOADED_ARTWORK_GETTER } from "@/store/storeconstants";

export default {
    [GET_PRODUCT_DATA_GETTER]: (state) => {
        return state.AllProducts
    },

    [GET_SINGLE_PRODUCT_DATA_GETTER]: (state) => {
        return state.SingleProduct
    },

    [GET_ATTRIBUTES_DATA_GETTER ]: (state)=>{
        return state.Attributes
    },

    [GET_ARTWORK_DATA_GETTER]: (state) => {
        return state.ArtWork
    },

    [GET_ARTWORK_UPLOAD_GETTER]: (state) => {
        return state.ArtworkUpload
    },

    [GET_FRAMEWORK_GETTER]: (state) => {
        return state.frameWork
    },

    [ SAVE_ARTWORK_GETTER]: (state) => {
        return state.saveArtwork
    },

    [DELETE_UPLOADED_ARTWORK_GETTER]:(state)=>{
        return state.ArtworkUpload
    
    }
 
 
  
    
};