<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb align-items-center mb-0">
            <li class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Home</a
              >
            </li>
            <li class="breadcrumb-item">
              <a
                href="#/pages/printing"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Printing</a
              >
            </li>
            <!-- <li class="breadcrumb-item">
              <a
                href="#/pages/printing"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >{{ getProducts[0] }}</a
              >
            </li> -->
            <li
              class="breadcrumb-item font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
            >
              payment
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <!-- Breadcrumb En d -->

  <!-- Process Steps Name Start -->
  <div class="container mt-2">
    <div class="row">
      <div class="col-md-12">
        <ul id="progress">
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            my shopping cart
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Shipping Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Payment Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize"
          >
            Review Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize"
          >
            Checkout
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Process Steps Name End -->

  <!-- Payment Details Section Start -->
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12 col-lg-7">
        <div class="row">
          <div class="col-md-12">
            <h1 class="font-30 font-weight-700 mb-5">Shipping Details</h1>
            <h2 class="font-20 font-weight-700 font-family-Roboto mb-3">
              Select Payment Type
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-10 mb-3">
            <div
              class="custom_radio_button bg-grey rounded-5 d-flex p-2 align-items-center"
            >
              <div class="bg-white rounded-5">
                <img
                  class="img-fluid"
                  src="img/paypal_logo.png"
                  alt="Paypal logo"
                />
              </div>
              <div class="ms-3">
                <span class="d-block font-16 font-weight-700 font-family-Roboto"
                  >Paypal</span
                >
                <span
                  class="d-block dim-gray font-14 font-weight-400 font-family-Roboto"
                  >Make payment with paypal</span
                >
              </div>

              <input
                style="cursor: pointer"
                type="radio"
                name="paypal"
                id="paypalRadiosButton"
                value="1"
                data-payment="Paypal"
              />
              <label for="paypalRadiosButton">
                <img
                  v-if="selectedMethod.payment_id == 1"
                  src="../assets/img/check_icon.svg"
                />
                <img v-else src="../assets/img/uncheck_icon.svg" />
              </label>
            </div>
          </div>

          <div class="col-md-12 col-lg-10 mb-3">
            <div
              class="custom_radio_button bg-grey rounded-5 d-flex p-2 align-items-center"
            >
              <div class="bg-white rounded-5">
                <img
                  class="img-fluid"
                  src="img/american_express.png"
                  alt="American Express logo"
                />
              </div>
              <div class="ms-3">
                <span class="d-block font-16 font-weight-700 font-family-Roboto"
                  >American Express</span
                >
                <span
                  class="d-block dim-gray font-14 font-weight-400 font-family-Roboto"
                  >Make payment with American Express</span
                >
              </div>
              <input
                style="cursor: pointer"
                type="radio"
                name="paypal"
                id="paypalRadiosButton-card"
                value="2"
                data-payment="American Express"
              />
              <label for="paypalRadiosButton-card">
                <img
                  v-if="selectedMethod.payment_id == 2"
                  src="../assets/img/check_icon.svg"
                />
                <img v-else src="../assets/img/uncheck_icon.svg" />
              </label>
            </div>
          </div>

          <div class="col-md-12 col-lg-10 mb-3">
            <div
              class="custom_radio_button bg-grey rounded-5 d-flex p-2 align-items-center"
            >
              <div class="bg-white rounded-5">
                <img
                  class="img-fluid"
                  src="img/mastercard.png"
                  alt="Paypal logo"
                />
              </div>
              <div class="ms-3">
                <span class="d-block font-16 font-weight-700 font-family-Roboto"
                  >Mastercard</span
                >
                <span
                  class="d-block dim-gray font-14 font-weight-400 font-family-Roboto"
                  >Make payment with Mastercard</span
                >
              </div>
              <input
                style="cursor: pointer"
                type="radio"
                name="paypal"
                id="paypalRadiosButton-card-master"
                value="3"
                data-payment="Master Card"
              />
              <label for="paypalRadiosButton-card-master">
                <img
                  v-if="selectedMethod.payment_id == 3"
                  src="../assets/img/check_icon.svg"
                />
                <img v-else src="../assets/img/uncheck_icon.svg" />
              </label>
            </div>
          </div>

          <div class="col-md-12 col-lg-10 mb-3">
            <div
              class="custom_radio_button bg-grey rounded-5 d-flex p-2 align-items-center"
            >
              <div class="bg-white rounded-5">
                <img class="img-fluid" src="img/VISA.png" alt="Paypal logo" />
              </div>
              <div class="ms-3">
                <span class="d-block font-16 font-weight-700 font-family-Roboto"
                  >VISA</span
                >
                <span
                  class="d-block dim-gray font-14 font-weight-400 font-family-Roboto"
                  >Make payment with VISA</span
                >
              </div>
              <input
                style="cursor: pointer"
                type="radio"
                name="paypal"
                id="paypalRadiosButton-card-visa"
                value="4"
                data-payment="Visa"
              />
              <label for="paypalRadiosButton-card-visa">
                <img
                  v-if="selectedMethod.payment_id == 4"
                  src="../assets/img/check_icon.svg"
                />
                <img v-else src="../assets/img/uncheck_icon.svg" />
              </label>
            </div>
          </div>

          <div class="col-md-12 col-lg-10 mb-3">
            <div
              class="custom_radio_button bg-grey rounded-5 d-flex p-2 align-items-center"
            >
              <div class="bg-white rounded-5">
                <img class="img-fluid" src="img/cheque.png" alt="Paypal logo" />
              </div>
              <div class="ms-3">
                <span class="d-block font-16 font-weight-700 font-family-Roboto"
                  >Cheque</span
                >
                <span
                  class="d-block dim-gray font-14 font-weight-400 font-family-Roboto"
                  >Funds must clear Hollywood Studios bank before production
                  begins and day 1 starts</span
                >
              </div>
              <input
                style="cursor: pointer"
                type="radio"
                name="paypal"
                id="paypalRadiosButton-check"
                value="5"
                data-payment="Cheque"
              />
              <label for="paypalRadiosButton-check">
                <img
                  v-if="selectedMethod.payment_id == 5"
                  src="../assets/img/check_icon.svg"
                />
                <img v-else src="../assets/img/uncheck_icon.svg" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-5 mt-md-5 mt-sm-5 mt-xs-5 mt-lg-0">
        <div class="card rounded-10 shadow-4 border-0">
          <div class="card-body pt-4 pb-4 pe-4 ps-4">
            <h3 class="font-20 font-weight-700 mt-2">Your Order</h3>
            <hr class="custom_second" />
            <div v-for="(cart, index) in cartData" v-bind:key="index" class="mt-4">
              <div class="container">
                <div class="row">
                  <div class="col-md-3 col-lg-3 p-0">
                    <div style="width: 80px; height: 80px">
                      <img
                        class="h-100 img-fluid w-100"
                        v-if="cart?.product_data?.product_files[0]?.file_name"
                        :src="
                          baseWebUrl +
                          'files/image/' +
                          cart?.product_data?.product_files[0]?.file_name
                        "
                        :alt="cart?.product_data?.title"
                      />
                      <img
                        v-else
                        width="100"
                        class="img-fluid"
                        src="../assets/img/product_img.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-9 col-lg-9">
                    <div class="row">
                      <div class="col-12">
                        <span
                          class="d-block font-16 font-weight-700 font-family-Roboto mb-2"
                       v-html="cart.product_data.title? cart.product_data.title:''" >
                         
                        </span>
                        <!-- <span
                          class="d-block font-16 font-weight-700 font-family-Roboto mb-2"
                        >
                          {{ cart.product_data.title }}
                        </span> -->

                        <div v-if="cart.cart_data">
                          <div v-for="(it, i) in cart.cart_data" :key="i">
                            <div
                              v-if="
                                it.text != '' &&
                                it.value != '' &&
                                it.id != 'user_filename' &&
                                it.id != 'filenames_arr'
                              "
                            >
                              <b>{{ it.label }} {{ it.label ? ":" : "" }}</b>
                              {{ it.text }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div v-if="getShippingCost !== ' '">
                          <b>Shipping: </b>
                          <b>{{
                            "$" + (getShippingCost !== "undefined")
                              ? getShippingCost
                              : ""
                          }}</b>
                        </div>
                      </div>

                      <div class="col-12">
                        <!----Artwork --->
                        <div
                          class="mt-2"
                          v-if="
                            cart?.file_details !== null &&
                            Object.keys(cart?.file_details).length > 0
                          "
                        >
                          <h6 class="mb-0">Artwork FIles</h6>
                          <div
                            v-for="(file, index) in cart.file_details"
                            v-bind:key="index"
                            class="mt-3 mb-4 d-block font-11 line-height-13 font-family-Roboto font-weight-400 text-gray57 "
                          >
                            <span v-if="index !== 'order_id' && index !== 'id'">
                              <!-- <span
                                v-if="file.name !== ''"
                                class="border-0 pb-2 pe-4 ps-4 pt-2 bg-grey rounded-3 font-12 font-weight-400 font-family-Roboto text-black-50 mt-2"
                                >{{ file.name }}</span
                              > -->
                              <span 
                                  v-if="file.name != ''">
                                    <span v-for="(file, i) in replaceCommaFromBrTag(file.name)" v-bind:key="i"  >
                                      <span class="d-block mb-2 border-0 pb-2 pe-4 jk ps-4 pt-2 bg-grey rounded-3 font-14 font-weight-400 font-family-Roboto text-black-50 mt-2">{{ file }}</span>
                                    </span>
                                </span>
                            </span>
                          </div>

                          <span
                            class="mt-4 d-block font-11 line-height-13 font-family-Roboto font-weight-400 text-gray57"
                          >
                            <a
                              style="
                                font-size: 1.2em;
                                background: #8080801f;
                                padding: 4px;
                                text-decoration: none;
                              "
                              :href="
                                baseWebUrl +
                                'files/order/' +
                                cart.order_id +
                                '/' +
                                cart.order_id +
                                '-' +
                                cart.id +
                                '.zip'
                              "
                              target="_blank"
                              >Download Artwork</a
                            >
                            <span
                              title="Remove Artwork"
                              class="ms-2"
                              style="cursor: pointer"
                              @click.prevent="
                                removeArtwork(
                                  $event,
                                  cart.order_id,
                                  cart.id,
                                  cart.product_data.id
                                )
                              "
                              ><img src="../assets/img/remove-icon.png"
                            /></span>
                          </span>
                          &nbsp;
                          <span class="ms-2" style="font-size: 1.1em">
                            {{ cart.artwork_files.name }}
                          </span>
                        </div>

                        <div v-else>
                          <span
                            class="mt-2 d-block font-11 line-height-13 font-family-Roboto font-weight-400 text-gray57"
                          >
                            Artwork not uploaded
                          </span>
                        </div>
                        <!---Artwork--->
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-6">
                        <span
                          class="d-block font-16 font-weight-700 font-family-Roboto"
                        >
                          $ {{ parseFloat(cart.price).toFixed(2) }} x
                          {{ cart.qty }}
                        </span>
                      </div>

                      <div class="col-6 pe-0 text-en text-end">
                        <span
                          class="d-block font-16 font-weight-700 font-family-Roboto"
                        >
                          ${{ parseFloat(cart.price * cart.qty).toFixed(2) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="custom_second" />
            </div>

            <div class="mt-4" v-if="notes">
              <div class="container">
                  <div class="row align-items-center">
                      <div class="col-2 ps-0"><strong class="font-16 font-weight-400 font-family-Roboto">Notes:</strong></div>
                      <div class="col-10">
                            <p class="mb-0">{{ notes }}</p>
                      </div>
                  </div>
              </div>
              <hr class="custom_second" />
            </div>

            <div class="d-flex justify-content-between mt-4">
              <span class="font-16 font-weight-400 font-family-Roboto"
                >Sub-Total</span
              >
              <span class="font-16 font-weight-700 font-family-Roboto">
                <div v-if="getTotalPrice">${{ getTotalPrice }}</div>
              </span>
            </div>

            <div class="d-flex justify-content-between mt-3">
              <span class="font-18 font-weight-400 font-family-Roboto"
                >Shipping</span
              >
              <span class="font-18 font-weight-700 font-family-Roboto">
                <div v-if="getShippingperItemcost">
                  ${{ getShippingperItemcost }}
                </div>
                <div v-else>$0</div>
              </span>
            </div>

            <div class="d-flex justify-content-between mt-3">
              <span class="font-16 font-weight-400 font-family-Roboto"
                >Tax</span
              >
              <span class="font-16 font-weight-700 font-family-Roboto">
                <div>${{ tax !== "" ? parseFloat(tax).toFixed(2) : 0.0 }}</div>
              </span>
            </div>

            <!----Coupon & Discount--------------->
            <div v-if="this.coupon != '' && this.discount > 0"><hr /></div>

            <div
              v-if="this.coupon != ' '"
              class="d-flex justify-content-between mt-3"
            >
              <span class="font-16 font-weight-400 font-family-Roboto"
                >Coupon</span
              >
              <span class="font-16 font-weight-700 font-family-Roboto">
                <div style="text-transform: uppercase">{{ this.coupon }}</div>
              </span>
            </div>

            <div
              v-if="this.discount && this.discount > 0"
              class="d-flex justify-content-between mt-3"
            >
              <span class="font-16 font-weight-400 font-family-Roboto"
                >Discount</span
              >
              <span class="font-16 font-weight-700 font-family-Roboto">
                <div><b>-</b> ${{ this.discount }}</div>
              </span>
            </div>
            <!----Coupon & Discount--------------->

            <hr class="custom_second" />
            <div class="d-flex justify-content-between mt-4">
              <span class="font-25 font-weight-400 font-family-Roboto"
                >Total</span
              >
              <span class="font-25 font-weight-700 font-family-Roboto"
                >${{ getFullPrice }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-5 mb-5">
        <div class="white_bg_btn">
          <a
            href="#"
            @click.prevent="gotoCheckout()"
            class="btn btn-primary p-0 text-capitalize me-sm-3"
            >Continue</a
          >
        </div>
      </div>
    </div>
  </div>
  {{ console.log(selectedMethod.payment_id, "selectedMethod") }}
</template>

<script>
import {
  DELETE_UPLOADED_CART_ARTWORK_ACTION,
  DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION,
} from "@/store/storeconstants";
import { useStore, mapActions } from "vuex";
// eslint-disable-next-line no-unused-vars
import $ from "jquery";
import { toast } from "vue3-toastify";
export default {
  name: "PaymentView",
  data() {
    return {
      notes: localStorage.getItem('notes') ? localStorage.getItem('notes') : null,
      TotalPrice: 0,
      shipping_rate: 0,
      selectedMethod: {},
      // tax: 0.0,
    };
  },
  setup() {
    const store = useStore();
    let cartData = store.state.cartData.cart;
    const baseWebUrl = process.env.VUE_APP_BASE_URL_WEB;
    let tax =
      localStorage.getItem("tax") !== null ? localStorage.getItem("tax") : 0;
    let coupon =
      localStorage.getItem("coupon") !== null
        ? localStorage.getItem("coupon")
        : "";
    let discount =
      localStorage.getItem("discount") !== null
        ? localStorage.getItem("discount")
        : "";

    return {
      cartData,
      tax,
      baseWebUrl,
      coupon,
      discount,
    };
  },
  computed: {
    getProducts() {
      let products_title = [];
      $.each(this.cartData, function (i, e) {
        products_title.push(e.product_data.title);
      });
      return products_title;
    },
    getTotalPrice() {
      let TotalPrice = this.cartData.reduce((accumulator, currentvalue) => {
        return currentvalue.total_price !== undefined
          ? (
              parseFloat(accumulator) + parseFloat(currentvalue.total_price)
            ).toFixed(2)
          : 0;
      }, 0);

      return TotalPrice;
    },
    getShippingCost() {
      let getShip =
        localStorage.getItem("shipping_methods") !== null
          ? JSON.parse(localStorage.getItem("shipping_methods"))
          : "";
      let costship = getShip.ship_value.split("$");
      let getprice = costship[1].split(")");
      let finalshipprice = parseFloat(getprice[0]).toFixed(2);

      return finalshipprice;
    },
    getTax() {
      let tax =
        localStorage.getItem("tax") != null ? localStorage.getItem("tax") : 0;
      return tax;
    },
    getDiscount() {
      let getdiscount = this.discount && this.discount > 0 ? this.discount : 0;
      return getdiscount;
    },

    getShippingperItemcost() {
      let shipping_price = this.getShippingCost;
      // this.shipping_rate = shipping_price;
      let carts = this.cartData;
      let totalshipcost = 0;
      let finalshipprice = 0;
      carts.map((e) => {
        totalshipcost =
          totalshipcost + parseFloat(shipping_price) * parseFloat(e.qty);
      });
      finalshipprice = totalshipcost.toFixed(2);
      return finalshipprice;
    },

    getFullPrice() {
      let itemprice = 0;
      let allitems = this.cartData;

      // eslint-disable-next-line no-unused-vars
      let shipcost = this.getShippingperItemcost
        ? this.getShippingperItemcost
        : 0;
      let tax = this.getTax;
      let discount = this.getDiscount;
      allitems.map((e) => {
        let unitprice = e.price * e.qty;
        itemprice = itemprice + unitprice;
      });
      if (discount && discount > 0) {
        return (
          parseFloat(itemprice) +
          parseFloat(shipcost) +
          parseFloat(tax) -
          parseFloat(discount)
        ).toFixed(2);
      } else {
        return (
          parseFloat(itemprice) +
          parseFloat(shipcost) +
          parseFloat(tax)
        ).toFixed(2);
      }
    },
  },
  mounted() {
    this.selectedMethod = JSON.parse(localStorage.getItem("paymentMethod"));
    console.log(this.getFullPrice);

    this.TotalPrice = this.cartData.reduce((accumulator, currentvalue) => {
      return currentvalue.total_price !== undefined
        ? (
            parseFloat(accumulator) + parseFloat(currentvalue.total_price)
          ).toFixed(2)
        : 0;
    }, 0);

    let allpayment = document.querySelectorAll('input[name="paypal"]');

    //console.log("HI...???");

    allpayment.forEach((e) => {
      e.addEventListener("change", () => {
        let getPaymentId = e.checked && e.checked == true ? e.value : "";
        let getPaymentValue =
          e.checked && e.checked == true ? e.getAttribute("data-payment") : "";
        if (localStorage.getItem("paymentMethod") === null) {
          let payments = {
            payment_method: getPaymentValue,
            payment_id: getPaymentId,
          };

          localStorage.setItem("paymentMethod", JSON.stringify(payments));
        } else {
          let payments = {
            payment_method: getPaymentValue,
            payment_id: getPaymentId,
          };
          this.selectedMethod = {};
          localStorage.setItem("paymentMethod", {});
          this.selectedMethod = payments;
          localStorage.setItem("paymentMethod", JSON.stringify(payments));
        }
      });
    });
  },

  methods: {
    replaceCommaFromBrTag(files) {
        const newData = files.split(',');
        var rv = {};
         for (var i = 0; i < newData.length; ++i)
         if (newData[i] !== undefined) rv[i] = newData[i];
       return rv;
      
    },
    gotoCheckout() {
      this.$router.push("/review");
      // let getRadios = document.querySelectorAll("#paypalRadiosButton");
      // getRadios.forEach((e) => {
      //   if (e.checked === true) {
      //     let getPaymentMode = e.getAttribute("data-payment");

      //     switch (getPaymentMode) {
      //       case "Paypal":
      //         console.log(getPaymentMode);
      //         break;
      //       case "Visa":
      //       case "American Express":
      //       case "Master Card":
      //         this.$router.push("/payment/helcim/checkout");
      //         break;
      //       case "Cheque":
      //         this.$router.push("/payment/check-cash");
      //         break;
      //     }
      //   }
      // });
    },
    ...mapActions("cartData", {
      deletesinglecartArtwork: DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION,
    }),
    ...mapActions("cartData", {
      deletecartArtwork: DELETE_UPLOADED_CART_ARTWORK_ACTION,
    }),
    removeArtwork(event, order_id, item_id, product_id) {
      const removeartwork = confirm("Are you Sure you want to remove Artwork?");
      if (removeartwork == true) {
        let getfilename = event.target.parentElement.previousElementSibling;
        let file_name = getfilename.innerText;
        if (localStorage.getItem("artworkFiles") !== null) {
          localStorage.removeItem("artworkFiles");
        }
        //console.log(event.target)
        let result = this.$axios.get(
          `${process.env.VUE_APP_BASE_URL}cart/remove_artwork/${order_id}/${item_id}/${product_id}`
        );
        result
          .then((res) => {
            if (res.status == 200) {
              let artwork_file_name = {
                file: file_name,
              };
              this.deletecartArtwork(artwork_file_name);
              //this.deleteArtwork({});
              this.deletesinglecartArtwork(res);
              toast.success(
                "Artwork file have been Successfully removed from Cart",
                {
                  autoClose: 2000,
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.coupon;
    this.discount;

    if (localStorage.getItem("paymentMethod") === null) {
      let payments = {
        payment_method: "Paypal",
        payment_id: 1,
      };

      localStorage.setItem("paymentMethod", JSON.stringify(payments));
    }
    //  else {
    //   let payments = {
    //     payment_method: "Paypal",
    //     payment_id: 1,
    //   };
    //   localStorage.setItem("paymentMethod", {});

    //   localStorage.setItem("paymentMethod", JSON.stringify(payments));
    // }
  },
  updated() {
    this.coupon;
    this.discount;
  },
};
</script>
