import {/*SET_USER_DATA_MUTATION*/SET_USER_PRINTING_ORDER_ACTION_MUTATION,SET_USER_COMMERCIAL_ORDER_ACTION_MUTATION,SET_USER_SHIPPING_MUTATION,SET_ORDER_DETAILS_MUTATION,SET_UPLOADED_ARTWORK_MUTATION,SET_USER_SHIPPING_ADDRESS_MUTATION,CLEAR_USERINFO_MUTATION,GETUPDATED_USER_SHIPPING_ADDRESS_MUTATION } from "@/store/storeconstants";

export default {
    // [SET_USER_DATA_MUTATION](state, payload) {
    //     state.UserInfo = payload
    // },
    [SET_USER_PRINTING_ORDER_ACTION_MUTATION](state,payload){
        state.PrintingOrders = payload
    },


    [SET_USER_COMMERCIAL_ORDER_ACTION_MUTATION](state,payload){
        state.CommercialOrders= payload
    },
  
    [SET_USER_SHIPPING_MUTATION ](state,payload){

        state.DefaultShipping = payload
    }, 

    [SET_ORDER_DETAILS_MUTATION ](state,payload){
      
        state.orderDetails= payload
    }, 

    [SET_UPLOADED_ARTWORK_MUTATION](state,payload){
        state.userUploadedArtwork= payload
    },


    [SET_USER_SHIPPING_ADDRESS_MUTATION](state,payload){
        state.userShippingAddresses=payload
    },


    /****clear user info upon logout ****/
   [CLEAR_USERINFO_MUTATION](state)
   {
    state.PrintingOrders=[]
    state.CommercialOrders=[]
    //state.DefaultShipping=[]
    state.orderDetails=[]
    state.userUploadedArtwork=[]
    state.userShippingAddresses=[]
   
   },
 
    /****clear user info upon logout  ****/

   /****Updated user Address *****/
   // eslint-disable-next-line no-unused-vars
   [GETUPDATED_USER_SHIPPING_ADDRESS_MUTATION](state,payload)
   {
    let getuseraddresess=state.userShippingAddresses.data


    getuseraddresess.map((e,i)=>{
      
        if(e.id===payload.id) //if both shipping id matches
        {
            state.userShippingAddresses.data[i][e.id]=payload
            console.log(state.userShippingAddresses.data[i])
        }

      
    })


   }
   /****Updated user Address *****/


    
    
};