import SignupValidations from "@/services/SignupValidations";
///import { useStore } from "vuex";
import {
    GET_CART_DATA_ACTION,
    SET_CART_DATA_MUTATION,
    GET_CART_QUANTITY_ACTION,
    GET_UPDATE_CART_DATA_ACTION,
    SET_UPDATE_CART_DATA_MUTATION,
    SET_CART_QUANTITY_MUTATION,
    DELETE_SINGLE_ITEM_CART_ACTION,
    SET_DELETE_SINGLE_ITEM_CART_MUTATION,
    DELETE_ALL_ITEMS_CART_ACTION,
    SET_DELETE_ALL_ITEMS_CART_MUTATION,
    GET_PAYMENT_DATA_ACTION,
    SET_PAYMENT_DATA_MUTATION,
    GET_CART_REVIEW_ACTION,
    SET_CART_REVIEW_MUTATION,
    HELCIUM_PAYMENT_ACTION,
    HELCIUM_PAYMENT_MUTATION,
    CHECK_PAYMENT_ACTION,
    CHECK_PAYMENT_MUTATION,
    PAYPAL_PAYMENT_ACTION,
    PAYPAL_PAYMENT_MUTATION,
    CLEAR_CART_DATA_ACTION,
    CLEAR_CART_MUTATION,
    DELETE_UPLOADED_CART_ARTWORK_ACTION,
    DELETE_UPLOADED_CART_ARTWORK_MUTATION,
    DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION,
    DELETE_SINGLE_UPLOADED_CART_ARTWORK_MUTATION,
    GET_UPDATE_CART_SHIPFROM_DATA_ACTION,
    SET_UPDATE_CART_SHIPFROM_DATA_MUTATION,
    GET_UPLOAD_MULTIPLE_FILE_ACTION,
    // SET_UPLOAD_MULTIPLE_FILE_MUTATION

} from "@/store/storeconstants";
const axios = require("axios");


let storageData = "";
let tokenData = "";
var gettoken = "";
//const store = useStore();
//let token = store.state?.auth?.CurrentUserLogin?.CurrentUserLogin?.token;

//let token_user = this.userData?.token != "" ? this.userData?.token : "";

if (localStorage.vuex != undefined) {


    const storage = { ...localStorage };

    storageData = JSON.parse(storage.vuex);

    tokenData = (storageData != undefined) ? storageData.auth : "";

    console.log('????SDf', tokenData.CurrentUserLogin.CurrentUserLogin);

    gettoken = (tokenData != undefined) ? tokenData.CurrentUserLogin.CurrentUserLogin : "";
}

console.log("cart action")
console.log(gettoken , "gettoken up")

export default {

    async [GET_UPLOAD_MULTIPLE_FILE_ACTION](context, payload) {
        try {
            const headers = {
                'Content-Type': 'multipart/form-data',
            }

            let response = await axios.post(
                `${process.env.VUE_APP_BASE_URL}product/updatecartFiles`,
                payload, headers
            );
            if (response.status === 200) {
                // context.commit(SET_UPLOAD_MULTIPLE_FILE_MUTATION, response);
                return response;
            }
        } catch (error) {
          console.log(error, "Error From GET_UPLOAD_MULTIPLE_FILE_ACTION")
        }
    },


    async [GET_CART_DATA_ACTION](context, payload) {
        try {
            const headers = {
                'Content-Type': 'multipart/form-data',
            }


            let response = await axios.post(
                `${process.env.VUE_APP_BASE_URL}product/addtocart`,
                payload, headers
            );
            if (response.status === 200) {
                console.log('!Q......1');
                context.commit(SET_CART_DATA_MUTATION, response);

                return response;
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response
            );
            throw errormsg;
        }
    },

    async [GET_UPDATE_CART_DATA_ACTION](context, payload) {
        try {
            const headers = {
                'Content-Type': 'multipart/form-data',
            }

            let response = await axios.post(
                `${process.env.VUE_APP_BASE_URL}product/updatecartFiles`,
                payload, headers
            );
            if (response.status === 200) {
                context.commit(SET_UPDATE_CART_DATA_MUTATION, response);
                return response;
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response
            );
            throw errormsg;
        }
    },

    async [GET_UPDATE_CART_SHIPFROM_DATA_ACTION](context, payload) {
        context.commit(SET_UPDATE_CART_SHIPFROM_DATA_MUTATION, payload);
    },

    //
    async [GET_CART_QUANTITY_ACTION](context, payload) {
        try {
            let response = await axios.post(
                `${process.env.VUE_APP_BASE_URL}cart/updateQuantity`,
                payload
            );
            if (response.status === 200) {
                context.commit(SET_CART_QUANTITY_MUTATION, response.data);
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response.data
            );
            throw errormsg;
        }
    },

    async [DELETE_SINGLE_ITEM_CART_ACTION](context, payload) {
        let order_id = payload.order_id;
        try {
            let response = await axios.get(
                `${process.env.VUE_APP_BASE_URL}cart/delete/delete/${order_id}`,
                payload
            );

            if (response.status === 200) {

                // console.log('hi.....response.data',response.data.data.itemsCount);
                // console.log('hi.....response.data.itemsCount',response.data.itemsCount);
                if (response.data.data.itemsCount == 0) {
                    localStorage.removeItem("current_order_id");
                    localStorage.removeItem("notes");
                    localStorage.removeItem("product_option_map");
                    localStorage.removeItem("tax");
                    localStorage.removeItem("shipping_methods");
                    localStorage.removeItem("tax_redemption");
                    localStorage.removeItem("paymentMethod");
                }
                //return false;
                context.commit(SET_DELETE_SINGLE_ITEM_CART_MUTATION, response.data);
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response.data
            );
            throw errormsg;
        }
    },

    async [DELETE_ALL_ITEMS_CART_ACTION](context, payload) {
        try {


            let order_id = localStorage.getItem("current_order_id");
            // payload.order_id=current_order_id;
            console.log('payload...kkk-order_id', payload);
            /* eslint-disable */
            let response = await axios.post(
                `${process.env.VUE_APP_BASE_URL}cart/clear_cart`,
                { order_id: order_id }
            );
            if (response.status === 200) {
                //if(response.data.data.itemsCount==0){
                localStorage.removeItem("current_order_id");
                localStorage.removeItem("product_option_map");
                localStorage.removeItem("tax");
                localStorage.removeItem("shipping_methods");
                localStorage.removeItem("tax_redemption");
                localStorage.removeItem("paymentMethod");
                // }
                context.commit(SET_DELETE_ALL_ITEMS_CART_MUTATION, response.data);
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response.data
            );
            throw errormsg;
        }
    },


    async [GET_PAYMENT_DATA_ACTION](context, payload) {
        try {

            if (gettoken !== undefined) {
                let session_id = payload.session_id;

                let config = {
                    headers: {
                        'Authorization': `Bearer ${gettoken.token
                            }`
                    }
                }
                let response = await axios.get(`${process.env.VUE_APP_BASE_URL
                    }cart/payment/helcim/checkout?session_id=${session_id}`, config);
                if (response.status === 200) {
                    context.commit(SET_PAYMENT_DATA_MUTATION, response.data);
                }
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                // error.response.data
                error.response.data
            );
            throw errormsg;
        }
    },

    /******Review Cart *****************/
    async [GET_CART_REVIEW_ACTION](context, payload) {
        // console.log(payload?.token, payload, 'payload')
        // console.log("Hello Main Token gettoken", gettoken)
        // console.log('token..--??', gettoken?.token);
        let token = gettoken?.token ? gettoken?.token:payload?.token;
        console.log(token, "token from front")
        try {
            let config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }

            let response = await axios.post(`${process.env.VUE_APP_BASE_URL}cart/review`, payload?.formDataSend, config)
            console.log(response, "response cart review out if")
            if (response.status === 200) {
                console.log(response, "response cart review in if")
                context.commit(SET_CART_REVIEW_MUTATION,
                    //response.data.data
                    response.data.result
                )
                return response;
            }
        } catch (error) {
            //  let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            //throw errormsg;
            console.log(error);
        }

    },
    /******Review Cart *****************/

    /***Helcium Payment *****/
    async [HELCIUM_PAYMENT_ACTION](context, payload) {

        try {
            if (gettoken != undefined) {

                console.log(payload)
                let config = {
                    headers: {
                        'Authorization': `Bearer ${gettoken.token}`
                    }
                }

                let response = await axios.post(`${process.env.VUE_APP_BASE_URL}cart/payment/helcim/checkout`, payload, config)

                if (response.status === 200) {
                    context.commit(HELCIUM_PAYMENT_MUTATION,
                        response.data.data
                    )

                    return response;
                }
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },
    /***Helcium Payment *****/

    /***Check-Cash Payment ****/
    async [CHECK_PAYMENT_ACTION](context, payload) {
        let token = gettoken?.token ? gettoken?.token:payload?.token;
        console.log(token, "token from front")
        try {
            let config = {
                headers: {'Authorization': `Bearer ${token}`}
            }
            let response = await axios.post(`${process.env.VUE_APP_BASE_URL}cart/payment/cash-checkout`, payload?.formDataSend, config)
            if (response.status === 200) {
                context.commit(CHECK_PAYMENT_MUTATION,
                    response.data.data
                )
                return response
            }
            
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },

    /****Check-Cash Payment ****/


    /****Paypal Payment  ******/
    async [PAYPAL_PAYMENT_ACTION](context, payload) {

        try {
            if (gettoken != undefined) {
                //let order_id=payload.order_id


                let config = {
                    headers: {
                        'Authorization': `Bearer ${gettoken.token}`
                    }
                }



                let response = await axios.post(`${process.env.VUE_APP_BASE_URL}carts/payment/paypal/cart`, payload, config)

                if (response.status === 200) {
                    context.commit(PAYPAL_PAYMENT_MUTATION,
                        response.data.data
                    )
                    return response
                }
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response)
            throw errormsg;
        }

    },

    /****Paypal Payment  ******/


    /****CLEAR CART DATA *******/

    async [CLEAR_CART_DATA_ACTION](context, payload) {

        context.commit(CLEAR_CART_MUTATION, payload);


    },
    /****CLEAR CART DATA *******/


    /***Delete uploaded artwork ****/
    async [DELETE_UPLOADED_CART_ARTWORK_ACTION](context, payload) {

        let filename = payload.file
        try {
            context.commit(DELETE_UPLOADED_CART_ARTWORK_MUTATION, filename)
        } catch (error) {
            console.log(error)
            // let errormsg = SignupValidations.getErrorMessageFromCode(error.response)
            // throw errormsg;
        }

    },
    /***Delete uploaded artwork ****/

    async [DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION](context, payload) {

        try {
            context.commit(DELETE_SINGLE_UPLOADED_CART_ARTWORK_MUTATION, payload)
        } catch (error) {
            console.log(error)
            // let errormsg = SignupValidations.getErrorMessageFromCode(error.response)
            // throw errormsg;
        }

    }

};
