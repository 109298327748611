<template>
  <!-- Address List Modal Start -->
  <div
    class="modal fade"
    id="myShippingModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myShippingModal"
    aria-hidden="true"
    style="display: none"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">
            <span>Select Shipping address</span>
            <div class="pull-right" id="addnew">
              <a
                class="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#add-address"
                ><i class="icon-plus"></i> Add New Address</a
              >
            </div>
          </h4>
          <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true">
            ×
          </button>
        </div>

        <div id="fetched-content">
          <div class="table-responsive">
            <table
              class="table table-striped table table-bordered table-hover"
              id="addresses_account"
            >
              <thead>
                <tr>
                  <th>First Names</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>company</th>
                </tr>
              </thead>

              <tbody ref="allfields"></tbody>

              <tfoot></tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Address List Modal End -->

  <!----Add new address modal--->
  <div class="modal fade" id="add-address" role="dialog">
    <div class="modal-dialog modal-lg">
      <form
        @submit.prevent="onFormsubmit"
        name="address_submit"
        id="address"
        enctype="multipart/form-data"
      >
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add New Address</h4>
            <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
            <div class="alert alert-danger errors hidden" id="ups_err"></div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input
                    type="text"
                    v-model="formData.first_name"
                    class="form-control"
                    name="first_name"
                    placeholder="Enter First Name"
                    required
                    data-error-msg="The First Name is required!"
                  />
                  <div class="alert alert-danger errors hidden" id="first_name_err"></div>
                </div>
              </div>
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input
                    type="text"
                    v-model="formData.last_name"
                    class="form-control"
                    name="last_name"
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="Title">Title</label>
                  <input
                    type="text"
                    v-model="formData.title"
                    class="form-control"
                    name="title"
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="Company">Company</label>
                  <input
                    type="text"
                    v-model="formData.company"
                    class="form-control"
                    name="company"
                    placeholder="Enter Company"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="Address1">Address1</label>
                  <input
                    type="text"
                    v-model="formData.address1"
                    class="form-control"
                    name="address1"
                    placeholder="Enter Address1"
                    required
                    data-error-msg="The Address1 is required!"
                  />
                  <div class="alert alert-danger errors hidden" id="address1_err"></div>
                </div>
              </div>
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="Address2">Address2</label>
                  <input
                    type="text"
                    v-model="formData.address2"
                    class="form-control"
                    name="address2"
                    placeholder="Enter Address2"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label>Country <span class="red">*</span></label>
                  <select
                    v-model="formData.country"
                    required
                    @change="getCountry"
                    name="country"
                    class="form-control"
                    id="country"
                    data-error-msg="The Country is required!"
                  >
                    <option selected>Select Country</option>
                    <option
                      v-for="country in countries"
                      :value="country.id"
                      :key="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <div class="alert alert-danger errors hidden" id="country_err"></div>
                <!-- <div class="form-group">
                        <label for="City">City</label>
                        <input type="text" class="form-control" name="city"  placeholder="Enter City">
                    </div> -->
              </div>
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label>State <span class="red">*</span></label>
                  <select
                    ref="state"
                    required
                    class="form-control"
                    name="state_id"
                    id="state"
                    v-model="formData.state"
                    @change="getStates"
                    :disabled="!hasState"
                    data-error-msg="The State is required!"
                  >
                    <option v-for="state in states" :key="state.id" :value="state.id">
                      {{ state.name }}
                    </option>
                  </select>
                  <div class="alert alert-danger errors hidden" id="state_id_err"></div>
                </div>
                <!--  <div class="form-group">
                        <label>Select State</label>
                        <select name="state_id" class="form-control">
                            <?php $data = getState('231'); ?>
                            @foreach ($data as $state)
<option value="{{ $state->id }}">{{ $state->s_abbreviation }}</option>
@endforeach
                        </select>
                    </div> -->
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label>City <span class="red">*</span></label>
                  <select
                    v-model="formData.city"
                    required
                    @change="getCity"
                    :disabled="!hasCity"
                    class="form-control"
                    name="city"
                    id="city"
                    data-error-msg="The City is required!"
                  >
                    <option selected>Select City</option>
                    <option v-for="city in cities" :key="city.id" :value="city.id">
                      {{ city.name }}
                    </option>
                  </select>
                  <div class="alert alert-danger errors hidden" id="city_err"></div>
                </div>
                <!--  <div class="form-group">
                        <label for="country">Country</label>
                        <input type="text" class="form-control" name="country"  placeholder="Enter Country">
                    </div> -->
              </div>
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="zip">Zip*</label>
                  <input
                    type="text"
                    v-model="formData.zip"
                    class="form-control"
                    name="zip"
                    placeholder="Enter Zip"
                    required
                    data-error-msg="The Zip is required!"
                  />
                  <div class="alert alert-danger errors hidden" id="zip_err"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="day_phone">Day Phone</label>
                  <input
                    type="text"
                    v-model="formData.day_phone"
                    class="form-control"
                    name="day_phone"
                    required
                    placeholder="Enter Day Phone"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-xs-12">
                <div class="form-group">
                  <label for="night_phone">Night Phone</label>
                  <input
                    type="text"
                    v-model="formData.night_phone"
                    class="form-control"
                    name="night_phone"
                    placeholder="Enter Night Phone"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-xs-12">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    v-model="formData.email"
                    type="text"
                    required
                    class="form-control"
                    name="email"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="country-error text-danger"></div>
            <div class="country-success text-success"></div>
            <!-- <input type="hidden" name="id" value="4"> -->
            <button
              type="submit"
              id="addressbook_submit"
              name="addressbook_submit"
              value="addressbook_submit"
              class="btn btn-primary btn-lg"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!---Add new address modal-->
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "ShippingAddress",
  components: {},
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        title: "",
        company: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        day_phone: "",
        night_phone: "",
        email: "",
      },
      countries: ["Select Country"],
      states: [],
      cities: [],
      error: [],
      selectedCountry: "Select Country",
      selectedState: "Select State",
      selectedCity: "Select City",
      countryIso2: "",
      stateIso2: "",
      hasCity: true,
      hasState: true,
    };
  },
  setup() {
    const store = useStore();
    let checkToken = store.state.auth.CurrentUserLogin.CurrentUserLogin;
    let checkCartitems = store.state.cartData.cart;
    // eslint-disable-next-line no-prototype-builtins
    let token = checkToken.hasOwnProperty("token") ? checkToken.token : "";

    return {
      token,
      checkCartitems,
    };
  },
  props: {
    //shippingdata: Object,
  },
  async mounted() {
    // this.$axios
    // .get('your-api-url-for-options1')
    //   .then(response => {
    //     this.options1 = response.data;
    //   })
    //   .catch(error => {
    //     console.error('Error fetching options1:', error);
    //   });

    // Make an Axios API request to populate Country
    await this.$axios
      .get(process.env.VUE_APP_BASE_URL + "country")
      .then((res) => {
        this.formData.country = res.data.data !== undefined ? res.data.data : [];
        this.countries = res.data.data !== undefined ? res.data.data : [];
      })
      .catch((error) => {
        console.log(error);
      });

    // Make an Axios API request to populate State
  },
  methods: {
    onFormsubmit() {
      //let checkError=[]
      let formData = new FormData();

      formData.append("first_name", this.formData.first_name);
      formData.append("last_name", this.formData.last_name);
      formData.append("title", this.formData.title);
      formData.append("company", this.formData.company);
      formData.append("address1", this.formData.address1);
      formData.append("address2", this.formData.address2);
      formData.append("country", this.formData.country);
      formData.append("state_id", this.formData.state);
      formData.append("city", this.formData.city);
      formData.append("zip", this.formData.zip);
      formData.append("day_phone", this.formData.day_phone);
      formData.append("night_phone", this.formData.night_phone);
      formData.append("email", this.formData.email);

      let session_id = "";
      this.checkCartitems.map(function (e) {
        session_id = e.session_id != undefined ? e.session_id : "";
      });

      if (this.token !== undefined) {
        let config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };
        let isCart = false;
        isCart = this.checkCartitems.length > 0 ? true : false;

        formData.append("isCart", isCart);
        formData.append("session_id", session_id);
        let _that = this;
        this.$axios
          .post(process.env.VUE_APP_BASE_URL + "address", formData, config)
          .then((res) => {
            if (res.status == 200) {
              let successfield = document.getElementsByClassName("country-success");
              successfield[0].innerHTML = "<p style='background: #135a23; padding: 7px 7px 7px 7px;color: #FFF;'>Address saved successfully</p>";
                _that.formData= {
                  first_name: "",
                  last_name: "",
                  title: "",
                  company: "",
                  address1: "",
                  address2: "",
                  country: "",
                  state: "",
                  city: "",
                  zip: "",
                  day_phone: "",
                  night_phone: "",
                  email: "",
                },
              setTimeout(() => {
                successfield[0].innerHTML = " ";
                document.querySelector("#add-address .close").click();
                // document.getElementById("showshipping").click();
              }, 3000);
            }
          })
          .catch((error) => {
            if (error) {
              this.error = error.message;
              let errorfield = document.getElementsByClassName("country-error");
              if (this.error) {
                errorfield[0].innerHTML =
                  "<p style='background: #dc354530;padding: 7px 7px 7px 7px;color: #dc3545;'>Sorry invalid countrycode or location</p>";
                setTimeout(() => {
                  errorfield[0].innerHTML = "";
                }, 4500);
              } else {
                errorfield[0].innerHTML = " ";
              }
            }
          });
      }
    },

    async getCountry() {
      this.states = [];
      this.cities = [];
      this.hasState = true;
      this.selectedState = "Select State";
      this.selectedCity = "Select City";

      if (this.countries && this.formData.country) {
        let country_id = this.formData.country;
        await this.$axios
          .get(process.env.VUE_APP_BASE_URL + "state", {
            params: { country_id: country_id },
          })
          .then((response) => {
            this.states = response.data.data;
            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async getStates() {
      this.cities = [];
      this.hasState = true;
      this.selectedCity = "Select City";

      if (this.states && this.formData.state) {
        let state_id = this.formData.state;
        await this.$axios
          .get(process.env.VUE_APP_BASE_URL + "city", {
            params: { state_id: state_id },
          })
          .then((response) => {
            this.cities = response.data.data;

            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getUserInfo(shipping_id) {
      console.log(shipping_id);
    },
  },

  created() {},
};
</script>

<style scoped>
h4.modal-title {
  font-weight: 100 !important;
  line-height: 1.1 !important;
  color: #333333 !important;
}

#addnew {
  margin-left: 350px;
}

button.close {
  opacity: 0.5;
  float: right;
  font-size: 21px !important;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
#fetched-content {
  padding: 12px;
}

.btn.btn-primary.btn-sm,
tbody tr td a {
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}

.country-error,
.country-success {
  margin-right: 184px;
  font-size: 1.2em;
  font-weight: 500;
}

button.close {
  opacity: 0.5;
  float: right;
  font-size: 21px !important;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.btn-primary {
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}

.btn-primary:hover {
  border-radius: 0px 15px 0px 15px !important;
}
</style>
