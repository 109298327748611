import {
    GET_COUNTRY_GETTER,
    GET_STATE_GETTER,
    GET_CITY_GETTER 
  } from "@/store/storeconstants";
  
  export default {
    [GET_COUNTRY_GETTER]: (state) => {
      return state.country;
    },  
    
    [ GET_STATE_GETTER]:(state)=>{
        return state.states 
    },

    [GET_CITY_GETTER]:(state)=>{
        return state.city
    }
  };
  