import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      cart: [],
      // updatedCart: [],
      // updatedCartFiles:[],
      // deletedSingleItem: "",
      // deleteAllItems: "",
      paymentData:[],
      HelciumPayment:[],
      CheckPayment:[],
      paypalPayment:[],
      reviewPayment:[],
    };
  },
  mutations,
  getters,
  actions,
};
