import SignupValidations from "@/services/SignupValidations";
import { GET_HOME_PAGE_DATA_ACTION,  SET_HOME_PAGE_DATA_MUTATION} from "@/store/storeconstants";
const axios = require("axios");

export default {
    async [GET_HOME_PAGE_DATA_ACTION](context) {
        try {
         
            let response = await axios.get(`${process.env.VUE_APP_BASE_URL}home`)
            if (response.status === 200) {
               // console.log( response?.data?.data,'h....................');
                context.commit(SET_HOME_PAGE_DATA_MUTATION,
                    response?.data?.data
                )
                return response;
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },

};