import {/*GET_USER_DATA_GETTER*/GET_USER_PRINTING_ORDER_GETTER,GET_USER_COMMERCIAL_ORDER_GETTER,GET_USER_SHIPPING_GETTER,GET_ORDER_DETAILS_GETTER,GET_UPLOADED_ARTWORK_GETTER, GET_USER_SHIPPING_ADDRESS_GETTER,GETUPDATED_USER_SHIPPING_ADDRESS_GETTER } from "@/store/storeconstants";

export default {
    // [GET_USER_DATA_GETTER]: (state) => {
    //     return state.UserInfo
    // },
    [GET_USER_PRINTING_ORDER_GETTER]: (state) => {
        return state.PrintingOrders
    },

    [GET_USER_COMMERCIAL_ORDER_GETTER]:(state)=>{
        return state.CommercialOrders
    },

    [GET_USER_SHIPPING_GETTER]: (state) => {
        return state.DefaultShipping
    },

    [GET_ORDER_DETAILS_GETTER]: (state) => {
        return state.orderDetails
    },

    [GET_UPLOADED_ARTWORK_GETTER ]: (state) => {
        return state.userUploadedArtwork
    },

    [GET_USER_SHIPPING_ADDRESS_GETTER]:(state)=>{
        return state.userShippingAddresses
    },

    [GETUPDATED_USER_SHIPPING_ADDRESS_GETTER]:(state)=>{
        return state.userShippingAddresses
    }

    

    
};