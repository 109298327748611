<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<template>
  <div class="align-items-center hide" id="loader">
    <img src="../assets/img/loader.gif" />
  </div>
  <form
    ref="shipdata"
    method="post"
    @submit.prevent="paymentProcess()"
    enctype="multipart/form-data"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb align-items-center mb-0">
              <li class="breadcrumb-item">
                <a
                  href="#"
                  class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                  >Home</a
                >
              </li>
              <li class="breadcrumb-item">
                <a
                  href="#/pages/printing"
                  class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                  >Printing</a
                >
              </li>
              <!-- <li class="breadcrumb-item">
                <a
                  href="#/pages/printing"
                  class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                  >{{ getProducts[0] }}</a
                >
              </li> -->
              <li
                class="breadcrumb-item font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
              >
                {{ breadcrumbs.cururl }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="container mt-2">
      <div class="row">
        <div class="col-md-12">
          <ul id="progress">
            <li
              class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
            >
              my shopping cart
            </li>
            <li
              class="font-14 font-family-Roboto font-weight-700 text-capitalize"
              :class="{ finish: shippingroute }"
            >
              Shipping Details
            </li>
            <li
              class="font-14 font-family-Roboto font-weight-700 text-capitalize"
            >
              Payment Details
            </li>
            <li
              class="font-14 font-family-Roboto font-weight-700 text-capitalize"
            >
              Review Details
            </li>
            <li
              class="font-14 font-family-Roboto font-weight-700 text-capitalize"
            >
              Checkout
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Breadcrumb STops -->

    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12 col-lg-7">
          <div class="row">
            <!----------------Shipping From--------------->
            <div class="col-md-12">
              <h1 class="font-30 font-weight-700 mb-5 float-start">
                Shipping From
              </h1>
              <button
                @click.prevent="openShip('shipfrom')"
                id="shipfrom"
                data-bs-toggle="modal"
                data-bs-target="#myShippingModal"
                class="btn btn-info mt-2 float-end"
              >
                CHANGE
              </button>
              <!-- <button @click.prevent="handleText()">TEST</button> -->
              <div id="shipfromaddress" class="row">
                <div class="col-md-6 mb-4">
                  <label
                    for="full-name"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Full Name
                  </label>
                  <input
                    readonly
                    type="text"
                    name="ship_from_full_name"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_from_full_name"
                    placeholder="Full Name"
                    :value="
                      submittedData.full_name
                        ? submittedData.full_name
                        : getDefaultShipping.hasOwnProperty(
                            'shipping_from_full_name'
                          )
                        ? getDefaultShipping?.shipping_from_full_name
                        : ''
                    "
                  />
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="phone-number"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Phone Number</label
                  >
                  <input
                    readonly
                    type="text"
                    name="ship_from_phno"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_from_phno"
                    placeholder="Enter Phone No"
                    :value="
                      submittedData.phone
                        ? submittedData.phone
                        : getDefaultShipping.hasOwnProperty(
                            'shipping_from_day_phone'
                          )
                        ? getDefaultShipping?.shipping_from_day_phone
                        : ''
                    "
                  />
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="email-address"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Email Address</label
                  >
                  <input
                    readonly
                    type="email"
                    name="ship_from_email"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_from_email"
                    placeholder="Enter Email Address"
                    :value="
                      submittedData.email
                        ? submittedData.email
                        : getDefaultShipping.hasOwnProperty(
                            'shipping_from_email'
                          )
                        ? getDefaultShipping?.shipping_from_email
                        : ''
                    "
                  />
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="address 1"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Country</label
                  >
                  <select
                    disabled="true"
                    v-model="submittedData.country"
                    @click="$emit('click', $event.target.value)"
                    name="ship_from_country"
                    id="ship_from_country"
                    @change.prevent="getShipFromCountry"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                  >
                    <option
                      v-for="country in countriesShipfrom"
                      :value="country.id"
                      :key="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="state"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >State</label
                  >
                  <select
                    disabled="true"
                    name="ship_from_state"
                    v-model="submittedData.state"
                    id="ship_from_state"
                    @change.prevent="getshipFromStates($event)"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                  >
                    <option
                      v-for="state in statesShipfrom"
                      :key="state.id"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="city"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >City</label
                  >
                  <select
                    disabled="true"
                    name="ship_from_city"
                    v-model="submittedData.city"
                    @change.prevent="getshipFromCity($event)"
                    id="ship_from_city"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                  >
                    <option
                      v-for="city in citiesShipfrom"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="zip"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >ZIP</label
                  >
                  <input
                    readonly
                    type="number"
                    name="ship_from_zip"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_from_zip"
                    placeholder="Enter Zip"
                    :value="
                      submittedData.zip
                        ? submittedData.zip
                        : getDefaultShipping.hasOwnProperty('shipping_from_zip')
                        ? getDefaultShipping?.shipping_from_zip
                        : ''
                    "
                  />
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="address"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Address 1</label
                  >
                  <input
                    readonly
                    type="text"
                    name="ship_from_address"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_from_address"
                    placeholder="Enter Address"
                    :value="
                      submittedData.address1
                        ? submittedData.address1
                        : getDefaultShipping.hasOwnProperty(
                            'shipping_from_address'
                          )
                        ? getDefaultShipping.shipping_from_address
                        : ''
                    "
                  />
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="address 2"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Address 2</label
                  >
                  <input
                    readonly
                    type="text"
                    name="ship_from_address_1"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_from_address_1"
                    placeholder="Enter Address 1"
                    :value="
                      submittedData.address2
                        ? submittedData.address2
                        : getDefaultShipping.hasOwnProperty(
                            'shipping_from_address_1'
                          )
                        ? getDefaultShipping.shipping_from_address_1
                        : ''
                    "
                  />
                </div>
              </div>
            </div>
            <!------------------Shipping From--------------->

            <!------------------Shipping To------------------>
            <div class="col-md-12 mt-5 pt-4">
              <h1 class="font-30 font-weight-700 mb-5 float-start">
                Shipping To
              </h1>
              <button
                @click.prevent="openShip('shipto')"
                id="shipto"
                data-bs-toggle="modal"
                data-bs-target="#myShippingModal"
                class="btn btn-info mt-2 float-end"
              >
                CHANGE
              </button>

              <div id="shipfromaddress" class="row">
                <div class="col-md-6 mb-4">
                  <label
                    for="full-name"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Full Name</label
                  >
                  <input
                    type="text"
                    name="ship_to_full_name"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_to_full_name"
                    placeholder="Full Name"
                  />
                  <label
                    id="ship_to_full_name_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >Full Name is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="phone-number"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Phone Number</label
                  >
                  <input
                    type="text"
                    name="ship_to_phno"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_to_phno"
                    placeholder="Enter Phone No"
                  />
                  <label
                    id="ship_to_phno_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >Phone number is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="email-address"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Email Address</label
                  >
                  <input
                    type="email"
                    name="ship_to_email"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_to_email"
                    placeholder="Enter Email Address"
                  />
                  <label
                    id="ship_to_email_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >Email is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="address 1"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Country</label
                  >
                  <select
                    v-model="shiptoSubmitted.country"
                    name="ship_to_country"
                    id="ship_to_country"
                    @change.prevent="getShipToCountry($event)"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                  >
                    {{
                      shiptoSubmitted
                    }}
                    <option
                      v-for="country in countriesShipto"
                      :value="country.id"
                      :key="country.id"
                      :id="'ship_to_country-' + country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                  <label
                    id="ship_to_country_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >Country is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="state"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >State</label
                  >
                  <select
                    name="ship_to_state"
                    v-model="shiptoSubmitted.state"
                    id="ship_to_state"
                    @change.prevent="getshipToStates($event)"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                  >
                    <option
                      v-for="state in statesShipto"
                      :key="state.id"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                  <label
                    id="ship_to_state_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >State is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="city"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >City</label
                  >

                  <select
                    name="ship_to_city"
                    v-model="shiptoSubmitted.city"
                    @change.prevent="getshipToCity($event)"
                    id="ship_to_city"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                  >
                    <option selected value="">Select city</option>
                    <option
                      v-for="city in citiesShipto"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <label
                    id="ship_to_city_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >City is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="zip"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >ZIP</label
                  >
                  <input
                    type="number"
                    name="ship_to_zip"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_to_zip"
                    placeholder="Enter Zip"
                  />
                  <label
                    id="ship_to_zip_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >Zip is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="address"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Address 1</label
                  >
                  <input
                    type="text"
                    name="ship_to_address"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_to_address"
                    placeholder="Enter Address"
                  />
                  <label
                    id="ship_to_address_error"
                    class="font-11 ms-1 mt-1 text-danger d-none"
                    >Address is required</label
                  >
                </div>

                <div class="col-md-6 mb-4">
                  <label
                    for="address 1"
                    class="form-label font-16 font-weight-400 font-family-Roboto"
                    >Address 2</label
                  >
                  <input
                    type="text"
                    name="ship_to_address_1"
                    class="form-control border-0 rounded-0 font-family-Roboto font-14"
                    id="ship_to_address_1"
                    placeholder="Enter Address 1"
                  />
                </div>
              </div>
            </div>
            <!------------------SHipping To------------------>
          </div>
        </div>

        <div class="col-md-12 col-lg-5 mt-md-5 mt-sm-5 mt-xs-5 mt-lg-0">
          <div class="card rounded-10 shadow-4 border-0">
            <div class="card-body pt-4 pb-4 pe-4 ps-4">
              <h3 class="font-20 font-weight-700 mt-2">Your Order</h3>
              <hr class="custom_second" />

              <div v-for="(cart, index) in getcartData" :key="index" class="mt-4">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 ps-0">
                      <div style="width: 80px; height: 80px">
                        <img
                          class="h-100 img-fluid w-100"
                          v-if="cart?.product_data?.product_files[0]?.file_name"
                          :src="
                            baseWebUrl +
                            'files/image/' +
                            cart?.product_data?.product_files[0]?.file_name
                          "
                          :alt="cart?.product_data?.title"
                        />
                        <img
                          v-else
                          width="100"
                          class="img-fluid"
                          src="../assets/img/product_img.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <div class="col-md-9 col-lg-9">
                      <div class="row">
                        <div class="col-12">
                          <span
                            class="d-block font-16 font-weight-700 font-family-Roboto mb-2"
                           v-html="cart.product_data.title ? cart.product_data.title :''" ></span>
                          <!-- <span
                            class="d-block font-16 font-weight-700 font-family-Roboto mb-2"
                            >{{ cart.product_data.title }}</span
                          > -->
                          <div v-if="cart.cart_data">
                            <div v-for="(it, i) in cart.cart_data" :key="i">
                              <div
                                v-if="
                                  it.text != '' &&
                                  it.value != '' &&
                                  it.id != 'user_filename' &&
                                  it.id != 'filenames_arr'">
                                <b>{{ it.label }} {{ it.label ? ":" : "" }}</b>
                                {{ it.text }}
                              </div>
                            </div>
                          </div>

                          <div v-if="single_shipping_rate !== ' '">
                            <b>Shipping: </b>
                            <b>{{
                              "$" + (single_shipping_rate !== "undefined")
                                ? single_shipping_rate
                                : ""
                            }}</b>
                          </div>
                        </div>

                        <div class="col-12">
                          <div
                            class="mb-4"
                            v-if="cart.file_details !== undefined"
                          >
                            <h6 class="mt-3">Artwork Files</h6>
                            <div
                              class="mt-3" 
                              v-for="(files, index) in cart.file_details"
                              :key="index"
                            >
                              <span
                                v-if="index !== 'order_id' && index !== 'id'"
                              >
                                <!-- <span
                                  v-if="files.name != ''"
                                  style="text-decoration: none"
                                  class="border-0 pb-2 pe-4 ps-4 pt-2 bg-grey rounded-3 font-12 font-weight-400 font-family-Roboto text-black-50 mt-2"
                                >
                                  {{ files.name }}
                                </span> -->
                                <span 
                                  v-if="files.name != ''">
                                    <span v-for="(file, i) in replaceCommaFromBrTag(files.name)" v-bind:key="i"  >
                                      <span class="d-block mb-2 border-0 pb-2 pe-4 jk ps-4 pt-2 bg-grey rounded-3 font-14 font-weight-400 font-family-Roboto text-black-50 mt-2">{{ file }}</span>
                                    </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <!----Artwork --->
                          <div
                            class="mt-4 mt-2"
                            v-if="
                              cart?.file_details !== null &&
                              Object.keys(cart?.file_details).length > 0">
                            <a
                              style="
                                font-size: 1.2em;
                                background: #8080801f;
                                padding: 4px;
                                text-decoration: none;"
                              :href="
                                baseWebUrl +
                                'files/order/' +
                                cart.order_id +
                                '/' +
                                cart.order_id +
                                '-' +
                                cart.id +
                                '.zip'"
                              target="_blank"
                              >Download Artwork</a
                            >&nbsp;
                            <span class="ms-2" style="font-size: 1.1em">{{
                              cart.artwork_files.name
                            }}</span>

                            <span
                              title="Remove Artwork"
                              class="ms-2"
                              style="cursor: pointer"
                              @click.prevent="
                                removeArtwork(
                                  $event,
                                  cart.order_id,
                                  cart.id,
                                  cart.product_data.id
                                )
                              "
                              ><img src="../assets/img/remove-icon.png" />
                            </span>
                          </div>

                          <div v-else>
                            <span
                              class="mt-2 d-block font-11 line-height-13 font-family-Roboto font-weight-400 text-gray57"
                              >Artwork not uploaded</span
                            >
                          </div>
                          <!---Artwork--->
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-6">
                          <span
                            class="d-block font-16 font-weight-700 font-family-Roboto"
                            >$ {{ parseFloat(cart.price).toFixed(2) }} x{{
                              cart.qty
                            }}
                          </span>
                        </div>

                        <div class="col-6 pe-0 text-en text-end">
                          <span
                            class="d-block font-16 font-weight-700 font-family-Roboto"
                            >$
                            {{ parseFloat(cart.price * cart.qty).toFixed(2) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- {{ console.log(notes, 'notes notes') }}
              <div class="mt-4" v-if="notes">
                <hr class="custom_second" />
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-2 ps-0"><strong class="font-16 font-weight-400 font-family-Roboto">Notes:</strong></div>
                        <div class="col-10">
                              <p class="mb-0">{{ notes }}</p>
                        </div>
                    </div>
                </div>
              </div> -->

        
              <hr class="custom_second" />
              <div class="d-flex justify-content-between mt-4">
                <span class="font-16 font-weight-400 font-family-Roboto"
                  >Sub-Total</span
                >
                <span class="font-16 font-weight-700 font-family-Roboto">
                  <div v-if="getTotalPrice">${{ getTotalPrice }}</div>
                </span>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <span class="font-16 font-weight-400 font-family-Roboto"
                  >Shipping</span
                >
                <span class="font-16 font-weight-700 font-family-Roboto">
                  <div v-if="shipping_rate">$ {{ shipping_rate }}</div>
                  <div v-else>$ 0</div>
                </span>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <span class="font-16 font-weight-400 font-family-Roboto"
                  >Tax</span
                >
                <span class="font-16 font-weight-700 font-family-Roboto">
                  <div>
                    $ {{ tax !== " " ? parseFloat(tax).toFixed(2) : 0.0 }}
                  </div>
                </span>
              </div>
              <!----Coupon & Discount--------------->
              <div v-if="this.coupon != '' && this.discount > 0"><hr /></div>
              <div
                v-if="this.coupon != ' '"
                class="d-flex justify-content-between mt-3"
              >
                <span class="font-16 font-weight-400 font-family-Roboto"
                  >Coupon</span
                >
                <span class="font-16 font-weight-700 font-family-Roboto">
                  <div style="text-transform: uppercase">{{ this.coupon }}</div>
                </span>
              </div>
              <div
                v-if="discount && discount > 0"
                class="d-flex justify-content-between mt-3"
              >
                <span class="font-16 font-weight-400 font-family-Roboto"
                  >Discount</span
                >
                <span class="font-16 font-weight-700 font-family-Roboto">
                  <div><b>-</b> ${{ discount }}</div>
                </span>
              </div>
              <!----Coupon & Discount--------------->

              <hr class="custom_second" />
              <div class="d-flex justify-content-between mt-4">
                <span class="font-25 font-weight-400 font-family-Roboto"
                  >Total</span
                >
                <span
                  id="gettotalamount"
                  class="font-25 font-weight-700 font-family-Roboto"
                >
                  <div v-if="shipping_rate && getTotalPrice && tax">
                    $
                    {{
                      console.log(
                        shipping_rate,
                        parseInt(getTotalPrice),
                        tax,
                        parseInt(discount),
                        "from view"
                      )
                    }}
                    {{
                      discount && discount > 0
                        ? (
                            parseFloat(shipping_rate) +
                            parseFloat(getTotalPrice) +
                            parseFloat(tax) -
                            parseFloat(discount)
                          ).toFixed(2)
                        : (
                            parseFloat(shipping_rate) +
                            parseFloat(getTotalPrice) +
                            parseFloat(tax)
                          ).toFixed(2)
                    }}
                  </div>

                  <div v-else-if="shipping_rate && getTotalPrice">
                    $
                    {{
                      discount && discount > 0
                        ? (
                            parseFloat(shipping_rate) +
                            parseFloat(getTotalPrice) -
                            parseFloat(discount)
                          ).toFixed(2)
                        : (
                            parseFloat(shipping_rate) +
                            parseFloat(getTotalPrice)
                          ).toFixed(2)
                    }}
                  </div>
                  <div v-else-if="getTotalPrice && tax">
                    $
                    {{
                      discount && discount > 0
                        ? (
                            parseFloat(getTotalPrice) +
                            parseFloat(tax) -
                            parseFloat(discount)
                          ).toFixed(2)
                        : (parseFloat(getTotalPrice) + parseFloat(tax)).toFixed(
                            2
                          )
                    }}
                  </div>

                  <div v-else>
                    $
                    {{
                      this.discount && this.discount > 0
                        ? (
                            parseFloat(getTotalPrice) - parseFloat(discount)
                          ).toFixed(2)
                        : getTotalPrice
                    }}
                  </div>
                </span>
              </div>

              <div v-if="shippingOptions.length > 0">
                <div
                  id="shipping-method"
                  tyle="margin-top: 60px !important"
                  class="mt-4"
                >
                  <p>Please select the shipping method below.</p>
                  <div
                    v-for="(shipping, index) in shippingOptions"
                    :key="index"
                  >
                    <div v-if="index == 0">
                      <select
                        @change.prevent="getShippingRate($event)"
                        v-model="key"
                        ref="shippingdetails"
                        class="form-control _country_id"
                        required
                        name="shipping"
                      >
                        <option selected="selected" value="">
                          Select shipping method
                        </option>
                        <option
                          :value="index"
                          v-for="(ship, index) in shipping"
                          v-bind:key="ship.index"
                        >
                          {{ ship.title + " ($" + ship.price + ")" }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-2 shiperror alert alert-danger"></div>
                  <input
                    @click.prevent="continueToPayment()"
                    class="btn btn-primary p-0 text-capitalize me-sm-3 mt-5"
                    type="submit"
                    value="Save & Continue"
                  />

                  <input
                    class="btn btn-outline-primary p-0 text-capitalize mt-5"
                    type="button"
                    @click="clearCartData()"
                    value="Cancel Order"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-10">
          <h3 class="mt-2 mb-4">Complete the form below to continue</h3>
          <input
            type="hidden"
            ref="ship_from_id"
            name="ship_from_id"
            id="OrderShipFromId"
            :value="submittedData?.ship_from"
          />
          <input
            type="hidden"
            ref="ship_to_id"
            name="ship_to_id"
            id="OrderShipToId"
          />
          <div class="form-group">
            <label for="OrderCoupon">Coupon</label>
            <input
              name="coupon"
              value=""
              class="form-control"
              maxlength="255"
              type="text"
              id="OrderCoupon"
            />
          </div>
          <div class="form-group">
            <label for="OrderNotes">Notes</label>
            <textarea
              name="notes"
              class="form-control"
              rows="3"
              cols="30"
              id="OrderNotes"
            ></textarea>
          </div>
          <div class="form-group">
            <input
              ref="tos"
              type="hidden"
              name='tos"'
              id="OrderTos_"
              value="0"
            />
            <input
              @change.prevent="acceptTOS($event)"
              type="checkbox"
              name="tos"
              id="OrderTos"
            />
            <div class="terms_and_conditions">
              <label for="OrderTos"
                >I agree to the terms of service as stipulated by Hollywood
                Studios. I acknowledge that all artwork submitted by me has been
                reviewed and approved by me. I understand Hollywood Studios
                shall not be responsible for content, spelling, layout, grammar,
                image quality or positioning including folds. I understand
                Hollywood Studios has provided me with templates and
                instructions on exactly how I am to&nbsp; create my artwork to
                achieve the best possible printed product. I also understand
                Hollywood Studios is not responsible to proofread or check
                content before printing.
              </label>
            </div>
            <div class="form-group mt-4">
              <label for="OrderTaxExemption"><h6>Tax Exemption</h6></label>
              <input
                ref="tax"
                name="tax_exemption"
                class="form-control"
                maxlength="255"
                type="text"
                id="OrderTaxExemption"
              />
            </div>
            <div class="submit">
              <input
                id="continue1"
                class="btn btn-outline-primary p-0 text-capitalize"
                type="submit"
                value="Save & Continue"
              />
            </div>

            <div class="ship_error alert alert-danger mt-2"></div>
            <div class="ship_success alert alert-success mt-2"></div>
          </div>
        </div>

        <div class="col-md-1"></div>
      </div>
    </div>
  </form>
  <!-----Open New  Address (To Add) of User---->
  <ShippingAddress />
  <!-----Open New  Address (To Add) of User---->
</template>

<script>
import $ from "jquery";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { mapActions } from "vuex";
import {
  GET_USER_DATA_ACTION,
  CLEAR_UP_LOGIN_ACTION,
  GET_USER_SHIPPING_ACTION,
  GET_COUNTRY_ACTION,
  GET_STATE_ACTION,
  GET_CITY_ACTION,
  DELETE_UPLOADED_ARTWORK_ACTION,
  DELETE_UPLOADED_CART_ARTWORK_ACTION,
  CLEAR_CART_DATA_ACTION,
  DELETE_ALL_ITEMS_CART_ACTION,
  DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION,
  GET_UPDATE_CART_SHIPFROM_DATA_ACTION,
} from "@/store/storeconstants";
import ShippingAddress from "@/components/ShippingAddress.vue";

import { toast } from "vue3-toastify";
export default {
  name: "ShippingView",
  components: {
    ShippingAddress,
  },

  data() {
    return {
      TotalPrice: 0,
      shippingroute: null,
      shippingOptions: [],
      isCart: false,
      sessionid: null,
      dataTableData: ref([]),
      getcartData: [],
      userLoginToken: null,
      breadcrumbs: {
        prevurl: "",
        product: [],
        cururl: "",
      },

      submittedData: {
        ship_from: null,
        ship_to: null,
        full_name: null,
        first_name: "",
        last_name: "",
        title: "",
        company: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        day_phone: "",
        night_phone: "",
        phone: "",
        email: "",
      },
      shiptoSubmitted: {
        country: "",
        state: "",
        city: "",
      },
      key: "",

      userInfo: {
        first_name: null,
        last_name: null,
        company: null,
        address1: null,
        address2: null,
        zip: null,
      },
      countriesShipfrom: ["Select Country"],
      statesShipfrom: [],
      citiesShipfrom: [],
      countriesShipto: ["Select Country"],
      statesShipto: [],
      citiesShipto: [],
      single_shipping_rate: null,
      shipping_rate: null,
      tax: 0.0,
      tax_redemption: 0.0,
      coupon: null,
      notes: localStorage.getItem('notes') ? localStorage.getItem('notes'): null,
      discount: 0,
    };
  },

  props: {
    value: Number, // Assuming your v-model value is a string
  },

  created() {
    if (this.$router.currentRoute._value.name == "shipping") {
      this.shippingroute = 1;
    }

    this.getcartData = this.cartData;
    this.breadcrumbs.prevurl = "Printing";
    this.breadcrumbs.cururl =
      this.route.name != undefined ? this.route.name : "";
    this.userDetail;
    /*****load shipping from*****/
    // let token = this.userlData.CurrentUserLogin.token != ""? this.userlData.CurrentUserLogin.token: "";
    // if(token){
    //     this.UserCartDetails(token)
    //         .then((res) => {
    //             console.log(res , 'res');
    //         })
    //         .catch((error) => {
    //             console.log(error, 'error');
    //     });
    // }
    // console.log("token..?????", token);
    /****load shipping from******/
  },

  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const store = useStore();
    const route = useRoute();
    // eslint-disable-next-line no-unused-vars
    const router = useRouter();
    // const submittedData = {};
    //let shiptoSubmitted = {};
    //const dataTableRef = ref(null);
    const baseWebUrl = process.env.VUE_APP_BASE_URL_WEB;
    const order_id =
      localStorage.getItem("current_order_id") !== null
        ? localStorage.getItem("current_order_id")
        : "";
    //let getcountry=this.submittedData.country

    let user_first_name = null;
    let user_last_name = null;
    // eslint-disable-next-line no-unused-vars
    let user_email = null;
    let user_company = null;
    let user_address1 = null;
    let user_address2 = null;
    let user_zip = null;
    // eslint-disable-next-line no-unused-vars
    let user_country = null;

    /****set default country state and city ****/

    // eslint-disable-next-line no-unused-vars
    let user_state = null;
    // eslint-disable-next-line no-unused-vars
    let user_city = null;

    // eslint-disable-next-line no-unused-vars
    let user_shipping_id = null;
    // eslint-disable-next-line no-unused-vars
    let user_day_phone = null;
    // eslint-disable-next-line no-unused-vars
    let user_night_phone = null;

    let orderids = [];

    let usershippingAddress =
      store.state.userInfo.DefaultShipping != undefined
        ? store.state.userInfo.DefaultShipping
        : "";
    let cartData =
      store.state.cartData.cart != undefined ? store.state.cartData.cart : [];
    let userlData = store.state.auth.CurrentUserLogin;
    // eslint-disable-next-line no-prototype-builtins

    let usertokendata = userlData.CurrentUserLogin;
    let token = Object.hasOwn(usertokendata, "token")
      ? userlData.CurrentUserLogin.token
      : "";
    //   userlData.CurrentUserLogin.token != "" ? userlData.CurrentUserLogin.token : "";
    if (cartData != undefined) {
      $.each(cartData, function (i, e) {
        orderids.push(e.order_id);
      });
    }

    let session_id = "";
    cartData.map(function (e) {
      session_id = e.session_id != undefined ? e.session_id : "";
    });

    // onMounted(getShippingData);

    return {
      //getShippingData,
      token,
      session_id,
      order_id,
      baseWebUrl,
      store,
      cartData,
      userlData,
      route,
      user_first_name,
      user_last_name,
      user_company,
      user_address1,
      user_address2,
      user_zip,
      usershippingAddress,
    };
  },

  computed: {
    getProducts() {
      let products_title = [];
      $.each(this.cartData, function (i, e) {
        products_title.push(e.product_data.title);
      });
      return products_title;
    },

    getUserToken() {
      let token =
        this.userlData.CurrentUserLogin.token != ""
          ? this.userlData.CurrentUserLogin.token
          : "";
      return token;
    },

    getsingleShipRate() {
      let shiprate =
        localStorage.getItem("shipping_methods") !== null
          ? JSON.parse(localStorage.getItem("shipping_methods"))
          : [];
      let shipp_rate = "";
      if (shiprate.length > 0) {
        if (shiprate !== null || shiprate !== "") {
          let ship = shiprate.ship_value;
          let getship = ship.split("$");
          let getshipprice = getship[1].replace(")", " ");
          shipp_rate = getshipprice;
        }
      }
      return shipp_rate;
    },

    getTotalPrice() {
      let SubtotalPrice = this.cartData;
      let total_price = 0;
      $.each(SubtotalPrice, (i, e) => {
        total_price = total_price + e.price * e.qty;
      });
      return total_price.toFixed(2);
    },

    getDefaultShipping() {
      //  this.store.state.userInfo.DefaultShipping != "" ? this.store.state.userInfo.DefaultShipping:''
      let shippinAddress =
        localStorage.getItem("ShippingFromDetails") != ""
          ? JSON.parse(localStorage.getItem("ShippingFromDetails"))
          : "";
      let userShipping = "";
      console.log(shippinAddress, "shippinAddress");
      /***set in local storage start ***/
      userShipping = {
        shipping_from_id: shippinAddress?.shipping_from_id
          ? shippinAddress?.shipping_from_id
          : shippinAddress?.id,
        shipping_from_full_name: shippinAddress?.shipping_from_full_name
          ? shippinAddress?.shipping_from_full_name
          : shippinAddress?.first_name + " " + shippinAddress?.last_name,
        shipping_from_day_phone: shippinAddress?.shipping_from_day_phone
          ? shippinAddress?.shipping_from_day_phone
          : shippinAddress?.day_phone != ""
          ? shippinAddress?.day_phone
          : "",
        shipping_from_night_phone: shippinAddress?.shipping_from_night_phone
          ? shippinAddress?.shipping_from_night_phone
          : shippinAddress?.night_phone != ""
          ? shippinAddress?.night_phone
          : "",
        shipping_from_address: shippinAddress?.shipping_from_address
          ? shippinAddress?.shipping_from_address
          : shippinAddress?.address1,
        shipping_from_address_1: shippinAddress?.shipping_from_address_1
          ? shippinAddress?.shipping_from_address_1
          : shippinAddress?.address2,
        shipping_from_state: shippinAddress?.shipping_from_state
          ? shippinAddress?.shipping_from_state
          : shippinAddress?.state_id,
        shipping_from_city: shippinAddress?.shipping_from_city
          ? shippinAddress?.shipping_from_city
          : shippinAddress?.city,
        shipping_from_country: shippinAddress?.shipping_from_country
          ? shippinAddress?.shipping_from_country
          : shippinAddress?.country,
        shipping_from_zip: shippinAddress?.shipping_from_zip
          ? shippinAddress?.shipping_from_zip
          : shippinAddress?.zip,
        shipping_from_email: shippinAddress?.shipping_from_email
          ? shippinAddress?.shipping_from_email
          : shippinAddress?.email,
      };
      // if (localStorage.getItem("ShippingFromDetails") != null ) {
      //     userShipping = {
      //         shipping_from_full_name: shippinAddress?.shipping_from_full_name,
      //         shipping_from_day_phone:shippinAddress?.shipping_from_day_phone ,
      //         shipping_from_night_phone:shippinAddress?.shipping_from_night_phone,
      //         shipping_from_address: shippinAddress?.shipping_from_address,
      //         shipping_from_address_1: shippinAddress?.shipping_from_address_1,
      //         shipping_from_state: shippinAddress?.shipping_from_state,
      //         shipping_from_city: shippinAddress?.shipping_from_city,
      //         shipping_from_country: shippinAddress?.shipping_from_country,
      //         shipping_from_zip: shippinAddress?.shipping_from_zip,
      //         shipping_from_email: shippinAddress?.shipping_from_email,
      //     };
      //     console.log("HI..If...", userShipping);
      //     localStorage.setItem( "ShippingFromDetails",JSON.stringify(userShipping));
      // } else {
      //     console.log("HI..Else...!", shippinAddress);
      //     userShipping = {
      //         shipping_from_full_name:shippinAddress?.first_name + " " + shippinAddress?.last_name,
      //         shipping_from_day_phone:shippinAddress?.day_phone != "" ? shippinAddress?.day_phone : "",
      //         shipping_from_night_phone:shippinAddress?.night_phone != "" ? shippinAddress?.night_phone : "",
      //         shipping_from_address: shippinAddress?.address1,
      //         shipping_from_address_1: shippinAddress?.address2,
      //         shipping_from_state: shippinAddress?.state,
      //         shipping_from_city: shippinAddress?.city,
      //         shipping_from_country: shippinAddress?.country,
      //         shipping_from_zip: shippinAddress?.zip,
      //         shipping_from_email: shippinAddress?.email,
      //     };
      //     console.log(userShipping, 'userShipping')
      //     localStorage.setItem("ShippingFromDetails",JSON.stringify(userShipping));
      // }
      /***set in local storage end ***/
      return userShipping;
    },

    getShippingPrice() {
      let ShippingCost = 0;
      return ShippingCost;
    },

    getShipTostate() {
      return this.shiptostate;
    },
  },

  beforeMount() {
    //let allshipdata = this.getDefaultShipping;
    let countries = this.listCountries();
    // set befault shipping from id
    this.submittedData.ship_from = this.getDefaultShipping.shipping_from_id;

    //let selectedCOuntry = this.shiptoSubmitted.country;
    countries
      .then((res) => {
        if (res.status == 200) {
          this.submittedData.country =
            this.getDefaultShipping.shipping_from_country;
          // this.shiptoSubmitted.country = 231;
          this.countriesShipfrom = res.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    let getcountry = {
      country_id: this.getDefaultShipping.shipping_from_country,
    };
    this.listStates(getcountry)
      .then((resstate) => {
        if (resstate.status == 200) {
          this.submittedData.state =
            this.getDefaultShipping.shipping_from_state;
          this.statesShipfrom = resstate.data.data;
        }
      })
      .catch((cityerror) => {
        console.log(cityerror);
      });

    let getstates = { state_id: this.getDefaultShipping.shipping_from_state };

    this.listCities(getstates)
      .then((citydata) => {
        if (citydata.status == 200) {
          this.submittedData.city = this.getDefaultShipping.shipping_from_city;
          this.citiesShipfrom = citydata.data.data;
        }
      })
      .catch((cityerror) => {
        console.log(cityerror);
      });

    /****ship to end ****/
    let shiptodetails =
      localStorage.getItem("ShippingToDetails") !== null
        ? JSON.parse(localStorage.getItem("ShippingToDetails"))
        : [];
    let shiptocountry = shiptodetails.shipping_to_country;
    let shiptostate = shiptodetails.shipping_to_state;
    let shipcity = shiptodetails.shipping_to_city;

    this.listCountries()
      .then((res) => {
        if (res.status == 200) {
          this.shiptoSubmitted.country = shiptocountry;
          this.countriesShipto = res.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    let getshiptocountry = { country_id: shiptocountry };

    this.listStates(getshiptocountry)
      .then((resstate) => {
        if (resstate.status == 200) {
          // this.submittedData.state = this.getDefaultShipping.state_id;
          this.shiptoSubmitted.state = shiptostate;
          this.statesShipto = resstate.data.data;
        }
      })
      .catch((cityerror) => {
        console.log(cityerror);
      });

    let getshiptostates = { state_id: shiptostate };

    this.listCities(getshiptostates)
      .then((citydata) => {
        if (citydata.status == 200) {
          //this.submittedData.city = this.getDefaultShipping.city;
          this.shiptoSubmitted.city = shipcity;
          this.citiesShipto = citydata.data.data;
        }
      })
      .catch((cityerror) => {
        console.log(cityerror);
      });

    /***ship to end ****/
  },

  methods: {
    ...mapActions("userInfo", {
      userData: GET_USER_DATA_ACTION,
    }),

    ...mapActions("auth", {
      userClearLogin: CLEAR_UP_LOGIN_ACTION,
    }),

    ...mapActions("userInfo", {
      UserCartDetails: GET_USER_SHIPPING_ACTION,
    }),
    /****Fetch countries, states and cities *****/
    ...mapActions("location", {
      listCountries: GET_COUNTRY_ACTION,
    }),

    ...mapActions("location", {
      listStates: GET_STATE_ACTION,
    }),

    ...mapActions("location", {
      listCities: GET_CITY_ACTION,
    }),
    ...mapActions("product", {
      deleteArtwork: DELETE_UPLOADED_ARTWORK_ACTION,
    }),

    ...mapActions("cartData", {
      deletecartArtwork: DELETE_UPLOADED_CART_ARTWORK_ACTION,
    }),
    ...mapActions("cartData", {
      updateShipFrom: GET_UPDATE_CART_SHIPFROM_DATA_ACTION,
    }),
    //GET_UPDATE_CART_SHIPFROM_DATA_ACTION
    ...mapActions("cartData", {
      clearCart: CLEAR_CART_DATA_ACTION,
    }),
    ...mapActions("cartData", {
      deletedAllItem: DELETE_ALL_ITEMS_CART_ACTION,
    }),
    ...mapActions("cartData", {
      deletesinglecartArtwork: DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION,
    }),

    replaceCommaFromBrTag(files) {
        const newData = files.split(',');
        var rv = {};
         for (var i = 0; i < newData.length; ++i)
         if (newData[i] !== undefined) rv[i] = newData[i];
       return rv;
    },

    clearCartData() {
      let input = confirm("Are you sure you want to clear cart?");
      if (input && input == true) {
        this.deletedAllItem();
        this.clearCart([]);
        toast.success("cart cleared successfully", { autoClose: 740 });
        this.$router.push("/cart");
      }
    },

    /***Fetch country , state and cities */
    handleText() {
      this.userClearLogin({});
    },

    removeArtwork(event, order_id, item_id, product_id) {
      const removeartwork = confirm("Are you Sure you want to remove Artwork?");
      if (removeartwork == true) {
        let getfilename = event.target.parentElement.previousElementSibling;
        let file_name = getfilename.innerText;
        if (localStorage.getItem("artworkFiles") !== null) {
          localStorage.removeItem("artworkFiles");
        }
        //console.log(event.target)
        let result = this.$axios.get(
          `${process.env.VUE_APP_BASE_URL}cart/remove_artwork/${order_id}/${item_id}/${product_id}`
        );
        result
          .then((res) => {
            if (res.status == 200) {
              let artwork_file_name = {
                file: file_name,
              };
              this.deletecartArtwork(artwork_file_name);
              //this.deleteArtwork({});
              this.deletesinglecartArtwork(res);
              toast.success(
                "Artwork file have been Successfully removed from Cart",
                {
                  autoClose: 2000,
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    // ...mapMutations("auth", SET_USER_LOGIN_DATA_MUTATION),
    acceptTOS(elem) {
      let isChecked = elem.target.checked;
      document.getElementById("OrderTos_").value = isChecked;
    },

    /*******************Ship From Countries*****************/
    getShipFromCountry() {
      this.states = [];
      this.cities = [];
      this.hasState = true;
      this.selectedState = "Select State";
      this.selectedCity = "Select City";

      if (this.countriesShipfrom && this.submittedData.country) {
        let country_id = $("#ship_from_country").val();
        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "state", {
            params: { country_id: country_id },
          })
          .then((response) => {
            this.statesShipfrom = response.data.data;
            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    /*******************Ship From Countries*****************/

    /*******************Ship From States*****************/
    getshipFromStates() {
      this.cities = [];
      this.hasState = true;
      this.selectedCity = "Select City";

      if (this.statesShipfrom && this.submittedData.state) {
        let state_id = $("#ship_from_state").val(); // this.submittedData.state;
        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "city", {
            params: { state_id: state_id },
          })
          .then((response) => {
            this.citiesShipfrom = response.data.data;

            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    /*******************Ship From States*****************/

    /*****************Ship From City********************/
    getshipFromCity() {},
    /*****************SHip From City*******************/

    /*******************Ship To Countries*****************/
    getShipToCountry() {
      this.states = [];
      this.cities = [];
      this.hasState = true;
      this.selectedState = "Select State";
      this.selectedCity = "Select City";
      if (this.countriesShipto && this.shiptoSubmitted.country) {
        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "state", {
            params: { country_id: this.shiptoSubmitted.country },
          })
          .then((response) => {
            this.statesShipto = response.data.data;
            // update country id from localStorage
            let ShippingToDetailsData = JSON.parse(
              localStorage.getItem("ShippingToDetails")
            );
            ShippingToDetailsData["shipping_to_country"] =
              this.shiptoSubmitted.country.toString();
            localStorage.setItem(
              "ShippingToDetails",
              JSON.stringify(ShippingToDetailsData)
            );
            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    /*******************Ship To Countries*****************/

    /*******************Ship To States*****************/
    getshipToStates() {
      this.cities = [];
      this.hasState = true;
      this.selectedCity = "Select City";
      if (this.statesShipto && this.shiptoSubmitted.state) {
        console.log(this.shiptoSubmitted.state);
        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "city", {
            params: { state_id: this.shiptoSubmitted.state },
          })
          .then((response) => {
            this.citiesShipto = response.data.data;
            // update state id from localStorage
            let ShippingToDetailsData = JSON.parse(
              localStorage.getItem("ShippingToDetails")
            );
            ShippingToDetailsData["shipping_to_state"] =
              this.shiptoSubmitted.state.toString();
            localStorage.setItem(
              "ShippingToDetails",
              JSON.stringify(ShippingToDetailsData)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    /*******************Ship To States*****************/

    /*******************Ship To City*****************/
    getshipToCity() {
      this.cities = [];
      this.hasState = true;
      if (
        this.citiesShipto &&
        this.shiptoSubmitted.city &&
        this.shiptoSubmitted.state
      ) {
        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "city", {
            params: { state_id: this.shiptoSubmitted.state },
          })
          .then((response) => {
            this.citiesShipto = response.data.data;
            // update state id from localStorage
            let ShippingToDetailsData = JSON.parse(
              localStorage.getItem("ShippingToDetails")
            );
            ShippingToDetailsData["shipping_to_city"] =
              this.shiptoSubmitted.city.toString();
            localStorage.setItem(
              "ShippingToDetails",
              JSON.stringify(ShippingToDetailsData)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    /*******************Ship To City*****************/

    paymentProcess() {
      if (localStorage.getItem("notes") !== null) {
        localStorage.removeItem("notes");
      }
      let ship_from_id = $("#OrderShipFromId").val();
      let ship_to_id = $("#OrderShipToId").val();

      if (!$("#ship_to_full_name").val()) {
        $("#ship_to_full_name_error").removeClass("d-none");
        $("#ship_to_full_name").focus();
        return false;
      }

      // if (!$("#ship_to_phno").val()) {
      //   $("#ship_to_phno_error").removeClass("d-none");
      //   $("#ship_to_phno").focus();
      //   return false;
      // }

      if (!$("#ship_to_email").val()) {
        $("#ship_to_email_error").removeClass("d-none");
        $("#ship_to_email").focus();
        return false;
      }

      if (!$("#ship_to_country").val()) {
        $("#ship_to_country_error").removeClass("d-none");
        $("#ship_to_country").focus();
        return false;
      }

      if (!$("#ship_to_state").val()) {
        $("#ship_to_state_error").removeClass("d-none");
        $("#ship_to_state").focus();
        return false;
      }

      if (!$("#ship_to_city").val()) {
        $("#ship_to_city_error").removeClass("d-none");
        $("#ship_to_city").focus();
        return false;
      }

      if (!$("#ship_to_zip").val()) {
        $("#ship_to_zip_error").removeClass("d-none");
        $("#ship_to_zip").focus();
        return false;
      }

      if (!$("#ship_to_address").val()) {
        $("#ship_to_zip_error").removeClass("d-none");
        $("#ship_to_address").focus();
        return false;
      }

      if (!$("#ship_to_address").val()) {
        $("#ship_to_address_error").removeClass("d-none");
        $("#ship_to_address").focus();
        return false;
      }

      if (ship_from_id === ship_to_id) {
        $(".ship_error").show("slow");
        $(".ship_error").html("<p>Ship from and Ship to Address cant be Same");

        setTimeout(() => {
          $(".ship_error").html("");
          $(".ship_error").hide("slow");
        }, 4500);
      } else {
        let config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };

        let ship_from = this.$refs.ship_from_id.value;
        let ship_to = this.$refs.ship_to_id.value;
        let tax_redemption = this.$refs.tax.value.length > 0 ? this.$refs.tax.value : 0;
        if (tax_redemption.length > 0) {
          if (localStorage.getItem("tax_redemption") == null) {
            localStorage.setItem("tax_redemption", tax_redemption);
          } else {
            localStorage.setItem("tax_redemption", tax_redemption);
          }
        }

        let formData = new FormData();

        this.isCart = this.cartData != undefined ? true : false;
        this.sessionid = this.session_id;
        let tos = this.$refs.tos.value;
        this.notes = $("#OrderNotes").val();
        console.log(this.notes, "this.notes " )
        let current_order_id =
          localStorage.getItem("current_order_id") !== null
            ? localStorage.getItem("current_order_id")
            : "";

        formData.append("isCart", this.isCart);
        formData.append("session_id", this.sessionid);
        formData.append("ship_from_id", ship_from);
        formData.append("ship_to_id", ship_to);
        formData.append("tos", tos);
        formData.append("notes", this.notes);
        if (current_order_id !== undefined) {
          formData.append("current_order_id", current_order_id);
        }
        //  console.log($("#OrderTaxExemption").val());
        this.tax_redemption = $("#OrderTaxExemption").val();
        this.coupon = $("#OrderCoupon").val();

        // eslint-disable-next-line no-unused-vars
        let apiURL = "";
        let params = {};
        let headers = {};

        this.$axios.post( process.env.VUE_APP_BASE_URL + "cart/shipping", formData, config)
          .then((res) => {
            if (localStorage.getItem("notes") !== null) {
              localStorage.removeItem("notes");
            }
            if (this.notes != "") {
              localStorage.setItem("notes", this.notes);
            }

            $(".ship_error").hide();
            $(".ship_success").hide();
            $("#loader").removeClass("hide");
            switch (res.data.status) {
              case 0:
                $("#loader").addClass("hide");
                $(".ship_error").show("slow");
                $(".ship_error").html("");
                $(".ship_error").html(`<span>${res.data.data}</span>`);
                break;
              case 1:
                /******Call Shipping Calculator Api ******/
                apiURL = process.env.VUE_APP_BASE_URL + "cart/shipping_calculator";
                params = {
                  isCart: this.isCart,
                  current_order_id: current_order_id,
                  coupon_id: this.coupon,
                  tax_redemption: this.tax_redemption,
                };

                headers = {
                  Authorization: `Bearer ${this.token}`,
                };
                this.$axios
                  .get(apiURL, {
                    params: params,
                    headers: headers,
                  })
                  .then((res) => {
                    $("#loader").addClass("hide");

                    if (res.data.status == 0) {
                      $(".ship_error").show("slow");
                      $(".ship_error").html("");
                      $(".ship_error").html(`<span>${res.data.data}</span>`);
                    }
                    if (res.data.status == 1) {
                      console.log(res, 'updateShipFrom')
                      this.updateShipFrom(res);

                      let checkShippings = res.data.data.items;
                      this.tax = res.data.data?.tax?.tax
                        ? res.data.data?.tax?.tax
                        : 0.0;
                      this.discount = res.data.data.items.discount;
                      this.coupon = res.data.data.items.coupon;

                      if (this.discount > 0) {
                        if (localStorage.getItem("discount") == null) {
                          localStorage.setItem("discount", this.discount);
                        } else {
                          localStorage.setItem("discount", this.discount);
                        }
                      }

                      if (this.coupon != "") {
                        if (localStorage.getItem("coupon") == null) {
                          localStorage.setItem("coupon", this.coupon);
                        } else {
                          localStorage.setItem("coupon", this.coupon);
                        }
                      }

                      if (localStorage.getItem("tax") == null) {
                        localStorage.setItem(
                          "tax",
                          parseFloat(this.tax).toFixed(2)
                        );
                      } else {
                        localStorage.setItem(
                          "tax",
                          parseFloat(this.tax).toFixed(2)
                        );
                      }

                      if (this.tax_redemption.length > 0) {
                        if (localStorage.getItem("tax_redemption") == null) {
                          localStorage.setItem(
                            "tax_redemption",
                            parseFloat(this.tax_redemption).toFixed(2)
                          );
                        } else {
                          localStorage.setItem(
                            "tax_redemption",
                            parseFloat(this.tax_redemption).toFixed(2)
                          );
                        }
                      }
                      let shipdata = [];
                      if (checkShippings) {
                        checkShippings.items.forEach(function (e) {
                          shipdata.push(e.shipping_quote.shipping);
                          ///
                        });
                      }
                      console.log("shipdata...?", shipdata);
                      this.shippingOptions = shipdata;

                      document
                        .getElementById("continue1")
                        .setAttribute("disabled", "disabled");

                      setTimeout(() => {
                        $(window).scrollTop(
                          $("#shipping-method").position().top
                        );
                      }, 850);
                    } else {
                      document
                        .getElementById("continue1")
                        .removeAttribute("disabled");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error("Unauthenticated please login again!", {
                      autoClose: 2500,
                    });
                    console.log(error);
                    let getvueitems = JSON.parse(localStorage.getItem("vuex"));
                    getvueitems.auth.CurrentUserLogin = {};
                    getvueitems.auth.UserStore = {};
                    getvueitems.auth.userLoggedin = false;
                    getvueitems.cartData.cart = [];
                    getvueitems.userInfo.CommercialOrders = [];
                    getvueitems.userInfo.CommercialOrders = [];
                    // getvueitems.userInfo.DefaultShipping=[]
                    getvueitems.userInfo.PrintingOrder = [];
                    localStorage.setItem("vuex", JSON.stringify(getvueitems));
                    /***clear all localstorage ***/
                    localStorage.removeItem("auth");
                    this.$router.push("/login");
                  });
                this.$forceUpdate();
              /******Call Shipping Calculator Api ******/
            }
          })
          .catch((error) => {
            toast.error("Unauthenticated please login again!", {
              autoClose: 2500,
            });
            console.log(error);
            let getvueitems = JSON.parse(localStorage.getItem("vuex"));
            getvueitems.auth.CurrentUserLogin = {};
            getvueitems.auth.UserStore = {};
            getvueitems.auth.userLoggedin = false;
            getvueitems.cartData.cart = [];
            getvueitems.userInfo.CommercialOrders = [];
            getvueitems.userInfo.CommercialOrders = [];
            // getvueitems.userInfo.DefaultShipping=[]
            getvueitems.userInfo.PrintingOrder = [];
            localStorage.setItem("vuex", JSON.stringify(getvueitems));
            /***clear all localstorage ***/
            localStorage.removeItem("auth");
            this.$router.push("/login");
          });
      }
    },

    getShippingCalculator(shipping) {
      let apiURL = process.env.VUE_APP_BASE_URL + "cart/shipping_calculator";

      const order_id =
        localStorage.getItem("current_order_id") !== null
          ? localStorage.getItem("current_order_id")
          : "";
      let headers = {
        Authorization: `Bearer ${this.token}`,
      };

      axios
        .post(
          apiURL,
          {
            shipping: shipping,
            payment_type: 6,
            current_order_id: order_id,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("response.....FF", response);
        });
    },

    continueToPayment() {
      let total_qty = 0;
      let cartItems = this.cartData;
      cartItems.map((e) => {
        total_qty = total_qty + e.qty;
      });

      $(".shiperror").hide();
      let selectedShipval =
        this.$refs.shippingdetails[0].options[
          this.$refs.shippingdetails[0].selectedIndex
        ].value;
      let ship_cost = selectedShipval.split("$");
      let actualcost = ship_cost[0].replace("$");
      console.log(actualcost);
      let selectedShiptext =
        this.$refs.shippingdetails[0].options[
          this.$refs.shippingdetails[0].selectedIndex
        ].text;
      if (selectedShipval == "") {
        $(".shiperror").show("slow");
        $(".shiperror").html("slow");
        $(".shiperror").html("<span>Kindly Select Shipping Method</span>");
      } else {
        $(".shiperror").hide("fast");
        $(".shiperror").html(" ");
        if (localStorage.getItem("shipping_methods") === null) {
          let ships = {
            ship_key: selectedShipval,
            ship_value: selectedShiptext,
          };
          localStorage.setItem("shipping_methods", JSON.stringify(ships));
        } else {
          let ships = {
            ship_key: selectedShipval,
            ship_value: selectedShiptext,
          };
          localStorage.setItem("shipping_methods", {});
          localStorage.setItem("shipping_methods", JSON.stringify(ships));
        }

        this.getShippingCalculator(selectedShipval);
        this.$router.push("/payment");
      }
      //this.$router.push("/payment");
    },

    openShip(text) {
      $("#addresses_account").DataTable().ajax.reload();
      let gettext = document.querySelector("#myModalLabel span");
      gettext.innerText =
        text == "shipfrom"
          ? "Select Ship From Address"
          : "Select Ship To Address";
    },

    getShippingRate(event) {
      let getSelectedText =
        event.target.options[event.target.selectedIndex].text;
      let cartItems = this.cartData;
      let total_qty = 0;
      cartItems.map((e) => {
        total_qty = parseInt(total_qty) + parseInt(e.qty);
      });

      console.log(getSelectedText, "getSelectedText")

      if (getSelectedText !== "Select shipping method") {
        let getrate = getSelectedText.split("(");
        let getrate_here = getrate[1].split(")");

        let get_r = getrate_here[0].replace("$", " ");

        let real_rate = parseFloat(get_r).toFixed(2);
        this.single_shipping_rate = real_rate;
        this.shipping_rate = (parseFloat(real_rate) * total_qty).toFixed(2);
        console.log(
          this.shipping_rate,
          real_rate,
          total_qty,
          get_r,
          "getShippingRate func"
        );
      } else {
        alert("Kindly select shipping method");
        this.shipping_rate = 0;
      }
    },

    customDrawCallback() {
      const _that = this;
      $("#addresses_account tbody td a").on("click", function () {
        // this.customDrawCallback;
        let first_name = this.getAttribute("data-firstname");
        let last_name = this.getAttribute("data-lastname");
        let email = this.getAttribute("data-email");
        let company = this.getAttribute("data-company");
        let address1 = this.getAttribute("data-address1");
        let address2 = this.getAttribute("data-address2");

        let u_country = this.getAttribute("data-country");
        let u_state = this.getAttribute("data-state");
        let u_city = this.getAttribute("data-city");

        let countryName = this.getAttribute("data-countryname");
        let stateName = this.getAttribute("data-statename");
        let cityName = this.getAttribute("data-cityname");

        let zip = this.getAttribute("data-zip");
        /// this.shiptoSubmitted.country = u_country;

        console.log("u_country..", u_country);

        let shippingid = this.getAttribute("data-shippingid");
        let dayphone = this.getAttribute("data-dayphone");
        let nightphone = this.getAttribute("data-nightphone");

        let user_first_name = first_name;
        let user_last_name = last_name;
        let user_email = email;

        // eslint-disable-next-line no-unused-vars
        let user_company = company;
        let user_address1 = address1;
        let user_address2 = address2;
        let user_country = u_country;
        let user_state = u_state;
        let user_city = u_city;
        let user_day_phone = dayphone;
        let user_night_phone = nightphone;
        let user_zip = zip;
        let user_shipping_id = shippingid;

        let checkShiplabel = $("#myModalLabel span").text();
        if (checkShiplabel == "Select Ship From Address") {
          let ship_from_country = u_country;
          let ship_from_state = u_state;
          // eslint-disable-next-line no-unused-vars
          let ship_from_city = u_city;

          /********BIND VALUES TO COUNTRY,STATE AND CITY****************/
          //COUNTRY
          let response1 = axios.get(`${process.env.VUE_APP_BASE_URL}country`);
          response1
            .then((res) => {
              if (res.data.status == 1) {
                console.log(res.data.data, "get country");
                _that.submittedData.country = ship_from_country;
                // _that.submittedAddress.country = ship_from_country;

                this.countryShipfrom = res.data.data;
                // console.log('ship_from_country..',ship_from_country);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //COUNTRY

          //STATE
          let response2 = axios.get(`${process.env.VUE_APP_BASE_URL}state`, {
            params: { country_id: ship_from_country },
          });
          response2
            .then((res) => {
              // $("#ship_from_state").html("");
              console.log(res.data.data, "get state");
              if (res.data.status == 1) {
                _that.submittedData.state = ship_from_state;
                _that.statesShipfrom = res.data.data;
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //STATE

          //CITY
          let response3 = axios.get(`${process.env.VUE_APP_BASE_URL}city`, {
            params: { state_id: ship_from_state },
          });
          response3
            .then((res) => {
              if (res.data.status == 1) {
                _that.submittedData.city = ship_from_city;
                _that.citiesShipfrom = res.data.data;
                // this.citiesShipfrom = res.data.data;
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //CITY

          /********BIND VALUES TO COUNTRY,STATE AND CITY****************/

          _that.submittedData.full_name =
            user_first_name + " " + user_last_name;
          _that.submittedData.phone =
            user_day_phone != "" ? user_day_phone : user_night_phone;
          _that.submittedData.email = user_email;
          _that.submittedData.zip = user_zip;
          _that.submittedData.address1 = user_address1;
          _that.submittedData.address2 = user_address2;
          _that.submittedData.ship_from = user_shipping_id;

          /****set default country state and city ****/
          document.getElementById("shipfrom").click(); //closepopup modal automatically
          document.getElementById("OrderShipFromId").value = user_shipping_id;

          
          /***set all shipping to details in local storage*** */
        } else {
          let ship_to_country = u_country;
          let ship_to_state = u_state;
          let ship_to_city = u_city;

          /********BIND VALUES TO COUNTRY,STATE AND CITY****************/
          //COUNTRY
          let response4 = axios.get(`${process.env.VUE_APP_BASE_URL}country`);
          response4
            .then((res) => {
              // $("#ship_to_country").html("");
              if (res.data.status == 1) {
                _that.countriesShipto = res.data.data;
                _that.shiptoSubmitted.country = ship_to_country;
                console.log("ship_to_country..kkkkkkk", ship_to_country);
                //console.log("this.countriesShipto..", this.countriesShipto);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //COUNTRY

          //STATE
          let response5 = axios.get(`${process.env.VUE_APP_BASE_URL}state`, {
            params: { country_id: ship_to_country },
          });
          response5
            .then((res) => {
              // $("#ship_to_state").html("");
              /// console.log("res.data...??jk", res.data);
              if (res.data.status == 1) {
                _that.statesShipto = res.data.data;
                _that.shiptoSubmitted.state = ship_to_state;

                //this.dataTableRef = res.data.data;
                console.log("this.statesShipto..???", this.statesShipto);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //STATE

          //CITY
          let response6 = axios.get(`${process.env.VUE_APP_BASE_URL}city`, {
            params: { state_id: ship_to_state },
          });
          response6
            .then((res) => {
              // $("#ship_to_city").html("");
              if (res.data.status == 1) {
                _that.citiesShipto = res.data.data;
                _that.shiptoSubmitted.city = ship_to_city;
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //CITY

          /********BIND VALUES TO COUNTRY,STATE AND CITY****************/
          $("#ship_to_full_name").val(user_first_name + " " + user_last_name);
          $("#ship_to_phno").val(user_day_phone != "" ? user_day_phone : user_night_phone);
          $("#ship_to_email").val(user_email);
          $("#ship_to_country").val(user_country);
          // this.shiptoSubmitted =user_country;
          $("#ship_to_state").val(user_state);
          $("#ship_to_city").val(user_city);
          $("#ship_to_zip").val(user_zip);
          $("#ship_to_address").val(user_address1);
          $("#ship_to_address_1").val(user_address2);
          document.getElementById("shipto").click(); //closepopup modal automatically
          document.getElementById("OrderShipToId").value = user_shipping_id;

          console.log("OrderShipToId..???", user_shipping_id);

          /***set all shipping to details in local storage*** */
          if (localStorage.getItem("ShippingToDetails") === null) {
            console.log("2");
            let userShipping = {
              shipping_to_full_name: user_first_name + " " + user_last_name,
              shipping_to_day_phone: user_day_phone != "" ? user_day_phone : "",
              shipping_to_company: user_company != "" ? user_company : "",
              shipping_to_night_phone:
                user_night_phone != "" ? user_night_phone : "",
              shipping_to_address: user_address1,
              shipping_to_address_1: user_address2,

              shipping_to_state: user_state,
              shipping_to_city: user_city,
              shipping_to_country: user_country,

              shipping_to_stateName: stateName,
              shipping_to_cityName: cityName,
              shipping_to_countryName: countryName,

              shipping_to_zip: user_zip,
              shipping_to_email: user_email,
              shipping_to_id: user_shipping_id,
            };
            localStorage.setItem(
              "ShippingToDetails",
              JSON.stringify(userShipping)
            );
          } else {
            console.log("3");
            // localStorage.setItem("ShippingToDetails", {});
            let userShipping = {
              shipping_to_full_name: user_first_name + " " + user_last_name,
              shipping_to_day_phone: user_day_phone != "" ? user_day_phone : "",
              shipping_to_company: user_company != "" ? user_company : "",
              shipping_to_night_phone:
                user_night_phone != "" ? user_night_phone : "",
              shipping_to_address: user_address1,
              shipping_to_address_1: user_address2,

              shipping_to_state: user_state,
              shipping_to_city: user_city,
              shipping_to_country: user_country,

              shipping_to_stateName: stateName,
              shipping_to_cityName: cityName,
              shipping_to_countryName: countryName,

              shipping_to_zip: user_zip,
              shipping_to_email: user_email,
              shipping_to_id: user_shipping_id,
            };
            localStorage.setItem(
              "ShippingToDetails",
              JSON.stringify(userShipping)
            );
          }

          /***set all shipping to details in local storage*** */
        }
      });
      this.$forceUpdate();
    },

    initDataTable() {
      // let submittedAddress = this.submittedData;
      // eslint-disable-next-line no-unused-vars
      let countryShipfrom = this.countriesShipfrom;
      // eslint-disable-next-line no-unused-vars
      let statesShipfrom = this.statesShipfrom;
      //    console.log(countriesShipfrom);

      // Initialize DataTable
      const tableElement = $("#addresses_account");
      $(tableElement).DataTable({
        paging: true,
        lengthChange: true,
        searching: true,
        ordering: true,
        info: true,
        autoWidth: false,
        serverSide: true,
        ajax: {
          url: `${process.env.VUE_APP_BASE_URL}cart/getshipping`,
          dataType: "json",
          type: "POST",
          data: { session_id: this.session_id },
          headers: { Authorization: `Bearer ${this.token}` },
          error: function (error) {
            if (error.responseJSON.error === "Unauthenticated.") {
              console.log("not autheticated");
              /*if (localStorage.getItem("Unauthenticated") == null) {
                            localStorage.setItem("Unauthenticated", true);
                        }

                        setTimeout(() => {
                            router.push("/");
                        }, 1300);*/
            }
          },
        },
        drawCallback: this.customDrawCallback, ///
        columns: [
          { data: "first_name" },
          { data: "last_name" },
          { data: "email" },
          { data: "day_phone" },
          { data: "company" },
        ],
      });
      //dataTableRef.value = dataTable;
    },

  },

  mounted() {
    this.initDataTable();
  },

  updated() {
    console.log(this.notes, "Update");
    let getTotal = "";
    if (localStorage.getItem("TotalAmount") == null) {
        let getTotal = $("#gettotalamount").html();
        localStorage.setItem("TotalAmount", getTotal);
    } else {
        localStorage.setItem("TotalAmount", getTotal);
    }
    let ShippingToDetails =
      localStorage.getItem("ShippingToDetails") !== null ? JSON.parse(localStorage.getItem("ShippingToDetails")) : [];
    if (ShippingToDetails !== undefined) {
        document.getElementById("OrderShipToId").value =  ShippingToDetails.shipping_to_id;
        $("#ship_to_full_name").val(ShippingToDetails.shipping_to_full_name);
        $("#ship_to_phno").val(ShippingToDetails.shipping_to_day_phone);
        $("#ship_to_email").val(ShippingToDetails.shipping_to_email);
        $("#ship_to_country").val(ShippingToDetails.shipping_to_country);
        $("#ship_to_state").val(ShippingToDetails.shipping_to_state);
        $("#ship_to_city").val(ShippingToDetails.shipping_to_city);
        $("#ship_to_zip").val(ShippingToDetails.shipping_to_zip);
        $("#ship_to_address").val(ShippingToDetails.shipping_to_address);
        $("#ship_to_address_1").val(ShippingToDetails.shipping_to_address_1);

        this.userInfo.first_name = this.user_first_name;
        this.userInfo.last_name = this.user_last_name;
        this.userInfo.company = this.user_company;
        this.userInfo.address1 = this.user_address1;
        this.userInfo.address2 = this.user_address2;
        this.userInfo.zip = this.user_zip;
        this.shippingOptions; //iterate on shipping options
    }
  },
};
</script>

<style scoped>
.btn.btn-info {
  font-weight: 400 !important;
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  text-transform: uppercase !important;
  letter-spacing: 0.08em !important;
  padding: 6px 12px !important;
  font-size: 13px !important;
  line-height: 1.42857143 !important;
  border-radius: 2px !important;
  position: relative !important;
  background-color: #5bc0de !important;
  border-color: #46b8da !important;
  color: #fff !important;
}

.btn.btn-info:hover {
  border-radius: 0px 15px 0px 15px !important;
}

tbody tr td a {
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}

#shipfromaddress,
#shiptoaddress {
  margin-top: 100px;
}

#shipto {
  margin-top: 100px;
}

.ship_error,
.shiperror,
.ship_success {
  display: none;
}

.white_bg_btn .btn-outline-primary,
.dark_bg_btn .btn-outline-primary,
.btn.btn-outline-primary.p-0.text-capitalize,
.btn.btn-primary.p-0.text-capitalize.me-sm-3 {
  font-family: "Roboto" !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #282828 !important;
  border-color: #7ec4cf !important;
  border-radius: 2px !important;
  width: 160px !important;
  height: 60px !important;
  line-height: 60px !important;
  background: right / 0% 100% no-repeat linear-gradient(#7ec4cf, #7ec4cf) !important;
  transition: background-size 500ms !important;
}

.dark_bg_btn .btn-outline-primary:hover,
.white_bg_btn .btn-outline-primary:hover,
.btn.btn-outline-primary.p-0.text-capitalize:hover,
.btn.btn-primary.p-0.text-capitalize.me-sm-3:hover {
  color: #fff !important;
  border-color: #7ec4cf !important;
  border-radius: 2px !important;
  background: left / 100% 100% no-repeat linear-gradient(#7ec4cf, #7ec4cf) !important;
  transition: background-size 500ms !important;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}
#loader {
  position: absolute;
  transform: translate(500px, 1400px);
}

#loader img {
  width: 39%;
}

input,
select,
textarea {
  background: #f4f5c8 !important;
}
</style>
