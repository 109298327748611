import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state() {
        return {
           // UserInfo: [],
            PrintingOrders:[],
            CommercialOrders:[],
           // DefaultShipping:[],
            orderDetails:[],
            userUploadedArtwork:[],
            userShippingAddresses:[]
            };
    },
    mutations,
    getters,
    actions,
}
